import React from "react";
import styles from "./NextITRModal.module.css";
import icon from "../../img/nextITR/markIcon.png";
import { Link } from "react-router-dom";
const NextITRModal = ({ setShowModal, appData }) => {
  const cancelClick = () => {
    setShowModal(false);
  };
  return (
    <div className={styles.container}>
      <div className={styles.modalContainer}>
        <img src={icon} alt="" className={styles.icon} />
        <div className={styles.title}>
          <div>Are you sure?</div>
        </div>
        <div className={styles.content}>
          Please refrain from submitting repeatedly
          <br /> the same types of creatives without making changes to the game,
          or your activity may be restricted.
        </div>
        <div className={styles.btnContainer}>
          <button className={styles.cancelBtn} onClick={cancelClick}>
            Cancel
          </button>
          <Link
            to={"/mytest/newapp/videos"}
            state={{
              data: appData,
            }}
            className={styles.createBtn}
          >
            Create
          </Link>
        </div>
      </div>
    </div>
  );
};

export default React.memo(NextITRModal);
/*
Link
          to={"/mytest/newapp/videos"}
          state={{
            data: location?.state?.data,
          }}
*/
