import React from "react";
import styles from "./FinanceOverviewSummary.module.scss";

import { ReactComponent as InfoIcon } from "../../img/FinaceOverview/info.svg";
import FinanceOverviewSummarySkeleton from "../FinanceOverviewSummarySkeleton/FinanceOverviewSummarySkeleton";
export default function FinanceOverviewSummary({ data, isLoading }) {
  return (
    <section className={styles.container}>
      <h2 className={styles.title}>Summary</h2>
      {isLoading ? (
        <FinanceOverviewSummarySkeleton />
      ) : (
        <section className={styles.data_section}>
          <span className={styles.ad_revenue}>
            <div className={styles.data_name}>
              <p>Ad Revenue</p>
              <InfoIcon className={styles.icon} />
              <div className={styles.ad_revenue_info}>
                Ad revenue data is provided for reference purposes only. Please
                note that the data is subject to change once the final report
                from ad networks is obtained.
              </div>
            </div>
            <div className={styles.data}>
              {data?.totalRevenue.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </div>
          </span>
          <span className={styles.iap_revenue}>
            <div className={styles.data_name}>
              <p>IAP Revenue</p>
              <InfoIcon className={styles.icon} />
              <div className={styles.iap_revenue_info}>
                IAP revenue data is delayed by 2 months due to exchange rate
                calculations. Real-time data is available in KRW. If necessary,
                please request it from your publishing manager.
              </div>
            </div>
            <div className={styles.data}>
              {data?.totalIAP.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </div>
          </span>
          <span className={styles.spend}>
            <div className={styles.data_name}>
              <p>Spend</p>
              <InfoIcon className={styles.icon} />
              <div className={styles.spend_info}>
                Spend data calculations for the most recent 2 months are based
                on a fixed rate of 1,300₩ per dollar. Please note that this data
                is subject to change once the correct exchange rate becomes
                available.
              </div>
            </div>
            <div className={styles.data}>
              {data?.totalSpend.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </div>
          </span>
          <span className={styles.prifit}>
            <div className={styles.data_name}>
              <p>Profit</p>
              <InfoIcon className={styles.icon} />
              <div className={styles.profit_info}>
                Profit data does not include tool costs, such as third-party
                service fees and attribution tool fees. For accurate
                calculations, please refer to the Invoice page.
              </div>
            </div>
            <div className={styles.data}>
              {data?.totalProfit.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </div>
          </span>
        </section>
      )}
    </section>
  );
}
