import React from "react";
import { DateRangePicker } from "react-date-range";
import moment from "moment";
import { subDays } from "date-fns";
import styles from "./PubCalendar.module.css";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
const PubCalender = ({
  state,
  setState,
  thirtyState,
  setThirtyState,
  selectRange,
  setSelectRange,
  select,
  setSelect,
  clickCalendar,
  setClickCalendar,
  dayList,
  rangeDateUpdate,
}) => {
  const onClickDateSelect = (key) => {
    let endDate = new Date(state.endDate);
    endDate.setFullYear(new Date(state.endDate).getFullYear());
    endDate.setMonth(new Date(state.endDate).getMonth());

    if (key === 1) {
      setState((prevState) => {
        return { ...prevState, endDate: state.endDate };
      });
      setClickCalendar(false);
    } else if (key === 2) {
      setThirtyState((prevState) => {
        return {
          ...prevState,
          startDate: thirtyState.startDate,
          endDate: thirtyState.endDate,
        };
      });
      setClickCalendar(false);
    } else {
      setClickCalendar(!clickCalendar);
    }
  };
  const isSelect = (list) => {
    return select?.id === list.id;
  };

  return (
    <div className={styles.container}>
      <div className={styles.selectBox}>
        <div className={styles.dateContainer}>
          <div>Show</div>
          {select?.id === 1 && (
            <div>
              <span>{state?.startDate?.toDateString()?.slice(4, 10)}</span> -{" "}
              <span className="endDate">
                {state?.endDate?.toDateString()?.slice(4, 10)}
              </span>
            </div>
          )}
          {select?.id === 2 && (
            <div>
              <span>
                {thirtyState?.startDate?.toDateString()?.slice(4, 10)}
              </span>{" "}
              -{" "}
              <span className="endDate">
                {thirtyState?.endDate?.toDateString()?.slice(4, 10)}
              </span>
            </div>
          )}
          {select?.id === 3 && (
            <div>
              <span>
                {selectRange[0]?.startDate?.toDateString()?.slice(4, 10)}
              </span>{" "}
              -{" "}
              <span className="endDate">
                {selectRange[0]?.endDate?.toDateString()?.slice(4, 10)}
              </span>
            </div>
          )}
        </div>

        {dayList.map((d) => (
          <div
            onClick={() => {
              onClickDateSelect(d.id);
              setSelect(d);
            }}
            key={d.id}
            className={isSelect(d) ? styles.activeSelect : styles.selectDate}
          >
            {d.select}
          </div>
        ))}
      </div>
      {clickCalendar && (
        <div className={styles.calendarBox}>
          <div className={styles.calendar_block}>
            <DateRangePicker
              onChange={(item) => setSelectRange([item.selection])}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={1}
              ranges={selectRange}
              direction="horizontal"
              preventSnapRefocus={true}
              calendarFocus="backwards"
              className={styles.calendarContainer}
              maxDate={subDays(new Date(moment().format("YYYY-MM-DD")), 4)}
            ></DateRangePicker>
            <button onClick={rangeDateUpdate} className={styles.updateBtn}>
              Update
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PubCalender;
