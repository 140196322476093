import React from "react";
import styles from "./Faq.module.scss";
import ReactGA from "react-ga4";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import getGA from "../../service/ga";
import FaqList from "../../components/FaqList/FaqList";
const Faq = () => {
  const location = useLocation();
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_ID);
    ReactGA.send({
      hitType: "pageview",
      path: location.pathname,
      location: location.pathname,
      title: "FAQ",
    });
    ReactGA.gtag("config", process.env.REACT_APP_GA_ID, { debug_mode: true });
    getGA();
  }, [location.pathname]);
  return (
    <div className={styles.container}>
      <FaqList />
    </div>
  );
};

export default Faq;
