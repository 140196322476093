import { Skeleton } from "@mui/material";
import React from "react";
import styles from "./FinanceOverviewListSkeleton.module.scss";
export default function FinanceOverviewListSkeleton() {
  return (
    <>
      <div className={styles.container}>
        <span></span>
        <span className={styles.game_name_section}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            className={styles.game_icon}
          />
          <Skeleton
            animation="wave"
            variant="rectangular"
            className={styles.game_name}
          />
        </span>

        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.revenue}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.iap}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.spend}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.profit}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.install}
        />
      </div>
      <div className={styles.container}>
        <span></span>
        <span className={styles.game_name_section}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            className={styles.game_icon}
          />
          <Skeleton
            animation="wave"
            variant="rectangular"
            className={styles.game_name}
          />
        </span>

        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.revenue}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.iap}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.spend}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.profit}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.install}
        />
      </div>
      <div className={styles.container}>
        <span></span>
        <span className={styles.game_name_section}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            className={styles.game_icon}
          />
          <Skeleton
            animation="wave"
            variant="rectangular"
            className={styles.game_name}
          />
        </span>

        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.revenue}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.iap}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.spend}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.profit}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.install}
        />
      </div>
      <div className={styles.container}>
        <span></span>
        <span className={styles.game_name_section}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            className={styles.game_icon}
          />
          <Skeleton
            animation="wave"
            variant="rectangular"
            className={styles.game_name}
          />
        </span>

        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.revenue}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.iap}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.spend}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.profit}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.install}
        />
      </div>
      <div className={styles.container}>
        <span></span>
        <span className={styles.game_name_section}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            className={styles.game_icon}
          />
          <Skeleton
            animation="wave"
            variant="rectangular"
            className={styles.game_name}
          />
        </span>

        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.revenue}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.iap}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.spend}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.profit}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.install}
        />
      </div>
    </>
  );
}
