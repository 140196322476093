import axios from "axios";

export class HttpClient {
  constructor(baseURL, authErrorEventBus) {
    this.baseURL = baseURL;
    this.authErrorEventBus = authErrorEventBus;
  }
  async axios(url, options) {
    let data;

    await axios(`${this.baseURL}${url}`, {
      ...options,
      headers: {
        "Content-Type": "application/json",
        ...options.headers,
      },
    }).then((res) => {
      data = res;

      if (res.status > 299 || res.status < 200) {
        const message =
          data && data.message ? data.message : "something went wrong!";
        const error = new Error(message);
        if (res.status === 400) {
          this.authErrorEventBus.notify(error);
          return;
        }
        throw error;
      }
    });

    return data;
  }
}

export class AdsNetworkHttpClient {
  constructor(adsNetworkUrl, authErrorEventBus) {
    this.adsNetworkUrl = adsNetworkUrl;
    this.authErrorEventBus = authErrorEventBus;
  }
  async axios(url, options) {
    let data;
    await axios(`${this.adsNetworkUrl}${url}`, {
      ...options,
      headers: {
        "Content-Type": "application/json",
        ...options.headers,
      },
    }).then((res) => {
      data = res;

      if (res.status > 299 || res.status < 200) {
        const message =
          data && data.message ? data.message : "something went wrong!";
        const error = new Error(message);
        if (res.status === 400) {
          this.authErrorEventBus.notify(error);
          return;
        }
        throw error;
      }
    });

    return data;
  }
}
