import moment from "moment";
export default function totalData(maxData, retention, day) {
  let installs = maxData?.map((data) => {
    let dt = new Date(new Date(data?.day).setHours(0, 0, 0, 0));
    if (
      moment(new Date(dt.setDate(dt.getDate() + day))).isBefore(
        new Date(new Date().setHours(0, 0, 0, 0))
      )
    ) {
      return Number(data?.installs);
    }
    return 0;
  });

  let average =
    (retention?.reduce((a, b) => a + b) / installs?.reduce((a, b) => a + b)) *
    100;

  return (average && `${Number(average).toFixed(1)}%`) || "-";
}
