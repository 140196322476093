import { useLocation } from "react-router-dom";
import Router from "./Router/Router";
import { useState, useEffect, memo } from "react";
import moment from "moment";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import styles from "./App.module.scss";
import Header from "./components/Header/Header";

import { useAuth } from "./Context/AuthContext";
import SideBar from "./components/Sidebar/SideBar";
import { usePopup } from "./Context/PopupContext";
import Popup from "./components/Popup/Popup";
import CampaignContext from "./Context/CampaignContext";
import BetaAlert from "./components/BetaAlert/BetaAlert";

function App({
  adsService,
  metaService,
  authService,
  sdkService,
  adsListService,
  maxService,
  adsNetworkService,
  invoiceService,
}) {
  const { logout, user } = useAuth();
  const { isPopup } = usePopup();
  const [menu, setMenu] = useState("CPI Test Process");

  return (
    <div className="App">
      {isPopup && <Popup />}
      <div className={styles.contentsContainer}>
        <div className={styles.mainContainer}>
          <SideBar setMenu={setMenu} menu={menu} user={user} />

          <div className={styles.main}>
            {window.location.pathname.includes("finance") && <BetaAlert />}
            <Router
              adsService={adsService}
              metaService={metaService}
              authService={authService}
              sdkService={sdkService}
              adsListService={adsListService}
              maxService={maxService}
              adsNetworkService={adsNetworkService}
              invoiceService={invoiceService}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(App);
