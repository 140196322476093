import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { useState, useEffect, memo } from "react";
import moment from "moment";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Process from "../pages/Process/Process";
import { useAuth } from "../Context/AuthContext";

import AdsDetail from "../pages/AdsDetail/AdsDetail";
import ProfileEdit from "../pages/ProfileEdit/ProfileEdit";
import NewApp from "../pages/NewApp/NewApp";
import BasicSetting from "../pages/NewApp/BasicSetting/BasicSetting";
import IntegratingSdk from "../pages/NewApp/IntegratingSdk/IntegratingSdk";
import UploadVideo from "../pages/NewApp/UploadVideo/UploadVideo";
import Completion from "../pages/NewApp/Completion/Completion";
import SecondProcess from "../components/SecondProcess/SecondProcess";
import ThirdProcess from "../components/ThirdProcess/ThirdProcess";
import Home from "../pages/Home/Home";
import SetManager from "../components/SetManager/SetManager";
import Faq from "../pages/Faq/Faq";
import PublishingDetail from "../pages/PublishingDetail/PublishingDetail";
import NotPublishing from "../pages/NotPublishing/NotPublishing";
import Progress from "../components/Progress/Progress";
import Complete from "../components/Complete/Complete";
import Notfound from "../error/Notfound";
import Invoice from "../pages/Invoice/Invoice";
import { getCookie } from "../db/cookies";
import InvoiceLogin from "../components/InvoiceLogin/InvoiceLogin";
import { InvoiceProvider } from "../Context/InvoiceContext";
import FinanceOverview from "../pages/FinanceOverview/FinanceOverview";

export default function Router({
  adsService,
  metaService,
  authService,
  sdkService,
  adsListService,
  maxService,
  adsNetworkService,
  invoiceService,
}) {
  const { user, modifingInfo } = useAuth();
  const location = useLocation();
  const [adData, setAdsData] = useState([]);
  const [progressList, setProgressList] = useState([]);
  const [completeList, setCompleteList] = useState([]);
  const [statusCode, setStatusCode] = useState(0);
  const [nextManagerSetup, setNextManagerSetup] = useState(false);
  const [ads, setAds] = useState([]);
  useEffect(() => {
    adsService.allAds(user?.data?.email).then((data) => setAds(data));
  }, [user?.data?.email]);

  useEffect(() => {
    metaService
      .getAllAds(user?.data?.id)
      .then((data) =>
        data.status === 200 ? setAdsData(data.data) : setStatusCode(data.status)
      );
  }, [user?.data?.id]);

  useEffect(() => {
    setProgressList(
      ads.filter((list) => moment().isSameOrBefore(list.endTime))
    );
  }, [ads]);
  useEffect(() => {
    setCompleteList(ads.filter((list) => moment().isAfter(list.endTime)));
  }, [ads]);
  useEffect(() => {
    if (location.pathname === "/progress") {
      localStorage.removeItem("appInfo");
    }
  }, [location.pathname]);

  return (
    <>
      {/* <Header
        user={user}
      menu={menu}
        gameName={gameName}
        gameId={gameId}
        gameIcon={gameIcon}
        setMenu={setMenu}
      /> */}

      <Routes>
        {user?.data?.tutorial && <Route exact path="/" element={<Home />} />}
        {!user?.data?.tutorial && (
          <>
            <Route
              exact
              path="/"
              element={
                <Process userId={user?.data?.id} authService={authService} />
              }
            />
            <Route path="/secondprocess" element={<SecondProcess />} />
            <Route path="/thirdprocess" element={<ThirdProcess />} />
          </>
        )}

        <Route
          exact
          path="/progress"
          element={
            <Progress
              adData={adData}
              adsService={adsService}
              user={user}
              progressList={progressList}
              metaService={metaService}
              adsListService={adsListService}
            />
          }
        />

        <Route
          exact
          path="/progress/:id"
          element={
            <AdsDetail
              metaService={metaService}
              adsListService={adsListService}
              maxService={maxService}
            />
          }
        />
        <Route
          exact
          path="/complete"
          element={
            <Complete
              adData={adData}
              adsService={adsService}
              user={user}
              completeList={completeList}
              metaService={metaService}
              adsListService={adsListService}
            />
          }
        />
        <Route
          exact
          path="/complete/:id"
          element={
            <AdsDetail
              metaService={metaService}
              adsListService={adsListService}
              maxService={maxService}
            />
          }
        />
        <Route
          path="/profile"
          element={
            <InvoiceProvider invoiceService={invoiceService}>
              <ProfileEdit user={user} modifingInfo={modifingInfo} />
            </InvoiceProvider>
          }
        />

        <>
          <Route
            path="/mytest/newapp"
            element={
              <NewApp
                user={user?.data}
                metaService={metaService}
                adsService={adsService}
              />
            }
          />
          <Route
            path="/mytest/newapp/basicsetting"
            element={
              <BasicSetting
                metaService={metaService}
                nextManagerSetup={nextManagerSetup}
                setNextManagerSetup={setNextManagerSetup}
                adsService={adsService}
              />
            }
          />
          <Route path="/mytest/newapp/advenced" element={<SetManager />} />
          <Route
            path="/mytest/newapp/sdk"
            element={
              <IntegratingSdk
                sdkService={sdkService}
                nextManagerSetup={nextManagerSetup}
                setNextManagerSetup={setNextManagerSetup}
                metaService={metaService}
              />
            }
          />
          <Route
            path="/mytest/newapp/videos"
            element={
              <UploadVideo
                adsService={adsService}
                metaService={metaService}
                studioName={user?.data?.studioName}
                adsListService={adsListService}
              />
            }
          />
          <Route path="/mytest/newapp/completion" element={<Completion />} />
        </>
        <Route path="/faq" element={<Faq />} />
        {!user?.data?.publishing && (
          <Route path="/empty" element={<NotPublishing />} />
        )}
        {user?.data?.publishing && (
          <Route
            path="/publishing"
            element={
              <PublishingDetail
                maxService={maxService}
                adsService={adsService}
                metaService={metaService}
                user={user}
                adsNetworkService={adsNetworkService}
              />
            }
          />
        )}
        <Route
          path="/finance/overview"
          element={
            getCookie("Authentication") ? (
              <InvoiceProvider invoiceService={invoiceService}>
                <FinanceOverview />
              </InvoiceProvider>
            ) : (
              <InvoiceLogin path="finance/overview" />
            )
          }
        />
        <Route
          path="finance/invoice"
          element={
            getCookie("Authentication") ? (
              <InvoiceProvider invoiceService={invoiceService}>
                <Invoice />
              </InvoiceProvider>
            ) : (
              <InvoiceLogin path="finance/invoice" />
            )
          }
        />
        <Route path="*" element={<Notfound />} />
      </Routes>
    </>
  );
}
