import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import Modal from "../../common/Modal/Modal";
import ProfileImgEdit from "../../components/ProfileImgEdit/ProfileImgEdit";
import ProfileInfoEdit from "../../components/ProfileInfoEdit/ProfileInfoEdit";
import styles from "./ProfileEdit.module.scss";
import { deleteCookie } from "../../db/cookies";
import { useLocation } from "react-router-dom";
import getGA from "../../service/ga";
import PaymentDetail from "../../components/PaymentDetail/PaymentDetail";
import { getCookie, setCookie } from "../../db/cookies";
import InvoiceLogin from "../../components/InvoiceLogin/InvoiceLogin";
import { useAuth } from "../../Context/AuthContext";
import { useInvoice } from "../../Context/InvoiceContext";
const ProfileEdit = ({ user, modifingInfo }) => {
  const location = useLocation();
  // const { invoiceUser } = useAuth();
  // const { studioId, role } = invoiceUser;
  const { invoiceUser } = useAuth();

  const [profileImg, setProfileImg] = useState(user.data.profileImg);
  const [selectedMenu, setSelectedMenu] = useState({ id: 1 });
  const [updatePassword, setUpdadtePassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [teamSize, setTeamSize] = useState(user.data.teamSize);
  const [portfolioLink, setPortfolioLink] = useState(user.data.portfolioLink);
  const [alertMessage, setAlertMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState({
    studio: {
      id: 0,
      name: "",
      paymentTo: "",
      email: "",
      businessAddress: "",
      phoneNumber: "",
      vatOrGst: "",
    },
    payment: {
      id: "",
      studioId: 0,
      bankName: "",
      bankAddress: "",
      accountName: "",
      accountNumber: "",
      swiftCode: "",
      branchCode: "",
      ifscCode: "",
      routingNumber: "",
    },
  });

  useEffect(() => {
    setSelectedMenu({ id: location?.state?.id ?? 1 });
  }, [location?.state?.id]);
  const menuList = [
    {
      id: 1,
      menu: "Profile",
    },
    {
      id: 2,
      menu: "Payment Details",
    },
  ];

  useEffect(() => {
    setProfileImg(user.data.profileImg);
    setTeamSize(user.data.teamSize);
    setPortfolioLink(user.data.portfolioLink);
  }, [user.data.portfolioLink, user.data.profileImg, user.data.teamSize]);
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_ID);
    ReactGA.send({
      hitType: "pageview",
      path: location.pathname,
      location: location.pathname,
      title: "Profile",
    });
    getGA();
  }, [location.pathname]);
  const onClick = async () => {
    try {
      if (!updatePassword) {
        alert("Please enter your password");
      } else if (updatePassword !== confirmPassword) {
        alert("Please check your password");
      } else if (!teamSize) {
        alert("Please check your team size");
      } else {
        modifingInfo(
          user?.data?.id,
          confirmPassword,
          profileImg,
          portfolioLink,
          teamSize
        )
          .then(() => {
            setAlertMessage("success modifing yout info");
            setShowModal(true);
          })
          .then(() =>
            setTimeout(() => {
              setShowModal(false);
              setCookie("password", confirmPassword, 7);
              window.location.reload();
            }, 1000)
          )
          .catch(console.error);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const onSelectedMenu = (id) => {
    return selectedMenu.id === id;
  };
  const onMenuClick = (id) => {
    setSelectedMenu({ id });
  };

  return (
    <section className={styles.area}>
      <h2 className={styles.title}>Profile</h2>
      <div className={styles.container}>
        <ul className={styles.select_menu}>
          {menuList.map((item) => (
            <li
              data-content={onSelectedMenu(item.id) ? "active" : "false"}
              onClick={() => onMenuClick(item.id)}
            >
              {item.menu}
            </li>
          ))}
        </ul>
        {/* <ProfileImgEdit
          profileImg={profileImg}
          setProfileImg={setProfileImg}
          user={user}
        />
        <ProfileInfoEdit
          updatePassword={updatePassword}
          setUpdadtePassword={setUpdadtePassword}
          confirmPassword={confirmPassword}
          setConfirmPassword={setConfirmPassword}
          teamSize={teamSize}
          setTeamSize={setTeamSize}
          portfolioLink={portfolioLink}
          setPortfolioLink={setPortfolioLink}
          user={user}
          onClick={onClick}
        /> */}
        {selectedMenu.id === 1 ? (
          <>
            {showModal && (
              <Modal setAlertModal={setShowModal} message={alertMessage} />
            )}
            <ProfileImgEdit
              profileImg={profileImg}
              setProfileImg={setProfileImg}
              user={user}
            />
            <ProfileInfoEdit
              updatePassword={updatePassword}
              setUpdadtePassword={setUpdadtePassword}
              confirmPassword={confirmPassword}
              setConfirmPassword={setConfirmPassword}
              teamSize={teamSize}
              setTeamSize={setTeamSize}
              portfolioLink={portfolioLink}
              setPortfolioLink={setPortfolioLink}
              user={user}
              onClick={onClick}
            />
          </>
        ) : getCookie("Authentication") ? (
          <PaymentDetail
            user={user}
            setSelectedMenu={setSelectedMenu}
            paymentInfo={paymentInfo}
            setPaymentInfo={setPaymentInfo}
          />
        ) : (
          <InvoiceLogin
            path="profile"
            type="menuSelect"
            setSelectedMenu={setSelectedMenu}
          />
        )}
      </div>
    </section>
  );
};

export default ProfileEdit;
