import { memo } from "react";
import { createContext, createRef, useContext, useMemo, useState } from "react";

const PopupContext = createContext({});

const contextRef = createRef();
export const PopupProvider = memo(({ children }) => {
  const [isPopup, setIsPopup] = useState(false);
  const context = useMemo(
    () => ({
      isPopup,
      setIsPopup,
    }),
    [isPopup]
  );

  return (
    <PopupContext.Provider value={context}>{children}</PopupContext.Provider>
  );
});

export default PopupContext;
export const fetchToken = () => contextRef.current;
export const usePopup = () => useContext(PopupContext);
