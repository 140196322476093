import React from "react";
import styles from "./IosAdnetwork.module.css";
import adColonyicon from "../../img/24px_ad_Icon/24px_Adcolony.png";
import applovinIcon from "../../img/24px_ad_Icon/24px_Applovin.png";
import mintegralIcon from "../../img/24px_ad_Icon/24px_Mintegral.png";
import ironsourceIcon from "../../img/24px_ad_Icon/24px_IronSource.png";
import pangleIcon from "../../img/24px_ad_Icon/24px_Pangle.png";
import tapjoyIcon from "../../img/24px_ad_Icon/24px_Tapjoy.png";
import verveGroupIcon from "../../img/24px_ad_Icon/24px_VerveGroup.png";
import vungleIcon from "../../img/24px_ad_Icon/24px_Vungle.png";
import inmobiIcon from "../../img/24px_ad_Icon/24px_inmobi.png";
import admobIcon from "../../img/24px_ad_Icon/24px_GoogleAdmob.png";
import fyberIcon from "../../img/24px_ad_Icon/24px_DigitalTurbine.png";
import metaIcon from "../../img/24px_ad_Icon/24px_Meta.png";
import unityIcon from "../../img/24px_ad_Icon/24px_UnityAds.png";
import odeeoIcon from "../../img/24px_ad_Icon/24px_odeeo.png";
import advertyIcon from "../../img/24px_ad_Icon/24px_Adverty.png";
import googleAdsIcon from "../../img/24px_ad_Icon/24px_GoogleAdwords.png";
import tiktokIcon from "../../img/24px_ad_Icon/24px_tiktok.png";
import applesearchIcon from "../../img/24px_ad_Icon/appleIcon.png";
import noneIcon from "../../img/24px_ad_Icon/none.png";
import mobileFuseIcon from "../../img/24px_ad_Icon/96px_mobilefuse.png";
import hyprmxIcon from "../../img/24px_ad_Icon/96px_hyprmx.png";
const IosAdnetwork = ({ iosData }) => {
  const {
    appLovin,
    unity,
    a4g,
    adColony,
    adverty,
    apple,
    fyber,
    google,
    inmobi,
    ironSource,
    meta,
    mintegral,
    odeeo,
    reklemUp,
    tabJoy,
    ticTok,
    verveGroup,
    vungle,
    pangle,
    mobileFuse,
    hyprmx,
  } = iosData;

  return (
    <>
      <tr className={styles.DataBox}>
        <td className={styles.adsName}>
          <img src={adColonyicon} alt="" className={styles.firstAdsflatform} />

          <div>Adcolony</div>
        </td>
        <td>$0.00</td>
        <td>
          {Number(adColony?.adColonyTotalRevenue).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </td>
        <td>
          {Number(adColony?.adColonyTotalRevenue)?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </td>
      </tr>

      <tr className={styles.DataBox}>
        <td className={styles.adsName}>
          <img src={admobIcon} alt="" className={styles.firstAdsflatform} />

          <div>Admob</div>
        </td>
        <td>$0.00</td>
        <td>
          {google?.googleTotalRevenue?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </td>
        <td>
          {google?.googleTotalRevenue?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </td>
      </tr>
      <tr className={styles.DataBox}>
        <td className={styles.adsName}>
          <img src={advertyIcon} alt="" className={styles.firstAdsflatform} />

          <div>Adverty</div>
        </td>
        <td>$0.00</td>
        <td>
          {adverty?.advertyTotalRevenue?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </td>
        <td>
          {adverty?.advertyTotalRevenue?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </td>
      </tr>

      <tr className={styles.DataBox}>
        <td className={styles.adsName}>
          <img src={applovinIcon} alt="" className={styles.firstAdsflatform} />

          <div>Applovin</div>
        </td>
        <td>
          {Number(appLovin?.appLovinTotalSpend)?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </td>
        <td>
          {appLovin?.appLovinTotalRevenue?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </td>
        <td>
          {(
            appLovin?.appLovinTotalRevenue - appLovin?.appLovinTotalSpend
          )?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </td>
      </tr>

      <tr className={styles.DataBox}>
        <td className={styles.adsName}>
          <img
            src={applesearchIcon}
            alt=""
            className={styles.firstAdsflatform}
          />

          <div>AppleSearch</div>
        </td>
        <td>
          {Number(apple?.appleTotalSpend)?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </td>
        <td>$0.00</td>
        <td>
          -
          {Number(apple?.appleTotalSpend)?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </td>
      </tr>
      <tr className={styles.DataBox}>
        <td className={styles.adsName}>
          <img src={noneIcon} alt="" className={styles.firstAdsflatform} />
          <div>A4G</div>
        </td>
        <td>$0.00</td>
        <td>
          {a4g?.a4gTotalRevenue?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </td>
        <td>
          {a4g?.a4gTotalRevenue?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </td>
      </tr>

      <tr className={styles.DataBox}>
        <td className={styles.adsName}>
          <img src={fyberIcon} alt="" className={styles.firstAdsflatform} />

          <div>Fyber</div>
        </td>
        <td>$0.00</td>
        <td>
          {Number(fyber.fyberTotalRevenue)?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </td>
        <td>
          {Number(fyber.fyberTotalRevenue)?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </td>
      </tr>
      <tr className={styles.DataBox}>
        <td className={styles.adsName}>
          <img src={googleAdsIcon} alt="" className={styles.firstAdsflatform} />

          <div>Google Ads</div>
        </td>
        <td>
          {Number(google?.googleTotalSpend).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </td>
        <td>$0.00</td>
        <td>
          -
          {Number(google?.googleTotalSpend)?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </td>
      </tr>
      <tr className={styles.DataBox}>
        <td className={styles.adsName}>
          <img src={hyprmxIcon} alt="" className={styles.firstAdsflatform} />

          <div>Hyprmx</div>
        </td>
        <td>$0.00</td>
        <td>
          {hyprmx?.hyprmxTotalRevenue?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </td>
        <td>
          {hyprmx?.hyprmxTotalRevenue?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </td>
      </tr>
      <tr className={styles.DataBox}>
        <td className={styles.adsName}>
          <img src={inmobiIcon} alt="" className={styles.firstAdsflatform} />
          <div>Inmobi</div>
        </td>
        <td>$0.00</td>
        <td>
          {Number(inmobi?.inmobiTotalRevenue)?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </td>
        <td>
          {Number(inmobi?.inmobiTotalRevenue)?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </td>
      </tr>

      <tr className={styles.DataBox}>
        <td className={styles.adsName}>
          <img
            src={ironsourceIcon}
            alt=""
            className={styles.firstAdsflatform}
          />

          <div>IronSource</div>
        </td>
        <td>
          {Number(ironSource?.ironSourceTotalSpend)?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </td>
        <td>
          {Number(ironSource?.ironSourceTotalRevenue)?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </td>
        <td>
          {(
            Number(ironSource?.ironSourceTotalRevenue) -
            Number(ironSource?.ironSourceTotalSpend)
          )?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </td>
      </tr>
      <tr className={styles.DataBox}>
        <td className={styles.adsName}>
          <img src={metaIcon} alt="" className={styles.firstAdsflatform} />

          <div>Meta</div>
        </td>
        <td>
          {Number(meta?.metaTotalSpend)?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </td>
        <td>
          {Number(meta?.metaTotalRevenue)?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </td>
        <td>
          {(
            Number(meta?.metaTotalRevenue) - Number(meta?.metaTotalSpend)
          )?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </td>
      </tr>

      <tr className={styles.DataBox}>
        <td className={styles.adsName}>
          <img src={mintegralIcon} alt="" className={styles.firstAdsflatform} />

          <div>Mintegral</div>
        </td>
        <td>
          {Number(mintegral?.mintegralTotalSpend)?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </td>
        <td>
          {Number(mintegral?.mintegralTotalRevenue)?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </td>
        <td>
          {(
            Number(mintegral?.mintegralTotalRevenue) -
            Number(mintegral.mintegralTotalSpend)
          )?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </td>
      </tr>
      <tr className={styles.DataBox}>
        <td className={styles.adsName}>
          <img
            src={mobileFuseIcon}
            alt=""
            className={styles.firstAdsflatform}
          />

          <div>MobileFuse</div>
        </td>
        <td>$0.00</td>
        <td>
          {Number(mobileFuse?.mobileFuseTotalReveue)?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </td>
        <td>
          {Number(mobileFuse?.mobileFuseTotalReveue)?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </td>
      </tr>
      <tr className={styles.DataBox}>
        <td className={styles.adsName}>
          <img src={odeeoIcon} alt="" className={styles.firstAdsflatform} />

          <div>Odeeo</div>
        </td>
        <td>$0.00</td>
        <td>
          {Number(odeeo?.odeeoTotalRevenue)?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </td>
        <td>
          {Number(odeeo?.odeeoTotalRevenue)?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </td>
      </tr>
      <tr className={styles.DataBox}>
        <td className={styles.adsName}>
          <img src={pangleIcon} alt="" className={styles.firstAdsflatform} />
          <div>Pangle</div>
        </td>
        <td>$0.00</td>
        <td>
          {pangle?.pangleTotalRevenue?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </td>
        <td>
          {pangle?.pangleTotalRevenue?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </td>
      </tr>
      <tr className={styles.DataBox}>
        <td className={styles.adsName}>
          <img src={noneIcon} alt="" className={styles.firstAdsflatform} />
          <div>Reklemup</div>
        </td>
        <td>$0.00</td>
        <td>
          {Number(reklemUp?.reklemUpTotalRevenue)?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </td>
        <td>
          {Number(reklemUp?.reklemUpTotalRevenue)?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </td>
      </tr>
      <tr className={styles.DataBox}>
        <td className={styles.adsName}>
          <img src={tapjoyIcon} alt="" className={styles.firstAdsflatform} />
          <div>Tapjoy</div>
        </td>
        <td>$0.00</td>
        <td>
          {Number(tabJoy?.tabJoyTotalRevenue)?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </td>
        <td>
          {Number(tabJoy?.tabJoyTotalRevenue)?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </td>
      </tr>
      <tr className={styles.DataBox}>
        <td className={styles.adsName}>
          <img src={tiktokIcon} alt="" className={styles.firstAdsflatform} />
          <div>TikTok</div>
        </td>
        <td>
          {Number(ticTok?.ticTokTotalSpend)?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </td>
        <td>$0.00</td>
        <td>
          -
          {Number(ticTok?.ticTokTotalSpend)?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </td>
      </tr>
      <tr className={styles.DataBox}>
        <td className={styles.adsName}>
          <img src={unityIcon} alt="" className={styles.firstAdsflatform} />
          <div>Unity</div>
        </td>
        <td>
          {Number(unity?.unityTotalSpend)?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </td>
        <td>
          {Number(unity?.unityTotalRevenue)?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </td>
        <td>
          {(unity?.unityTotalRevenue - unity?.unityTotalSpend)?.toLocaleString(
            "en-US",
            {
              style: "currency",
              currency: "USD",
            }
          )}
        </td>
      </tr>
      <tr className={styles.DataBox}>
        <td className={styles.adsName}>
          <img
            src={verveGroupIcon}
            alt=""
            className={styles.firstAdsflatform}
          />
          <div>VerveGroup</div>
        </td>
        <td>$0.00</td>
        <td>
          {Number(verveGroup?.verveGroupTotalRevenue)?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </td>
        <td>
          {Number(verveGroup?.verveGroupTotalRevenue)?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </td>
      </tr>

      <tr className={styles.DataBox}>
        <td className={styles.adsName}>
          <img src={vungleIcon} alt="" className={styles.firstAdsflatform} />
          <div>Vungle</div>
        </td>
        <td>$0.00</td>
        <td>
          {Number(vungle?.vungleTotalRevenue)?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </td>
        <td>
          {Number(vungle?.vungleTotalRevenue)?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </td>
      </tr>
    </>
  );
};

export default IosAdnetwork;
