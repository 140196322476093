import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import ReactGA from "react-ga4";
import getGA from "../../../service/ga";
import VideoUploadBox from "../../../components/UploadVideo/VideoUploadBox/VideoUploadBox";
import styles from "./UploadVideo.module.css";
import Loading from "../../../common/Loading";
import Modal from "../../../common/Modal/Modal";
import whiteMark from "../../../img/settingBoxs/whiteMark.png";
import questionMark from "../../../img/videoGuide/questionMark.png";
import { useAuth } from "../../../Context/AuthContext";
const UploadVideo = ({
  adsService,
  metaService,
  studioName,
  adsListService,
}) => {
  const { user } = useAuth();
  const [videos, setVideos] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [videoIds, setVideoIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [firstVideoId, setFirstVideoId] = useState();
  const [secondVideoId, setSecondVideoId] = useState();
  const [thirdVideoId, setThirdVideoId] = useState();
  const [fourthVideoId, setFourthVideoId] = useState();
  const [isToastMessage, setIsToastMessage] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);
  const [isClick, setIsClick] = useState(false);
  const [alertMessge, setAlertMessage] = useState("");
  const [cpiVideoGuide, setCpiVideoGuide] = useState(false);

  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_ID);
    ReactGA.send({
      hitType: "pageview",
      path: location.pathname,
      location: location.pathname,
      title: "Fifth",
    });
    getGA();
  }, [location.pathname]);
  useEffect(() => {
    if (location?.state?.data === undefined) {
      let appId = localStorage.getItem("app_Id");

      const result = async () =>
        await metaService.checkAppId(appId).then((data) => {
          if (!data) {
            localStorage.removeItem("app_data");
          } else if (data) {
            localStorage.setItem("app_data", JSON.stringify(data?.data));
          }
        });
      result();
    } else {
      localStorage.setItem("firstCheck", true);
      localStorage.setItem("secondCheck", true);
      localStorage.setItem("thirdCheck", true);
      localStorage.setItem("fourthCheck", true);
      localStorage.setItem("app_Id", location?.state?.data?.appId);
      localStorage.setItem("type", location?.state?.data?.type);
      localStorage.setItem("gameName", location?.state?.data?.gameName);
      localStorage.setItem("gameIcon", location?.state?.data?.gameIcon);
      localStorage.setItem("genre", location?.state?.data?.genre);
      let appId = localStorage.getItem("app_Id");
      const result = async () =>
        await metaService.checkAppId(appId).then((data) => {
          if (!data) {
            localStorage.removeItem("app_data");
          } else if (data) {
            localStorage.setItem("app_data", JSON.stringify(data?.data));
          }
        });
      result();
    }
  }, []);

  const onClick = (e) => {
    if (fourthVideoId === undefined) {
      setIsToastMessage(true);
      setTimeout(() => {
        setIsToastMessage(false);
      }, 1000);
    } else if (fourthVideoId) {
      if (!isClick) {
        setIsClick(true);
        e.preventDefault();
        adsService
          .insertAppId(
            localStorage.getItem("app_Id"),
            localStorage.getItem("gameIcon")
          )
          .then((data) => {
            if (data) {
              adsListService
                .insertList(localStorage.getItem("app_Id"))
                .then((data) => data)
                .then(() => {
                  let gameName = JSON.parse(
                    localStorage.getItem("app_data")
                  ).name;
                  let gameLink = JSON.parse(localStorage.getItem("app_data"))
                    .object_store_urls.google_play;
                  let videoId = [
                    firstVideoId?.videoId,
                    secondVideoId?.videoId,
                    thirdVideoId?.videoId,
                    fourthVideoId?.videoId,
                  ];
                  setLoading(true);
                  metaService
                    .creativeAds(
                      videoId,
                      gameLink,
                      studioName,
                      localStorage.getItem("app_Id"),
                      gameName,
                      user.data.id
                    )
                    .then(async (data) => {
                      if (!data) {
                        setLoading(false);
                      } else if (data) {
                        const curr = new Date();
                        const utc =
                          curr.getTime() + curr.getTimezoneOffset() * 60 * 1000;
                        const START_TIME_SET = 15 * 60 * 60 * 1000;
                        const END_TIME_SET = 111 * 60 * 60 * 1000;
                        const startTime = new Date(
                          utc + START_TIME_SET
                        ).toISOString(); //start_time
                        const endTime = new Date(
                          utc + END_TIME_SET
                        ).toISOString(); //end_time
                        let gameName = JSON.parse(
                          localStorage.getItem("app_data")
                        ).name;
                        let gameLink = JSON.parse(
                          localStorage.getItem("app_data")
                        ).object_store_urls.google_play;
                        let gameIcon = JSON.parse(
                          localStorage.getItem("appInfo")
                        ).gameIcon;
                        await adsListService
                          .syncGame({
                            email: user.data.email,
                            bundleId: gameLink.split("id=")[1],
                            metaUAId: localStorage.getItem("app_Id"),
                            name: gameName,
                            gameIconUrl: gameIcon,
                            createDay: moment(startTime).format("YYYY-MM-DD"),
                            endDay: moment(endTime).format("YYYY-MM-DD"),
                            type:
                              JSON.parse(localStorage.getItem("appInfo"))
                                .type ?? localStorage.getItem("type"),
                            gameUrl: gameLink,
                            genre:
                              JSON.parse(localStorage.getItem("appInfo"))
                                .genre ?? localStorage.getItem("genre"),
                            source: "PUBLISHING",
                          })
                          .then((res) => {
                            setLoading(false);
                            localStorage.removeItem("app_Id");
                            localStorage.removeItem("app_data");
                            localStorage.removeItem("gameName");
                            localStorage.removeItem("gameIcon");
                            localStorage.removeItem("type");
                            localStorage.removeItem("firstCheck");
                            localStorage.removeItem("secondCheck");
                            localStorage.removeItem("thirdCheck");
                            localStorage.removeItem("fourthCheck");
                            localStorage.removeItem("genre");
                            navigate("/mytest/newapp/completion", {
                              data: data,
                            });
                          });
                      }
                    });
                });
            }
          });
      } else if (isClick) {
        setAlertMessage("Generating campaign, please wait…");
        setIsShowModal(true);
      }
    }
  };

  return (
    <div className={styles.container}>
      <img
        src={questionMark}
        alt=""
        className={styles.markIcon}
        onClick={() => setCpiVideoGuide(true)}
      />
      {isShowModal && (
        <Modal message={alertMessge} setAlertModal={setIsShowModal} />
      )}
      <div className={styles.progress}>
        <div className={styles.firstCircle}>v</div>
        <div className={styles.line}></div>
        <div className={styles.secondCircle}>v</div>
        <div className={styles.line}></div>
        <div className={styles.thirdCircle}>3</div>
      </div>
      <div className={styles.title}>Add 4 videos for campaign</div>
      <VideoUploadBox
        videos={videos}
        setVideos={setVideos}
        metaService={metaService}
        adsService={adsService}
        firstVideoId={firstVideoId}
        setFirstVideoId={setFirstVideoId}
        secondVideoId={secondVideoId}
        setSecondVideoId={setSecondVideoId}
        thirdVideoId={thirdVideoId}
        setThirdVideoId={setThirdVideoId}
        fourthVideoId={fourthVideoId}
        setFourthVideoId={setFourthVideoId}
        videoIds={videoIds}
        setCpiVideoGuide={setCpiVideoGuide}
        cpiVideoGuide={cpiVideoGuide}
      />
      <div className={styles.btnContainer}>
        {isToastMessage && (
          <div className={styles.messageContainer}>
            <img src={whiteMark} alt="" className={styles.whiteMark} />
            <div className={styles.message}>
              Please upload 4 videos to get the most accurate results.
            </div>
          </div>
        )}
        <button onClick={onClick} className={styles.submitBtn}>
          {!loading ? (
            "Submit"
          ) : (
            <Loading color={"#FFFFFF"} width={"20px"} height={"20px"} />
          )}
        </button>
      </div>
    </div>
  );
};

export default UploadVideo;
//Please upload 4 videos to get the most accurate results.
