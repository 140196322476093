import React from "react";
import BonusAndTotalAmount from "../BonusAndTotalAmount/BonusAndTotalAmount";
import MonthlyNetRevenue from "../MonthlyNetRevenue/MonthlyNetRevenue";
import PayableRevenue from "../PayableRevenue/PayableRevenue";
import styles from "./InvoiceGameDetail.module.scss";
export default function InvoiceGameDetail({ data, date }) {
  return (
    <section className={styles.container}>
      <section className={styles.tables}>
        <MonthlyNetRevenue data={data} date={date} />
        <PayableRevenue data={data} />
        <BonusAndTotalAmount data={data} />
      </section>
      <section className={styles.info_text}>
        <p>
          *For data accuracy, monthly activity calculations are delayed by two
          months.
        </p>
        <p>
          **Revenue share becomes payable once recoupable payments have been
          reimbursed and the amount reaches $500.
        </p>
      </section>
    </section>
  );
}
