import React, { useCallback, useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import moment from "moment";
import lockedImg from "../../img/Invoice/locked.png";
import FinanceOverviewDataList from "../../components/FinanceOverviewDataList/FinanceOverviewDataList";
import FinanceOverviewSummary from "../../components/FinanceOverviewSummary/FinanceOverviewSummary";
import ReactCalander from "../../components/ReactCalander/ReactCalander";
import { useAuth } from "../../Context/AuthContext";
import { useInvoice } from "../../Context/InvoiceContext";
import { deleteCookie, getCookie } from "../../db/cookies";
import styles from "./FinanceOverview.module.scss";

import { ReactComponent as DownIcon } from "../../img/FinaceOverview/down.svg";
import { Link } from "react-router-dom";

export default function FinanceOverview() {
  const { invoiceUser } = useAuth();
  const { getOverviewData, studioInvoiceInfo } = useInvoice();
  const [selectDate, setSelectDate] = useState({
    startDate: new Date(moment().subtract(10, "day")),
    endDate: new Date(moment().subtract(3, "day")),
    key: "selection",
  });
  const calanderRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const [totalData, setTotalData] = useState({
    totalAppleIAP: 0,
    totalGoogleIAP: 0,
    totalIAP: 0,
    totalInstalls: 0,
    totalProfit: 0,
    totalRevenue: 0,
    totalSpend: 0,
  });
  const [overviewList, setOverviewList] = useState([]);
  const [isPayment, setIsPayment] = useState(false);
  const [isSelectCalander, setIsSelectCalander] = useState(false);
  useEffect(() => {
    if (getCookie("Authentication")) {
      studioInvoiceInfo(invoiceUser.studioId).then((res) => {
        if (res.status === 401) {
          deleteCookie("Authentication");
          if (!getCookie("Authentication")) {
            window.location.reload();
          }
        } else {
          setIsPayment(res.data.isPayment);
        }
      });
    }
  }, [invoiceUser, studioInvoiceInfo]);
  useEffect(() => {
    if (getCookie("Authentication")) {
      if (isPayment) {
        setIsLoading(true);
        const startDate = moment(selectDate.startDate).format("YYYY-MM-DD");
        const endDate = moment(selectDate.endDate).format("YYYY-MM-DD");
        getOverviewData(startDate, endDate).then((res) => {
          if (res.status === 401) {
            deleteCookie("Authentication");
            if (!getCookie("Authentication")) {
              window.location.reload();
            }
          } else if (res.status === 400) {
            setIsLoading(false);
            setOverviewList([]);
          } else {
            setIsLoading(false);
            setTotalData({
              totalAppleIAP: res.data.totalAppleIAP,
              totalGoogleIAP: res.data.totalGoogleIAP,
              totalIAP: res.data.totalIAP,
              totalInstalls: res.data.totalInstalls,
              totalProfit: res.data.totalProfit,
              totalRevenue: res.data.totalRevenue,
              totalSpend: res.data.totalSpend,
            });
            setOverviewList(res.data.overview);
          }
        });
      }
    }
  }, []);

  const onCalanderRangeApply = useCallback(() => {
    setIsLoading(true);
    setIsSelectCalander(false);
    const startDate = moment(selectDate.startDate).format("YYYY-MM-DD");
    const endDate = moment(selectDate.endDate).format("YYYY-MM-DD");
    getOverviewData(startDate, endDate).then((res) => {
      if (res.status === 401) {
        deleteCookie("Authentication");
        if (!getCookie("Authentication")) {
          window.location.reload();
        }
      } else if (res.status === 400) {
        setIsLoading(false);
        setOverviewList([]);
      } else {
        setIsLoading(false);
        setTotalData({
          totalAppleIAP: res.data.totalAppleIAP,
          totalGoogleIAP: res.data.totalGoogleIAP,
          totalIAP: res.data.totalIAP,
          totalInstalls: res.data.totalInstalls,
          totalProfit: res.data.totalProfit,
          totalRevenue: res.data.totalRevenue,
          totalSpend: res.data.totalSpend,
        });
        setOverviewList(res.data.overview);
      }
    });
  }, [selectDate]);

  useEffect(() => {
    const calanderOutClickHandler = (e) => {
      if (calanderRef?.current?.contains(e.target)) {
        return setIsSelectCalander(true);
      } else {
        return setIsSelectCalander(false);
      }
    };
    document.addEventListener("mousedown", calanderOutClickHandler);
    return () =>
      document.removeEventListener("mousedown", calanderOutClickHandler);
  }, []);

  return (
    <section className={styles.container}>
      {isPayment ? (
        <>
          <div className={styles.top_section}>
            <span className={styles.welcome_text}>
              Hello, {invoiceUser?.studioName} 👋
            </span>
            <span className={styles.calander_area}>
              <p className={styles.calander_area_title}>Date</p>
              <button
                className={styles.select_btn}
                onClick={() => setIsSelectCalander((prev) => !prev)}
                data-content={isSelectCalander && "active"}
              >
                <p>
                  {moment(selectDate.endDate).isSame(selectDate.endDate, "year")
                    ? `${moment(selectDate.startDate).format(
                        "MMM DD"
                      )} - ${moment(selectDate.endDate).format("MMM DD, YYYY")}`
                    : `${moment(selectDate.startDate).format(
                        "MMM DD, YYYY"
                      )} - ${moment(selectDate.endDate).format(
                        "MMM DD, YYYY"
                      )}`}
                </p>
                <DownIcon />
              </button>
              {isSelectCalander && (
                <ReactCalander
                  selectDate={selectDate}
                  setSelectDate={setSelectDate}
                  setIsSelectCalander={setIsSelectCalander}
                  onCalanderRangeApply={onCalanderRangeApply}
                  calanderRef={calanderRef}
                />
              )}
            </span>
          </div>
          <FinanceOverviewSummary data={totalData} isLoading={isLoading} />
          <FinanceOverviewDataList
            list={overviewList}
            isLoading={isLoading}
            selectDate={selectDate}
          />
        </>
      ) : (
        <div className={styles.empty_payment}>
          <h2>Want to unlock this page?</h2>
          <img src={lockedImg} alt="img" />
          <p>
            Please fill in the studio and payment information in your profile.
          </p>
          <Link to="/profile" state={{ id: 2 }}>
            Go to profile
          </Link>
        </div>
      )}
    </section>
  );
}
