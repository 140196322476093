export default class AdsListService {
  constructor(http, tokenStorage) {
    this.http = http;
    this.tokenStorage = tokenStorage;
  }
  async allAds(appId) {
    return await this.http
      .axios(`/adslist/${appId}`, {
        method: "GET",
      })
      .then((res) => res.data)
      .catch(console.error);
  }
  async syncGame(data) {
    return await this.http
      .axios("/test/common/syncGame", {
        method: "POST",
        data,
      })
      .then((res) => res)
      .catch((e) => e.response);
  }
  async insertList(appId) {
    return await this.http
      .axios(`/adslist/ad`, {
        method: "POST",
        data: JSON.stringify({ appId }),
      })
      .then((res) => {
        return res.data;
      });
  }
}
