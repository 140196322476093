import React from "react";
import styles from "./PrivacyModal.module.css";
const PrivacyModal = ({ setShowModal, navigate }) => {
  const onClick = () => {
    setShowModal(false);
    navigate("/mytest/newapp/basicsetting");
  };
  return (
    <div className={styles.container}>
      <div className={styles.modalContainer}>
        <div className={styles.privacyTitle}>
          <div className={styles.privacyTitleText}>
            Accept MondayOFF's Terms and Conditions
          </div>
        </div>
        <div className={styles.scroll}>
          <div className={styles.privacyContainer}>
            <div className={styles.privacyContext}>
              <div className={styles.one}>
                Testing with{" "}
                <span className={styles.italyMedium}>MondayOFF Dashboard</span>{" "}
                is limited to hyper-casual-related mobile games only. To
                guarantee a smooth process in the case of publishing, games
                should be made with Unity.
              </div>
              <div className={styles.two}>
                <span className={styles.italyMedium}>MondayOFF</span> considers
                transparency and trusts its highest priority when working with
                its partners. Our business follows a detailed process to ensure
                safety and mutual trust with partner studios.
                <br />
                <br />
                <span className={styles.italyMedium}>
                  “MondayOFF Dashboard”
                </span>{" "}
                provides accurate data in a fast and safe manner. By submitting
                your game to{" "}
                <span className={styles.italyMedium}>
                  “MondayOFF Dashboard,”
                </span>{" "}
                you will have access to features that will help improve game
                data and support you on the way towards publishing. This
                includes entering into a{" "}
                <span className={styles.italicBold}>
                  “Mutual Partnership Agreement”
                </span>{" "}
                shall the studio wants to work on or publish a game with
                MondayOFF.
              </div>
              <div className={styles.three}>
                In case of mutual agreement to further develop or publish a game
                in collaboration with MondayOFF, the studio accepts the
                conditions per the&nbsp;
                <span className={styles.italicBold}>
                  “Mutual Partnership Agreement.”
                </span>{" "}
                The conditions include the following:
              </div>
              <div className={styles.four}>
                1. After a mutual agreement to publish your game with{" "}
                <span className={styles.italyMedium}>MondayOFF</span>, you
                accept the terms and conditions of the{" "}
                <span className={styles.italicBold}>
                  “Mutual Partnership Agreement.”
                </span>
              </div>
              <div className={styles.five}>
                2. After submitting a game to the{" "}
                <span className={styles.italyMedium}>
                  “MondayOFF Dashboard,”
                </span>
                the following actions will be restricted for 4 (four) weeks from
                the submission date:
                <ul className={styles.list}>
                  <li>
                    - The act of testing or publishing promotion of the game
                    through a third party.
                  </li>
                  <li>
                    - The act of distributing games with similar or the same
                    mechanism and the same or a similar game.
                  </li>
                  <li>
                    - The act of selling or proposing to sell the submitted
                    game.
                  </li>
                </ul>
                <div className={styles.five_two}>
                  2.1. If the total CPI costs less than $0.70 on completion of
                  the test,{" "}
                  <span className={styles.italyMedium}>MondayOFF</span> may
                  request to work based on a publishing contract on the project
                  in question. In this case, the studio shall work with{" "}
                  <span className={styles.italyMedium}>MondayOFF</span> on
                  publishing the game together.
                </div>
              </div>
              <div className={styles.six}>
                <div className={styles.sixTitle}>
                  3. MondayOFF Party ‘Spring Festa’{" "}
                  <span className={styles.underline}>
                    (2023/02/20/ - 2023/03/24)
                  </span>
                </div>
                <div className={styles.six_one}>
                  By participating in this competition{" "}
                  <span className={styles.italyMedium}>(MondayOFF Party)</span>{" "}
                  you agree to the collection and use of personal information
                  related to these Terms and Conditions.
                </div>
                <div className={styles.six_two}>
                  MondayOFF Party’s Terms and Conditions have legal effect
                  between MondayOFF (“MondayOFF,” “we,” “us,” “our”) and a
                  mobile application developer or development studio (“you,”
                  “developer,” or “studio”). MondayOFF is the primary management
                  body. MondayOFF shall collect and utilize data related to
                  these Terms and Conditions.
                </div>
                <div className={styles.six_three}>
                  Submitting a game means agreeing to participate in the
                  MondayOFF Competition and for MondayOFF to collect and utilize
                  the submitted information. By not agreeing with the above,
                  participation will not be possible in the competition hosted
                  by MondayOFF.
                </div>
                <div className={styles.six_four}>
                  <div>
                    Competition Date{" "}
                    <span className={styles.underline}>
                      (23/02/20 to 23/03/24)
                    </span>
                  </div>
                  <br />
                  During the competition period of 23/02/20 to 23/03/24
                  (February 20th, 12:00 AM UTC+0 to March 24th 11:59:59 PM
                  (UTC+0)), you can submit your game to the MondayOFF dashboard
                  to participate in MondayOFF Party. (“Game” refers to
                  hyper-casual, casual, and idle arcade mobile games only).
                  <div>
                    Submitting a game means that you agree to the Terms and
                    Conditions stated above and thus agree to the MondayOFF
                    Dashboard Terms and Conditions.
                  </div>
                </div>
                <div className={styles.six_five}>
                  After game submission, MondayOFF shall conduct a test for each
                  game and has the right to approve and reject the test for the
                  submitted game.
                </div>
                <div className={styles.six_six}>
                  After the testing period, MondayOFF shall present the results
                  to the studio or developer, including those who do not earn an
                  award from the competition.
                </div>
                <div className={styles.six_seven}>
                  MondayOFF reserves the right to decide on the competition’s
                  cancellation, suspension, change, or extension at its own
                  discretion.
                </div>
              </div>
              <div className={styles.seven}>
                <div className={styles.seven_one}>
                  For more information, please check{" "}
                  <a
                    href="https://events.mondayoff.me/terms"
                    target="_blank"
                    rel="noreferrer"
                    className={styles.link}
                  >
                    https://events.mondayoff.me/terms
                  </a>
                </div>
              </div>
              <button className={styles.agreeBtn} onClick={onClick}>
                Agree
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyModal;
