import React, { useEffect, useState } from "react";
import { DateRangePicker } from "react-date-range";
import moment from "moment";
import { ReactComponent as CheckIcon } from "../../img/FinaceOverview/calanderCheck.svg";

import styles from "./ReactCalander.module.scss";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

export default function ReactCalander({
  selectDate,
  setSelectDate,
  setIsSelectCalander,
  calanderRef,
  onCalanderRangeApply,
}) {
  const [pastSevenDays, setPastSevenDays] = useState(true);
  const [pastFourteenDays, setPastFourteenDays] = useState(false);
  const [pastThirdtyDays, setPastThirdtyDays] = useState(false);
  const [pastSixtyDays, setPastSixtyDays] = useState(false);
  const onPickDate = (e) => {
    setSelectDate((prev) => ({
      ...prev,
      startDate: e.selection.startDate,
      endDate: e.selection.endDate,
    }));
  };
  useEffect(() => {
    setPastSevenDays(true);
  }, []);

  return (
    <div className={styles.calander} ref={calanderRef}>
      <DateRangePicker
        className={styles.react_calander}
        ranges={[selectDate]}
        onChange={onPickDate}
        showSelectionPreview={false}
        maxDate={new Date(moment().subtract(3, "day"))}
        showDateDisplay={false}
        staticRanges={[
          {
            label: (
              <span className={styles.range_day_btn}>
                <p>Past 7 days</p>
                {pastSevenDays && <CheckIcon />}
              </span>
            ),
            range: () => ({
              startDate: new Date(moment().subtract(10, "day")),
              endDate: new Date(moment().subtract(3, "day")),
            }),
            isSelected: (range) => {
              if (
                moment
                  .duration(moment(range.endDate).diff(moment(range.startDate)))
                  .asDays() === 7
              ) {
                setPastSevenDays(true);
                return pastSevenDays;
              } else {
                setPastSevenDays(false);
                return pastSevenDays;
              }
            },
          },
          {
            label: (
              <span className={styles.range_day_btn}>
                <p>Past 14 days</p>
                {pastFourteenDays && <CheckIcon />}
              </span>
            ),
            range: () => ({
              startDate: new Date(moment().subtract(17, "day")),
              endDate: new Date(moment().subtract(3, "day")),
            }),
            isSelected: (range) => {
              if (
                moment
                  .duration(moment(range.endDate).diff(moment(range.startDate)))
                  .asDays() === 14
              ) {
                setPastFourteenDays(true);
                return pastFourteenDays;
              } else {
                setPastFourteenDays(false);
                return pastFourteenDays;
              }
            },
          },
          {
            label: (
              <span className={styles.range_day_btn}>
                <p>Past 30 days</p>
                {pastThirdtyDays && <CheckIcon />}
              </span>
            ),
            range: () => ({
              startDate: new Date(moment().subtract(33, "day")),
              endDate: new Date(moment().subtract(3, "day")),
            }),
            isSelected: (range) => {
              if (
                moment
                  .duration(moment(range.endDate).diff(moment(range.startDate)))
                  .asDays() === 30
              ) {
                setPastThirdtyDays(true);
                return pastThirdtyDays;
              } else {
                setPastThirdtyDays(false);
                return pastThirdtyDays;
              }
            },
          },
          {
            label: (
              <span className={styles.range_day_btn}>
                <p>Past 60 days</p>
                {pastSixtyDays && <CheckIcon />}
              </span>
            ),
            range: () => ({
              startDate: new Date(moment().subtract(63, "day")),
              endDate: new Date(moment().subtract(3, "day")),
            }),
            isSelected: (range) => {
              if (
                moment
                  .duration(moment(range.endDate).diff(moment(range.startDate)))
                  .asDays() === 60
              ) {
                setPastSixtyDays(true);
                return pastSixtyDays;
              } else {
                setPastSixtyDays(false);
                return pastSixtyDays;
              }
            },
          },
        ]}
        inputRanges={[]}
        weekdayDisplayFormat="EEEEE"
        minDate={new Date(moment("2022-01-01"))}
      />
      <div className={styles.apply_section}>
        <span className={styles.date_range_view}>
          <p>Range:</p>
          <p>
            {moment(selectDate.startDate).format("DD MMM YYYY")} -{" "}
            {moment(selectDate.endDate).format("DD MMM YYYY")}
          </p>
        </span>
        <span className={styles.buttons}>
          <button
            className={styles.cancel}
            onClick={() => setIsSelectCalander(false)}
          >
            <p>cancel</p>
          </button>
          <button className={styles.apply} onClick={onCalanderRangeApply}>
            <p>Apply</p>
          </button>
        </span>
      </div>
    </div>
  );
}
