import React, { useEffect, useState } from "react";
import * as cookie from "../../db/cookies.js";
import styles from "./Login.module.scss";
import letterLogo from "../../img/logo/letterLogo.png";
import defaultImg from "../../img/defaultProfile/default_profile.png";
import { memo } from "react";
import Modal from "../../common/Modal/Modal.jsx";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import arrow from "../../img/login/arrow.png";
import inactiveEye from "../../img/login/inactiveEye.png";
import activeEye from "../../img/login/activeEye.png";
import lockIcon from "../../img/login/lock.png";
import getGA from "../../service/ga.js";
import Crypto from "crypto-js";
import moment from "moment";
import axios from "axios";
const Login = memo(({ onLogin, onSignUp, sendPassword }) => {
  const sizeList = [
    { id: 1, size: "1" },
    { id: 2, size: "2 - 5" },
    { id: 3, size: "10 +" },
  ];
  const [signup, setSignup] = useState(false);
  const [conformPassword, setConformPassword] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [studioName, setStudioName] = useState("");
  const [teamSize, setTeamSize] = useState("");
  const [portfolio, setPortfolio] = useState("");
  const [saveId, setSaveId] = useState(true);
  const [agree, setAgree] = useState(false);
  const [discoverPassword, setDiscoverPassword] = useState(false);
  const profileImg = defaultImg;
  const [showModal, setShowModal] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [isClick, setIsClick] = useState(false);
  const [news, setNews] = useState(false);
  const [viewPassword, setViewPassword] = useState(false);
  const [signupViewPassword, setSignupViewPassword] = useState(false);
  const [signupConfirmViewPassword, setSignupConfirmViewPassword] =
    useState(false);
  const [emailValidation, setEmailValidation] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState(false);
  const [viewDropDown, setViewDropDown] = useState(false);

  const [select, setSelect] = useState({ id: 1, size: "1" });
  const location = useLocation();
  const expires = moment().add("7", "day").toDate();
  let emailRule =
    /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i; //이메일 정규식
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_ID);
    ReactGA.send({
      hitType: "pageview",
      path: location.pathname,
      location: location.pathname,
      title: "Login",
    });
    ReactGA.gtag("config", process.env.REACT_APP_GA_ID, { debug_mode: true });
    getGA();
  }, [location.pathname]);
  const isSelect = (item) => {
    return select?.id === item.id;
  };
  const onSubmit = (event) => {
    event.preventDefault();
    if (signup) {
      if (!emailRule.test(email)) {
        setAlertMessage("Please enter a valid email address");
        setShowModal(true);
      } else if (password < 5) {
        setAlertMessage("password should be at least 5 characters");
        setShowModal(true);
      } else if (password !== conformPassword) {
        setAlertMessage("please check your password");
        setShowModal(true);
      } else if (!studioName) {
        setAlertMessage("Enter your studio name");
        setShowModal(true);
      } else if (!teamSize) {
        setAlertMessage("Enter your team size");
        setShowModal(true);
      } else if (!agree) {
        setAlertMessage("Please read and agree to the Terms and Conditions");
        setShowModal(true);
      } else {
        if (!isClick) {
          setIsClick(true);
          cookie.setCookie("email", email, 7);
          cookie.setCookie("password", password, 7);
          cookie.setCookie("check", saveId, 7);

          onSignUp(
            email,
            password,
            studioName,
            teamSize,
            portfolio,
            profileImg,
            news
          ).then((res) => {
            if (res.status !== 201) {
              setIsClick(false);
              setAlertMessage(res.data.message);
              setShowModal(true);
            }
          });
        } else {
          setAlertMessage("Generating.");
          setShowModal(true);
        }
      }
    } else {
      if (!emailRule.test(email)) {
        setAlertMessage("Please enter a valid email address");
        setShowModal(true);
      } else if (password.length < 5) {
        setAlertMessage("Please enter a valid password");
        setShowModal(true);
      } else {
        if (saveId) {
          let cipherPassword = Crypto.AES.encrypt(
            password,
            "admin_passowrd"
          ).toString();

          cookie.setCookie("email", email, { expires });
          cookie.setCookie("password", cipherPassword, {
            expires,
            secure: true,
            sameSite: "none",
          });
          cookie.setCookie("saveId", saveId, { expires });
        } else if (saveId === false) {
          cookie.deleteCookie("email");
          cookie.deleteCookie("password");
          cookie.deleteCookie("check");
        }
        onLogin(email, password)
          .then((data) => {
            if (!data) {
              setAlertMessage("Invalid email or password");
              setShowModal(true);
            }
          })
          .catch(() => {});
      }
    }
  };
  const signupOnClick = () => {
    axios("https://api.ip.pe.kr/json/", {
      method: "GET",
    }).then((data) => {
      if (data.data.country_code === "KR") {
        console.log("KR block");
      } else {
        setSignup(true);
      }
    });
  };
  useEffect(() => {
    if (cookie.getCookie("email") && cookie.getCookie("password")) {
      let bytes = Crypto.AES.decrypt(
        cookie.getCookie("password"),
        "admin_passowrd"
      );
      let originalText = bytes.toString(Crypto.enc.Utf8);
      setEmail(cookie.getCookie("email"));
      setPassword(originalText);
    }
  }, []);

  const onChange = (event) => {
    const {
      target: { name, value, checked },
    } = event;

    switch (name) {
      case "email":
        setEmailValidation(emailRule.test(value));
        return setEmail(value.replace(/\s/g, ""));
      case "password":
        value.length > 5
          ? setPasswordValidation(true)
          : setPasswordValidation(false);
        return setPassword(value);
      case "conformPassword":
        return setConformPassword(value);
      case "studioName":
        return setStudioName(value);
      case "portfolio":
        return setPortfolio(value);
      case "saveId":
        return setSaveId(checked);
      case "agree":
        return setAgree(checked);
      case "news":
        return setNews(checked);
      default:
    }
  };

  const sendEmailPassword = () => {
    return sendPassword(email)
      .then(() => {
        setShowModal(true);
        setAlertMessage(
          "Please check the temporary password sent to your email."
        );
      })
      .catch(console.error);
  };

  return (
    <div className={styles.container}>
      {!signup & !discoverPassword ? (
        <div className={styles.loginContainer}>
          {showModal && (
            <Modal message={alertMessage} setAlertModal={setShowModal} />
          )}
          <div className={styles.titleContainer}>
            <img src={letterLogo} alt="logo" className={styles.logo} />
          </div>
          <form onSubmit={onSubmit} className={styles.loginForm}>
            <div className={styles.loginEmail}>
              <div className={styles.loginEmailText}>Email</div>
              <input
                name="email"
                type="email"
                value={email}
                onChange={onChange}
                required
                className={
                  emailValidation
                    ? styles.loginEmailInput
                    : styles.loginEmailInputNotVaild
                }
                placeholder="Email"
              />
            </div>
            <div className={styles.loginPassword}>
              <div className={styles.loginPasswordText}>Password</div>
              <div className={styles.loginPasswordBox}>
                <input
                  name="password"
                  type={viewPassword ? "text" : "password"}
                  value={password}
                  onChange={onChange}
                  className={
                    passwordValidation === false
                      ? styles.loginPasswordNotValid
                      : styles.loginPasswordInput
                  }
                  placeholder="Password"
                  autoComplete="on"
                />
                {viewPassword ? (
                  <img
                    src={activeEye}
                    alt=""
                    className={styles.activeEye}
                    onClick={() => setViewPassword(false)}
                  />
                ) : (
                  <img
                    src={inactiveEye}
                    alt=""
                    className={styles.inactiveEye}
                    onClick={() => setViewPassword(true)}
                  />
                )}
              </div>
            </div>

            <div className={styles.saveAndPasswordContainer}>
              <input
                type="checkbox"
                id="saveId"
                name="saveId"
                checked={saveId}
                onChange={onChange}
                className={styles.saveInfo}
              />
              <label htmlFor="saveId" className={styles.customCheckbox}>
                <span></span>
                <div className={styles.rememberText}>Remember me</div>
              </label>
              <div
                className={styles.discoverPassword}
                onClick={() => setDiscoverPassword(true)}
              >
                Forgot password?
              </div>
            </div>
            <button
              type="submit"
              onSubmit={onSubmit}
              className={styles.loginBtn}
            >
              Sign in
            </button>
            <div className={styles.signupBox}>
              <div className={styles.signupText}>Not a member yet?</div>
              <div className={styles.signupBtn} onClick={signupOnClick}>
                Sign up
              </div>
            </div>
          </form>
        </div>
      ) : null}
      {signup && (
        <div className={styles.signupContainer}>
          {showModal && (
            <Modal message={alertMessage} setAlertModal={setShowModal} />
          )}
          <div className={styles.signUpSubTitle}>Create your account</div>
          <form className={styles.signupForm} onSubmit={onSubmit}>
            <div className={styles.emailAndPasswordContainer}>
              <div className={styles.signUpEmailContainer}>
                <div className={styles.signupEmailText}>Email</div>
                <input
                  name="email"
                  type="text"
                  placeholder="Please enter valid e-mail"
                  value={email}
                  onChange={onChange}
                  required
                  className={
                    emailValidation
                      ? styles.signUpEmail
                      : styles.signupEmailNotValid
                  }
                />
              </div>
              <div className={styles.studioNameContainer}>
                <div className={styles.signupStudioNameText}>Studio Name</div>
                <input
                  name="studioName"
                  type="text"
                  placeholder="Studio Name"
                  value={studioName}
                  onChange={onChange}
                  required
                  className={styles.signupStudioName}
                />
              </div>
              <div className={styles.signupPasswordContainer}>
                <div className={styles.signupPasswordText}>Password</div>
                <div className={styles.signupPasswordBox}>
                  <input
                    name="password"
                    type={signupViewPassword ? "text" : "password"}
                    placeholder="Password"
                    value={password}
                    onChange={onChange}
                    className={
                      password.length > 5
                        ? styles.signupPassword
                        : styles.signupPasswordNotValid
                    }
                  />
                  {signupViewPassword ? (
                    <img
                      src={activeEye}
                      alt=""
                      className={styles.signupActiveEye}
                      onClick={() => setSignupViewPassword(false)}
                    />
                  ) : (
                    <img
                      src={inactiveEye}
                      alt=""
                      className={styles.signupInactiveEye}
                      onClick={() => setSignupViewPassword(true)}
                    />
                  )}
                </div>
              </div>

              <div className={styles.signupPasswordContainer}>
                <div className={styles.signupPasswordText}>
                  Password Confirmation
                </div>
                <div className={styles.signupPasswordBox}>
                  <input
                    name="conformPassword"
                    type={signupConfirmViewPassword ? "text" : "password"}
                    placeholder="Password Confirmation"
                    value={conformPassword}
                    onChange={onChange}
                    required
                    className={
                      password !== conformPassword
                        ? styles.signupConfirmPasswordNotValid
                        : styles.signupConfirmPassword
                    }
                  />
                  {signupConfirmViewPassword ? (
                    <img
                      src={activeEye}
                      alt=""
                      className={styles.signupActiveEye}
                      onClick={() => setSignupConfirmViewPassword(false)}
                    />
                  ) : (
                    <img
                      src={inactiveEye}
                      alt=""
                      className={styles.signupInactiveEye}
                      onClick={() => setSignupConfirmViewPassword(true)}
                    />
                  )}
                </div>
              </div>

              <div className={styles.temaSizeContainer}>
                <div className={styles.signupPTeamSizeText}>Team Size</div>
                <div
                  className={styles.signupTeamSize}
                  onClick={() => setViewDropDown(!viewDropDown)}
                >
                  <div className={styles.selectSizeText}>{select.size}</div>
                  <img
                    src={arrow}
                    alt=""
                    className={
                      !viewDropDown ? styles.arrow : styles.rotateArrow
                    }
                  />
                  {viewDropDown && (
                    <ul className={styles.dropDown}>
                      {sizeList.map((i) => (
                        <>
                          <li
                            key={i.id}
                            onClick={() => {
                              setSelect(i);
                              setTeamSize(i.size);
                            }}
                            className={
                              isSelect(i) ? styles.clickList : styles.list
                            }
                          >
                            {i.size}
                          </li>
                        </>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
              <div className={styles.portfolioContainer}>
                <div className={styles.signupPortfolioText}>Portfolio Link</div>
                <input
                  name="portfolio"
                  type="portfolio"
                  placeholder="Portfolio URL"
                  value={portfolio}
                  onChange={onChange}
                  className={styles.signupPortfolio}
                />
              </div>
            </div>
            <div className={styles.termsAndNewsContainer}>
              <div className={styles.newsAgreeBox}>
                <input
                  type="checkbox"
                  id="news"
                  name="news"
                  checked={news}
                  onChange={onChange}
                  className={styles.checkAgree}
                />
                <label htmlFor="news" className={styles.agreeCheckBox}>
                  <span></span>
                </label>
                <div className={styles.agreeText}>
                  <div className={styles.first}>
                    I agree to subscribe to MondayOFF’s Newsletter
                  </div>
                </div>
              </div>
              <div className={styles.termsBox}>
                <input
                  type="checkbox"
                  id="agree"
                  name="agree"
                  checked={agree}
                  onChange={onChange}
                  className={styles.checkAgree}
                />
                <label htmlFor="agree" className={styles.agreeCheckBox}>
                  <span></span>
                </label>
                <div className={styles.agreeText}>
                  I agree to the MondayOFF Dashboard&nbsp;
                  <a
                    className={styles.second}
                    href="https://server.mondayoff.me/terms"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    Terms {"&"} Conditions
                  </a>
                  <span className={styles.star}>*</span>
                </div>
              </div>
            </div>
            <div>
              <button
                type="button"
                onClick={() => setSignup(false)}
                className={styles.backBtn}
              >
                Back
              </button>
              <button
                type="submit"
                onSubmit={onSubmit}
                className={styles.registBtn}
              >
                Register
              </button>
            </div>
          </form>
        </div>
      )}
      {discoverPassword && (
        <div className={styles.resetPasswordContainer}>
          {showModal && (
            <Modal message={alertMessage} setAlertModal={setShowModal} />
          )}
          <div className={styles.textContainer}>
            <img src={lockIcon} alt="" className={styles.lockIcon} />
            <div className={styles.firstText}>Password Reset</div>
            <div className={styles.secondText}>
              <div>Please enter your email and</div>{" "}
              <div>we will send you a temporary password.</div>
            </div>
          </div>

          <div className={styles.resetPasswordEmailContainer}>
            <div className={styles.resetPassowrdEmailText}>Email</div>
            <input
              name="email"
              type="text"
              placeholder="Email"
              value={email}
              onChange={onChange}
              required
              className={
                emailValidation ? styles.resetEmail : styles.resetEmailNotValid
              }
            />
          </div>
          <div className={styles.btnContainer}>
            <button
              className={styles.sendPasswordBtn}
              onClick={sendEmailPassword}
            >
              Send me the password
            </button>
            <div
              className={styles.resetBackBtn}
              onClick={() => setDiscoverPassword(false)}
            >
              Return to Sign in
            </div>
          </div>
        </div>
      )}
    </div>
  );
});

export default React.memo(Login);
