import React, { useEffect, useState } from "react";
import styles from "./Status.module.scss";

import GameTotalData from "../GameTotalData/GameTotalData";
import AdsListAnimation from "../../common/AdsListAnimation/AdsListAmination";
const Status = ({
  adsList,
  adNetworkData,
  setTotalRevenue,
  setTotalSpend,
  totalRevenue,
  tengineData,
  setTotalTengineRevenue,
  setTotalTengineSpend,
  newAdNetworkData,
}) => {
  const [select, setSelect] = useState();
  const [isClick, setIsClick] = useState(false);
  const onClick = (item) => {
    setIsClick(!isClick);
    setSelect(item);
    return () => setSelect();
  };
  const isSelect = (item) => {
    return select?.id === item?.id;
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>Status</div>
      <table className={styles.table}>
        <thead>
          <tr>
            <th className={styles.menu}>Name</th>
            <th className={styles.menu}>Spend</th>
            <th className={styles.menu}>Total Rev</th>
            <th className={styles.menu}>Profit</th>
          </tr>
        </thead>
        {newAdNetworkData?.length === 0 ? (
          <AdsListAnimation />
        ) : (
          adsList.map((data, idx) => {
            return (
              <tbody key={idx}>
                <GameTotalData
                  onClick={onClick}
                  data={data}
                  isSelect={isSelect}
                  select={select}
                  newAdNetworkData={newAdNetworkData?.apps[data.appName]}
                  tengineData={tengineData?.filter(
                    (d) => d.appName === data.appName
                  )}
                  setTotalSpend={setTotalSpend}
                  setTotalRevenue={setTotalRevenue}
                  totalRevenue={totalRevenue}
                  setTotalTengineRevenue={setTotalTengineRevenue}
                  setTotalTengineSpend={setTotalTengineSpend}
                  isClick={isClick}
                />
              </tbody>
            );
          })
        )}
      </table>
    </div>
  );
};

export default Status;
