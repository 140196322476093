import React from "react";

import styles from "./DownSdk.module.css";

const DownSdk = ({ sdkService }) => {
  let appId = localStorage.getItem("app_Id");

  const downloadClick = () => {
    return sdkService.downSDK().then((res) => res);
  };
  return (
    <div className={styles.container}>
      <div className={styles.downSdkText}>
        Download the SDK file and follow the instructions to set it up.
      </div>
      <div className={styles.downSdkBtn} onClick={downloadClick}>
        Download SDK
      </div>
      <div className={styles.linkText}>
        <span>After Attaching the SDK to the Unity File,</span>
        <span>
          Click{" "}
          <a
            href={`https://developers.facebook.com/apps/${appId}/settings/basic/`}
            target="_blank"
            rel="noreferrer"
          >
            Here
          </a>{" "}
          to Complete the Platform Settings
        </span>
      </div>
    </div>
  );
};

export default DownSdk;
