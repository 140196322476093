import React from "react";
import styles from "./PubSummary.module.css";
const PubSummary = ({ newAdNetworkData }) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>Summary</div>
      <div className={styles.dataContainer}>
        <div className={styles.dataBox}>
          <div>Spend</div>
          <div className={styles.spend}>
            {newAdNetworkData?.totalSpend?.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
          </div>
        </div>
        <div className={styles.dataBox}>
          <div>Revenue</div>
          <div className={styles.revenue}>
            {newAdNetworkData?.totalRevenue?.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
          </div>
        </div>
        <div className={styles.dataBox}>
          <div>Profit</div>
          <div className={styles.profit}>
            {newAdNetworkData?.totalProfit?.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PubSummary;
