import React from "react";
import styles from "./FirstDocModal.module.css";
import firstImg from "../../../../img/firstDocModal/first.png";
import secondImg from "../../../../img/firstDocModal/second.png";
import thirdImg from "../../../../img/firstDocModal/third.png";
import fourthImg from "../../../../img/firstDocModal/fourth.png";
const FirstDocModal = ({ setShowFirstModal }) => {
  const onClick = () => {
    setShowFirstModal(false);
  };
  return (
    <div className={styles.container}>
      <div className={styles.modalContainer}>
        <div className={styles.bunisessTitle}>
          <div className={styles.bunisessTitleText}>
            How to Create a Business Manager Account
          </div>
        </div>
        <div className={styles.scroll}>
          <div className={styles.firstDocContainer}>
            <div className={styles.firstDocContext}>
              <div className={styles.one}>
                How to Create a Business Manager Account
              </div>
              <div className={styles.two}>
                <div>1. Move to the Facebook Business page and Login</div>
                <a
                  className={styles.businessLink}
                  href="https://business.facebook.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  business.facebook.com
                </a>
              </div>
              <div className={styles.three}>
                <div>
                  2. Click the “Create Account button” in the upper right
                  corner.
                </div>
                <img src={firstImg} alt="" className={styles.firstImg} />
              </div>
              <div className={styles.four}>
                <div>3. Fill in the information and click on submit.</div>
                <img src={secondImg} alt="" className={styles.secondImg} />
              </div>
              <div className={styles.five}>
                <div>
                  4. Confirm your email and click the <span>“Done”</span>{" "}
                  button.
                </div>
                <img src={thirdImg} alt="" className={styles.thirdImg} />
              </div>
              <div className={styles.six}>
                <div>5. Nothing else! Your account has been created.</div>
                <img src={fourthImg} alt="" className={styles.fourthImg} />
              </div>

              <button className={styles.agreeBtn} onClick={onClick}>
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(FirstDocModal);
