import React, { useEffect, useState } from "react";
import styles from "./MaxData.module.scss";
import icon from "../../img/alertModal/icon.png";
import totalData from "../../service/maxData";
const MaxData = ({ setD1Retention, retData }) => {
  const [totalInstall, setTotalInstall] = useState("");
  const [totalD1, setTotalD1] = useState("");
  const [totalD2, setTotalD2] = useState("");
  const [totalD3, setTotalD3] = useState("");
  const [totalD4, setTotalD4] = useState("");
  const [totalD5, setTotalD5] = useState("");
  const [totalD6, setTotalD6] = useState("");
  useEffect(() => {
    const dataColor = document.getElementsByClassName(styles.data);
    for (let value of dataColor) {
      if (Number(value.innerText.split("%")[0]) === 0) {
        value.innerText = "-";
        value.style.background = "#ffff";
        value.style.color = "#DB4848";
      } else if (
        Number(value.innerText.split("%")[0]) === 0 ||
        Number(value.innerText.split("%")[0]) < 10
      ) {
        value.style.background = "#FFF2F2";
        value.style.color = "#E85D5D";
      } else if (
        Number(value.innerText.split("%")[0]) === 10 ||
        Number(value.innerText.split("%")[0]) < 20
      ) {
        value.style.background = "#FFE1E1";
        value.style.color = "#E85D5D";
      } else if (
        Number(value.innerText.split("%")[0]) === 20 ||
        Number(value.innerText.split("%")[0]) < 30
      ) {
        value.style.background = "#FFD1D1";
        value.style.color = "#E85D5D";
      } else if (
        Number(value.innerText.split("%")[0]) === 30 ||
        Number(value.innerText.split("%")[0]) < 40
      ) {
        value.style.backgroundColor = "#FFB2B2";
        value.style.color = "#E85D5D";
      } else if (
        Number(value.innerText.split("%")[0] === 40) ||
        Number(value.innerText.split("%")[0]) < 50
      ) {
        value.style.background = " #FC8F8F";
        value.style.color = "#FFFFFF";
      } else if (
        Number(value.innerText.split("%")[0] === 50) ||
        Number(value.innerText.split("%")[0]) < 60
      ) {
        value.style.background = "#F57676";
        value.style.color = "#FFFFFF";
      } else if (Number(value.innerText.split("%")[0]) > 59) {
        value.style.background = "#E85D5D";
        value.style.color = "#FFFFFF";
      }
    }
  });
  useEffect(() => {
    let install = [];
    retData?.map((data) => {
      return install?.push(Number(data?.installs));
    });
    if (install?.length > 0) {
      setTotalInstall(() => install?.reduce((a, b) => a + b));
    } else if (install?.length === 0) {
      setTotalInstall("-");
    }
  });
  //D1
  useEffect(() => {
    let retention = [];
    retData?.map((data) => {
      return retention?.push(
        Number(data?.installs) * Number(data?.retention_1)
      );
    });
    if (retention?.length === 0) {
      setTotalD1("-");
      setD1Retention(0);
    } else if (retention?.length > 0) {
      setTotalD1(() => totalData(retData, retention, 1));
      setD1Retention(totalD1);
    }
  }, [retData, setD1Retention, totalD1]);
  //D2
  useEffect(() => {
    let retention = [];

    retData?.map((data) => {
      return retention?.push(
        Number(data?.installs) * Number(data?.retention_2)
      );
    });

    if (retention?.length === 0) {
      setTotalD2("-");
    } else if (retention?.length > 0) {
      setTotalD2(() => totalData(retData, retention, 2));
    }
  }, [retData]);
  //D3
  useEffect(() => {
    let retention = [];
    retData?.map((data) => {
      return retention?.push(
        Number(data?.installs) * Number(data?.retention_3)
      );
    });
    if (retention?.length === 0) {
      setTotalD3("-");
    } else if (retention?.length > 0) {
      setTotalD3(() => totalData(retData, retention, 3));
    }
  }, [retData]);
  //D4
  useEffect(() => {
    let retention = [];
    retData?.map((data) => {
      return retention?.push(
        Number(data?.installs) * Number(data?.retention_4)
      );
    });
    if (retention?.length === 0) {
      setTotalD4("-");
    } else if (retention?.length > 0) {
      setTotalD4(() => totalData(retData, retention, 4));
    }
  }, [retData]);
  //D5
  useEffect(() => {
    let retention = [];
    retData?.map((data) => {
      return retention?.push(
        Number(data?.installs) * Number(data?.retention_5)
      );
    });
    if (retention?.length === 0) {
      setTotalD5("-");
    } else if (retention?.length > 0) {
      setTotalD5(() => totalData(retData, retention, 5));
    }
  });
  //D6
  useEffect(() => {
    let retention = [];
    retData?.map((data) => {
      return retention?.push(
        Number(data?.installs) * Number(data?.retention_6)
      );
    });
    if (retention?.length === 0) {
      setTotalD6("-");
    } else if (retention?.length > 0) {
      setTotalD6(() => totalData(retData, retention, 6));
    }
  }, [retData]);

  return (
    <div className={styles.container}>
      <div className={styles.title}>Ret. Cohort</div>
      <div className={styles.dataHeader}>
        <div></div>
        <div>Installs</div>
        <div>Day 0</div>
        <div>Day 1</div>
        <div>Day 2</div>
        <div>Day 3</div>
        <div>Day 4</div>
        <div>Day 5</div>
        <div>Day 6</div>
      </div>
      <div className={styles.maxDataTable}>
        {retData?.length === 0 ? (
          <div className={styles.retentionMessage}>
            <img src={icon} alt="" />
            <div>
              It may take a while to receive gaming data from the server. Please
              wait.
            </div>
          </div>
        ) : (
          <>
            {retData?.map((d, idx) => (
              <>
                <div className={styles.dataBox} key={idx}>
                  <div key={Math.random()}>{d?.day}</div>
                  <div key={Math.random()}>{Math.floor(d?.installs)}</div>

                  <div className={styles.data} key={Math.random()}>
                    {(d?.retention_0 * 100).toFixed(1)}
                    {"%"}
                  </div>
                  <div className={styles.data} key={Math.random()}>
                    {(d?.retention_1 * 100).toFixed(1)}
                    {"%"}
                  </div>
                  <div className={styles.data} key={Math.random()}>
                    {(d?.retention_2 * 100).toFixed(1)}
                    {"%"}
                  </div>
                  <div className={styles.data} key={Math.random()}>
                    {(d?.retention_3 * 100).toFixed(1)}
                    {"%"}
                  </div>
                  <div className={styles.data} key={Math.random()}>
                    {(d?.retention_4 * 100).toFixed(1)}
                    {"%"}
                  </div>
                  <div className={styles.data} key={Math.random()}>
                    {(d?.retention_5 * 100).toFixed(1)}
                    {"%"}
                  </div>
                  <div className={styles.data} key={Math.random()}>
                    {(d?.retention_6 * 100).toFixed(1)}
                    {"%"}
                  </div>
                </div>
              </>
            ))}
            <div className={styles.totalData}>
              <div>Average</div>
              <div>{totalInstall}</div>
              <div className={styles.data}>100%</div>
              <div className={styles.data}>{totalD1}</div>
              <div className={styles.data}>{totalD2}</div>
              <div className={styles.data}>{totalD3}</div>
              <div className={styles.data}>{totalD4}</div>
              <div className={styles.data}>{totalD5}</div>
              <div className={styles.data}>{totalD6}</div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default MaxData;
