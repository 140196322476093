import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import lockedImg from "../../img/Invoice/locked.png";
import styles from "./Invoice.module.scss";
import InvoiceCalander from "../../components/InvoiceCalander/InvoiceCalander";
import InvoiceData from "../../components/InvoiceData/InvoiceData";
import { useInvoice } from "../../Context/InvoiceContext";
import { deleteCookie, getCookie } from "../../db/cookies";
import { useAuth } from "../../Context/AuthContext";
import { monthCheck } from "../../db/monthCheck";

export default function Invoice() {
  const [isRecentClick, setIsRecentClick] = useState(false);
  const [isAllTimeClick, setIsAllTimeClick] = useState(false);
  const [isCalanderClick, setIsCalanderClick] = useState(false);
  const [yearCount, setYearCount] = useState(0);
  const [year, setYear] = useState(moment().year());
  const [month, setMonth] = useState(moment().format("MMM"));
  const [prevDate, setPrevDate] = useState();
  const { getInvoiceList, setInvoiceList, studioInvoiceInfo } = useInvoice();
  const { invoiceUser } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [isPayment, setIsPayment] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: "",
    endDate: "",
  });
  const [dateSelects, setDateSelects] = useState({
    startDate: false,
    endDate: false,
  });
  const monthList = moment.monthsShort();
  useEffect(() => {
    setYear((prevYear) => prevYear - yearCount);
  }, [yearCount]);
  useEffect(() => {
    studioInvoiceInfo(invoiceUser.studioId).then((res) => {
      if (res.status === 401) {
        deleteCookie("Authentication");
        if (!getCookie("Authentication")) {
          window.location.reload();
        }
      } else {
        setIsPayment(res.data.isPayment);
      }
    });
  }, [invoiceUser, studioInvoiceInfo]);
  useEffect(() => setIsRecentClick(true), []);

  const dateRangeView = useCallback((range) => {
    const { startDate, endDate } = range;
    if (moment(startDate).isSame(endDate, "year")) {
      return moment(startDate).isBefore(endDate)
        ? `${moment(startDate).format("MMM")} - ${moment(endDate).format(
            "MMM, YYYY"
          )}`
        : `${moment(endDate).format("MMM")} - ${moment(startDate).format(
            "MMM, YYYY"
          )}`;
    } else {
      return moment(startDate).isBefore(endDate)
        ? `${moment(startDate).format("MMM, YYYY")} - ${moment(endDate).format(
            "MMM, YYYY"
          )}`
        : `${moment(endDate).format("MMM, YYYY")} - ${moment(startDate).format(
            "MMM, YYYY"
          )}`;
    }
  }, []);

  const onRecentClick = () => {
    setIsRecentClick(true);
    setIsAllTimeClick(false);
    setIsLoading(true);
    setDateRange(() => ({
      startDate: moment(moment(`${year}-${month}`).format("YYYY-MM"))
        .subtract(7, "month")
        .format("YYYY-MM"),
      endDate: moment(`${year}-${month}`).format("YYYY-MM"),
    }));
    getInvoiceList(
      moment(moment(`${year}-${month}`).format("YYYY-MM"))
        .subtract(7, "month")
        .format("YYYY-MM"),
      moment(`${year}-${month}`).format("YYYY-MM")
    ).then((data) => {
      if (data?.status === 401) {
        deleteCookie("Authentication");
        window.location.reload();
      } else if (data?.status === 400) {
        setIsLoading(false);
        setInvoiceList([]);
      } else {
        setIsLoading(false);
        setInvoiceList(data?.data?.list);
      }
    });
  };

  const onAllTimeClick = () => {
    setIsRecentClick(false);
    setIsAllTimeClick(true);
    setIsLoading(true);
    setDateRange(() => ({
      startDate: "2022-10",
      endDate: moment(`${year}-${month}`).format("YYYY-MM"),
    }));
    getInvoiceList(
      "2022-10",
      moment(`${year}-${month}`).format("YYYY-MM")
    ).then((data) => {
      if (data?.status === 401) {
        deleteCookie("Authentication");
        window.location.reload();
      } else if (data?.status === 400) {
        setIsLoading(false);
        setInvoiceList([]);
      } else {
        setIsLoading(false);
        setInvoiceList(data?.data?.list);
      }
    });
  };

  const onCustomClick = () => {
    setIsLoading(true);
    setIsCalanderClick(false);
    setDateSelects({ startDate: false, endDate: false });

    setIsCalanderClick(false);
    let startDate = moment(dateRange.startDate).isBefore(dateRange.endDate)
      ? dateRange.startDate
      : dateRange.endDate;
    let endDate = moment(dateRange.endDate).isAfter(dateRange.startDate)
      ? dateRange.endDate
      : dateRange.startDate;
    getInvoiceList(startDate, endDate).then((data) => {
      if (data?.status === 401) {
        deleteCookie("Authentication");
        window.location.reload();
      } else if (data?.status === 400) {
        setIsLoading(false);
        setInvoiceList([]);
      } else {
        setIsLoading(false);
        setInvoiceList(data?.data?.list);
      }
    });
  };
  useEffect(() => {
    if (isPayment) {
      let checkMonth = monthCheck.find((mon) => mon[month]);

      setIsLoading(true);
      setIsRecentClick(true);
      setIsAllTimeClick(false);

      setDateRange(() => ({
        startDate: moment(`${year}-${checkMonth[month]}`)
          .subtract(7, "month")
          .format("YYYY-MM"),
        endDate: moment(`${year}-${checkMonth[month]}`).format("YYYY-MM"),
      }));
      getInvoiceList(
        moment(`${year}-${checkMonth[month]}`)
          .subtract(7, "month")
          .format("YYYY-MM"),
        moment(`${year}-${checkMonth[month]}`).format("YYYY-MM")
      ).then((data) => {
        if (data?.status === 401) {
          deleteCookie("Authentication");
          if (!getCookie("Authentication")) {
            window.location.reload();
          }
        } else if (data?.status === 400) {
          setIsLoading(false);
          setInvoiceList([]);
        } else {
          setIsLoading(false);
          setInvoiceList(data?.data?.list);
        }
      });
    }
  }, [isPayment]);
  return (
    <section className={styles.container}>
      {isPayment ? (
        <>
          <div className={styles.top_section}>
            <h2>Summary</h2>
            <span className={styles.select_calander}>
              <span className={styles.show_month}>
                <p>show</p>
                <p>{dateRangeView(dateRange)}</p>
              </span>
              <span className={styles.buttons}>
                <button
                  onClick={onRecentClick}
                  data-content={isRecentClick && "active"}
                >
                  Most Recent
                </button>
                <button
                  onClick={onAllTimeClick}
                  data-content={isAllTimeClick && "active"}
                >
                  All Time
                </button>
                <button
                  onClick={() => {
                    setIsRecentClick(false);
                    setIsAllTimeClick(false);
                    setIsCalanderClick(true);
                  }}
                  data-content={isCalanderClick && "active"}
                >
                  Custom
                </button>
              </span>

              {isCalanderClick && (
                <InvoiceCalander
                  setIsCalanderClick={setIsCalanderClick}
                  setYearCount={setYearCount}
                  monthList={monthList}
                  setYear={setYear}
                  setMonth={setMonth}
                  month={month}
                  year={year}
                  prevDate={prevDate}
                  setPrevDate={setPrevDate}
                  dateRange={dateRange}
                  setIsAllTimeClick={setIsAllTimeClick}
                  setDateRange={setDateRange}
                  dateSelects={dateSelects}
                  setDateSelects={setDateSelects}
                  onCustomClick={onCustomClick}
                />
              )}
            </span>
          </div>
          <InvoiceData isLoading={isLoading} />
        </>
      ) : (
        <div className={styles.empty_payment}>
          <h2>Want to unlock this page?</h2>
          <img src={lockedImg} alt="img" />
          <p>
            Please fill in the studio and payment information in your profile.
          </p>
          <Link to="/profile" state={{ id: 2 }}>
            Go to profile
          </Link>
        </div>
      )}
    </section>
  );
}
