import React from "react";
import styles from "./Home.module.scss";
import mainVideo from "../../mainVideo/Dashboard_0412_main.mp4";
import ReactGA from "react-ga4";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import getGA from "../../service/ga";
const Home = (props) => {
  const location = useLocation();
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_ID);
    ReactGA.send({
      hitType: "pageview",
      path: location.pathname,
      location: location.pathname,
      title: "Home",
    });
    ReactGA.gtag("config", process.env.REACT_APP_GA_ID, { debug_mode: true });
    getGA();
  }, [location.pathname]);
  return (
    <div className={styles.container}>
      <div className={styles.title}>MondayOFF Dashboard</div>
      <div className={styles.subTitle}>
        The fastest Dashboard in the Industry
      </div>
      <div className={styles.explain}>
        Get your game tested in just 3 simple steps.
      </div>

      <video
        className={styles.videoArea}
        autoPlay
        muted
        loop
        webkit-playsinline="true"
        playsInline
      >
        <source src={mainVideo} type="video/mp4" />
      </video>
    </div>
  );
};

export default Home;
