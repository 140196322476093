export default class MaxService {
  constructor(http, tokenStorage) {
    this.http = http;
    this.tokenStorage = tokenStorage;
  }
  async getData(appId, itr) {
    const token = this.tokenStorage.getToken();
    itr = itr.replace(/#/g, "%23");

    return await this.http
      .axios(`/max/${appId}&${itr}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => res.data)
      .catch(console.error);
  }
  async getMaxData(appId) {
    return await this.http
      .axios(`/max/${appId}`, {
        method: "GET",
      })
      .then((res) => res.data)
      .catch(console.error);
  }
  async getPubData(appId, createdAt, endTime, platform) {
    return await this.http
      .axios(
        `/max/pub?appId=${appId}&createdAt=${createdAt}&endTime=${endTime}&platform=${platform}`,
        {
          method: "GET",
        }
      )
      .then((res) => res.data)
      .catch(console.error);
  }
  async getPubDAUData(appId, createdAt, endTime, platform) {
    return await this.http
      .axios(
        `/max/dau?appId=${appId}&createdAt=${createdAt}&endTime=${endTime}&platform=${platform}`,
        {
          method: "GET",
        }
      )
      .then((res) => res.data)
      .catch(console.error);
  }
}
