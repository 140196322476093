import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import getGA from "../../../service/ga";
import ReactGA from "react-ga4";
import SettingBoxs from "../../../components/BasicSetting/SettingBoxs/SettingBoxs";
import styles from "./BasicSetting.module.css";
import AppIdBox from "../../../components/AppId/AppIdBox/AppIdBox";
import questionMark from "../../../img/addNewApp/questionMark.png";
import whiteMark from "../../../img/settingBoxs/whiteMark.png";
const BasicSetting = ({ metaService, nextManagerSetup, adsService }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [fourthCheck, setFourthCheck] = useState(false);
  const [appId, setAppId] = useState("");
  const [checkAppId, setCheckAppId] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [tutorialPoint, setTutorialPoint] = useState(false);
  const [videoGuidePoint, setVideoGuidePoint] = useState(false);
  const [lastCheck, setLastCheck] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [endTime, setEndTime] = useState("");
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_ID);
    ReactGA.send({
      hitType: "pageview",
      path: location.pathname,
      location: location.pathname,
      title: "Second",
    });
    getGA();
  }, [location.pathname]);
  const onClick = (e) => {
    e.preventDefault();
    if (localStorage.getItem("fourthCheck") === null) {
      setAlertModal(true);
      setAlertMessage("Please complete Basic Settings");
      setTimeout(() => {
        setAlertModal(false);
      }, 2000);
    } else if (!checkAppId) {
      setAlertModal(true);
      setAlertMessage("Please check your AppId");
      setTimeout(() => {
        setAlertModal(false);
      }, 2000);
    } else if (isDuplicate) {
      setAlertModal(true);
    } else if (checkAppId || !isDuplicate) {
      navigate("/mytest/newapp/advenced");
    }
  };

  const onTutorialPointClick = () => {
    setTutorialPoint(true);
  };
  const onVideoGuidePointClick = () => {
    setVideoGuidePoint(true);
  };

  return (
    <>
      <div className={styles.box}>
        {!tutorialPoint && (
          <div className={styles.tutorialPoint}>
            <div className={styles.tutorialTitle}>Need help?</div>
            <div className={styles.tutorialText}>
              Check out our guides by clicking on the green question marks!
            </div>
            <button
              className={styles.tutorialBtn}
              onClick={onTutorialPointClick}
            >
              I got it
            </button>
          </div>
        )}
        <img
          src={questionMark}
          alt=""
          className={styles.questionMark}
          onClick={onVideoGuidePointClick}
        />

        <div className={styles.container}>
          <div className={styles.progress}>
            <div className={styles.firstCircle}>v</div>
            <div className={styles.line}></div>
            <div className={styles.secondCircle}>2</div>
            <div className={styles.line}></div>
            <div className={styles.thirdCircle}>3</div>
          </div>
          <div className={styles.title}>Register Your App</div>
          <div className={styles.settingArea}>
            <SettingBoxs
              setLastCheck={setLastCheck}
              fourthCheck={fourthCheck}
              setFourthCheck={setFourthCheck}
              tutorialPoint={tutorialPoint}
              videoGuidePoint={videoGuidePoint}
              setVideoGuidePoint={setVideoGuidePoint}
              onVideoGuidePointClick={onVideoGuidePointClick}
            />
            <AppIdBox
              checkAppId={checkAppId}
              setCheckAppId={setCheckAppId}
              appId={appId}
              setAppId={setAppId}
              metaService={metaService}
              nextManagerSetup={nextManagerSetup}
              adsService={adsService}
              isDuplicate={isDuplicate}
              setIsDuplicate={setIsDuplicate}
              setEndTime={setEndTime}
              setAlertMessage={setAlertMessage}
            />
            <div className={styles.btnContainer}>
              {alertModal && (
                <div className={styles.messageContainer}>
                  <img src={whiteMark} alt="" className={styles.whiteMark} />
                  <div className={styles.message}>{alertMessage}</div>
                </div>
              )}
              <button className={styles.nextBtn} onClick={onClick}>
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BasicSetting;
//"/mytest/newapp/sdk"
