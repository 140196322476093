import React from "react";
import styles from "./InvoiceStudioInfo.module.scss";
export default function InvoiceStudioInfo({
  studioInfo,
  onInfoChange,
  invoiceUser,
  isPayment,
}) {
  return (
    <section className={styles.container}>
      <div>
        <span>
          <p>Payment To</p>
          <p>*</p>
        </span>
        <input
          type="text"
          id="paymentTo"
          placeholder="Payment To"
          value={studioInfo.studio.paymentTo}
          onChange={onInfoChange}
          disabled={invoiceUser.role === "EMPLOYEE" || isPayment ? true : false}
        />
      </div>
      <div>
        <span>
          <p>Phone Number</p>
          <p>*</p>
        </span>
        <input
          type="text"
          id="phoneNumber"
          placeholder="Phone Number"
          value={studioInfo.studio.phoneNumber}
          onChange={onInfoChange}
          disabled={invoiceUser.role === "EMPLOYEE" || isPayment ? true : false}
        />
      </div>
      <div>
        <span>
          <p>email</p>
          <p>*</p>
        </span>
        <input
          type="text"
          id="email"
          placeholder="email"
          value={studioInfo.studio.email}
          onChange={onInfoChange}
          disabled={invoiceUser.role === "EMPLOYEE" || isPayment ? true : false}
        />
      </div>
      <div>
        <span>
          <p>Business Address</p>
          <p>*</p>
        </span>
        <input
          type="text"
          id="businessAddress"
          placeholder="Business Address"
          value={studioInfo.studio.businessAddress}
          onChange={onInfoChange}
          disabled={invoiceUser.role === "EMPLOYEE" || isPayment ? true : false}
        />
      </div>
      <div>
        <span>
          <p>VAT/GST</p>
        </span>
        <input
          type="text"
          id="vatOrGst"
          placeholder="VAT/GST"
          value={studioInfo.studio.vatOrGst}
          onChange={onInfoChange}
          disabled={invoiceUser.role === "EMPLOYEE" || isPayment ? true : false}
        />
      </div>
    </section>
  );
}
