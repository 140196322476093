import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../Context/AuthContext";
import styles from "./InvoiceLogin.module.scss";
export default function InvoiceLogin({
  path,
  setIsProfile,
  type,
  setSelectedMenu,
}) {
  const { invoiceLogin } = useAuth();
  const navigate = useNavigate();
  const [loginInfo, setLoginInfo] = useState({
    email: "",
    password: "",
  });
  const onChange = (e) => {
    const { id, value } = e.target;
    switch (id) {
      case "email":
        setLoginInfo({ ...loginInfo, email: value });
        return;
      case "password":
        setLoginInfo({ ...loginInfo, password: value });
        return;
      default:
        return;
    }
  };

  const onLoginClick = () => {
    invoiceLogin(loginInfo.email, loginInfo.password).then(() => {
      window.location.pathname = `/${path}`;
    });
  };
  const onKeyPress = (e) => {
    if (e.keyCode === 13) {
      invoiceLogin(loginInfo.email, loginInfo.password);
    }
  };

  return (
    <section className={styles.container}>
      <div className={styles.contents}>
        <h2>Sign In</h2>
        <section className={styles.email}>
          <span>
            <p>Email</p>
            <p>*</p>
          </span>
          <input type="text" id="email" onChange={onChange} />
        </section>
        <section className={styles.password}>
          <span>
            <p>Password</p>
            <p>*</p>
          </span>
          <input
            type="password"
            id="password"
            onChange={onChange}
            onKeyDown={onKeyPress}
          />
        </section>
        <section className={styles.buttons}>
          <div onClick={onLoginClick}>
            <p>Sign in</p>
          </div>
          <div
            onClick={() => {
              if (path === "profile" && !type) {
                setIsProfile(false);
              } else if (path === "profile" && type === "menuSelect") {
                setSelectedMenu({ id: 1 });
                navigate("/profile");
              } else {
                navigate("/");
              }
            }}
          >
            <p>Cancel</p>
          </div>
        </section>
      </div>
    </section>
  );
}
