import React, { useEffect, useState } from "react";
import styles from "./GameTotalData.module.css";
import { ReactComponent as Arrow } from "../../img/publishingDetail/arrow.svg";
import classNames from "classnames";
import AosAdnetwork from "../../common/AosAdnetwork/AosAdnetwork";
import aosIcon from "../../img/publishingDetail/aosIcon.png";
import appleIcon from "../../img/publishingDetail/appleIcon.png";
import IosAdnetwork from "../../common/IosAdnetwork/IosAdnetwork";
import { Fragment } from "react";
const GameTotalData = ({
  onClick,
  data,
  isSelect,
  newAdNetworkData,
  isClick,
}) => {
  const [viewIosData, setViewIosData] = useState(false);
  const [viewAosData, setViewAosData] = useState(false);

  const onIosClick = () => {
    setViewIosData(!viewIosData);
    return () => setViewIosData(false);
  };
  const onAosClick = () => {
    setViewAosData(!viewAosData);
    return () => setViewAosData(false);
  };

  return (
    <Fragment>
      <tr className={styles.totalDataView} onClick={() => onClick(data)}>
        <td className={styles.data}>
          <Arrow
            className={
              isSelect(data) && isClick ? styles.rotateArrow : styles.arrow
            }
          />

          <img src={data.gameIcon} alt="" className={styles.gameIcon} />

          <div>{data?.appName}</div>
        </td>
        <td className={styles.data}>
          {newAdNetworkData?.totalSpend?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </td>
        <td className={styles.data}>
          {newAdNetworkData?.totalRevenue?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </td>
        <td className={styles.data}>
          {newAdNetworkData?.totalProfit?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </td>
      </tr>
      {isSelect(data) && isClick && (
        <>
          <tr onClick={onIosClick}>
            <td className={classNames(styles.data, styles.platformSection)}>
              <Arrow
                className={!viewIosData ? styles.arrow : styles.rotateArrow}
              />
              <img src={appleIcon} alt="" className={styles.platform} />
              <div className={styles.platformName}>iOS</div>
            </td>
            <td className={styles.data}>
              {newAdNetworkData.IOS.spend?.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </td>
            <td className={styles.data}>
              {newAdNetworkData.IOS.revenue?.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </td>
            <td className={styles.data}>
              {newAdNetworkData?.IOS.profit?.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </td>
          </tr>
          {viewIosData && <IosAdnetwork iosData={newAdNetworkData.IOS} />}

          <tr onClick={onAosClick}>
            <td className={classNames(styles.data, styles.platformSection)}>
              <Arrow
                className={!viewAosData ? styles.arrow : styles.rotateArrow}
              />
              <img src={aosIcon} alt="" className={styles.platform} />
              <div className={styles.platformName}>Android</div>
            </td>
            <td className={styles.data}>
              {newAdNetworkData?.ANDROID?.spend?.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </td>
            <td className={styles.data}>
              {newAdNetworkData?.ANDROID?.revenue?.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </td>
            <td className={styles.data}>
              {newAdNetworkData?.ANDROID?.profit?.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </td>
          </tr>
          {viewAosData && (
            <AosAdnetwork androidData={newAdNetworkData.ANDROID} />
          )}
        </>
      )}
    </Fragment>
  );
};

export default GameTotalData;
