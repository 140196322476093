import React from "react";
import { useInvoice } from "../../Context/InvoiceContext";
import { ReactComponent as QmarkIcon } from "../../img/FinaceOverview/Qmark.svg";
import emptyBox from "../../img/Invoice/emptybox.png";
import InvoiceList from "../InvoiceList/InvoiceList";
import SkeletonUi from "../SkeletonUi/SkeletonUi";
import styles from "./InvoiceData.module.scss";
export default function InvoiceData({ isLoading }) {
  const { invoiceList } = useInvoice();

  return (
    <section className={styles.container}>
      <h2>Monthly List</h2>
      <section className={styles.data_list}>
        <ul className={styles.data_list_header}>
          <li>
            <p>Issue Date</p>
          </li>
          <li>
            <p>
              Monthly
              <br /> Gross Revenue
            </p>
          </li>
          <li>
            <p>Monthly Spend</p>
          </li>
          <li>
            <p className={styles.tool_costs}>
              <p>Tool costs</p>
              <QmarkIcon />
              <p className={styles.tool_costs_info}>
                Tool costs are 5% of monthly gross revenue. Refer to Pub.
                Contract, sect. 1.1(z).
              </p>
            </p>
          </li>
          <li>
            <p>Monthly Net Revenue</p>
          </li>
          <li>
            <p>Revenue Share</p>
          </li>
          <li>
            <p>Bonus</p>
          </li>
          <li>
            <p>Payment Status</p>
          </li>
        </ul>
        {!isLoading ? (
          <ul className={styles.data_list_items}>
            {invoiceList.length ? (
              invoiceList
                .sort((a, b) => new Date(b.issueDate) - new Date(a.issueDate))
                .map((item) => <InvoiceList item={item} />)
            ) : (
              <div className={styles.empty_list}>
                <img src={emptyBox} alt="img" />
                <p>No invoice information has been found.</p>
              </div>
            )}
          </ul>
        ) : (
          <SkeletonUi />
        )}
      </section>
    </section>
  );
}
