import React from "react";
import styles from "./Filter.module.scss";
import glass from "../../img/search/glassIcon.png";

const Filter = ({ setSearchValue }) => {
  const onChange = (e) => {
    setSearchValue(e.target.value);
  };
  return (
    <div className={styles.container}>
      <div className={styles.iconBlock}>
        <img src={glass} alt="glass" />
      </div>
      <div className={styles.inputBlock}>
        <input
          className={styles.searchBox}
          placeholder="search"
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default React.memo(Filter);
