import React, { useEffect } from "react";
import Dropzone from "react-dropzone";
import styles from "./ImgBox.module.css";
import plus from "../../../img/plus/gameIconPlus.png";
const ImgBox = ({ gameIcon, setGameIcon }) => {
  useEffect(() => {
    actionImgCompress(gameIcon).then(() =>
      localStorage.setItem("gameIcon", gameIcon)
    );
  }, [gameIcon]);
  useEffect(() => {
    if (localStorage.getItem("gameIcon")) {
      setGameIcon(localStorage.getItem("gameIcon"));
    } else {
      setGameIcon("");
    }
  }, []);
  const actionImgCompress = async (fileSrc) => {
    try {
      const blob = URL.createObjectURL(fileSrc[0]);
      setGameIcon(blob);
    } catch (err) {
      return;
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.text}>Add your game icon.</div>
      <Dropzone
        accept="image/*"
        onDrop={(acceptedFiles) => {
          setGameIcon(acceptedFiles);
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <section className={styles.dragAndDropArea}>
            <div {...getRootProps()} className={styles.dragAndDropBox}>
              <input {...getInputProps()} />
              {gameIcon && (
                <img src={gameIcon} alt="" className={styles.gameIcon} />
              )}

              {!gameIcon && (
                <>
                  <img src={plus} alt="plus" className={styles.plus} />
                  <div className={styles.first}>Add file </div>
                  <div className={styles.second}>or</div>
                  <div className={styles.third}>drop file here</div>
                </>
              )}
            </div>
          </section>
        )}
      </Dropzone>
    </div>
  );
};

export default ImgBox;
