import React, { useEffect, useState } from "react";
import classNames from "classnames";
import styles from "./AdsData.module.scss";
import playIcon from "../../img/adsData/videoPlay.png";
import dropDownIcon from "../../img/adsData/drop_down.png";
import DataAnimation from "../../common/DataAnimation/DataAnimation";
import boxIcon from "../../img/adsData/emptybox.png";
import SkelSkeletonCampaignetonUi from "../../common/SkeletonCampaign/SkeletonCampaign";
const AdsData = ({
  adsData,
  adsetData,
  metaService,
  setITR_Tap,
  ITR_Tap,
  setAverageCpi,
  ITR,
  isLimitError,
}) => {
  const [videoList, setVideoList] = useState([]);
  const [videoSource, setVideoSource] = useState();
  const [showDropDown, setShowDropDown] = useState(false);
  const [status, setStatus] = useState([]);

  useEffect(() => {
    if (adsData) {
      let videoId = adsData?.map((data) => data?.creative?.video_id);
      if (videoId.length > 0) {
        metaService.getAdVideo(videoId).then((data) => setVideoList(data));
      }
    }
  }, [adsData]);
  useEffect(() => {
    let list = [];
    adsData?.map((d) => {
      switch (d?.effective_status) {
        case "CAMPAIGN_PAUSED":
          return list.push({ name: d?.name, status: "Paused" });
        case "PENDING_REVIEW":
          return list.push({ name: d?.name, status: "Review" });
        case "IN_PROCESS":
          return list.push({ name: d?.name, status: "Active" });
        case "ACTIVE":
          return list.push({ name: d?.name, status: "Active" });
        default:
          return list.push({ name: d?.name, status: d?.effective_status });
      }
    });
    setStatus(list);
  }, [adsData]);

  const onClick = (id) => {
    let video = videoList?.find((video) => id === video.id);
    setVideoSource(video);
  };
  const isSelect = (itr) => {
    return ITR_Tap === itr;
  };
  const onDropDownClick = () => {
    setShowDropDown(!showDropDown);
  };
  const onItrSelect = (e) => {
    setShowDropDown(false);
    return setITR_Tap(e.target.innerText);
  };

  return (
    <div className={styles.container}>
      {isLimitError ? (
        <div className={styles.error}>
          <img src={boxIcon} alt="" />
          <p>Meta Limit Error</p>
        </div>
      ) : (
        <>
          <div className={styles.tapContainer}>
            <div className={styles.dataTitle}>Facebook Campaign</div>
            <div className={styles.itrDropBox} onClick={onDropDownClick}>
              <div className={styles.itrText}>{ITR_Tap}</div>
              <img src={dropDownIcon} alt="" className={styles.dropDown} />
            </div>

            {showDropDown && (
              <ul className={styles.itrList}>
                {ITR?.map((data, idx) => (
                  <li
                    key={idx}
                    className={classNames(
                      isSelect(data) ? styles.activeITR : styles.inactiveITR
                    )}
                    onClick={(e) => onItrSelect(e)}
                  >
                    {data}
                  </li>
                ))}
              </ul>
            )}
          </div>

          <>
            <ul className={styles.menuCategory}>
              <li>Name</li>
              <li>Video</li>
              <li>Status</li>
              <li>Impressions</li>
              <li>Installs</li>
              <li>CPI</li>
              <li>Clicks</li>
              <li>CPC</li>
              <li>CTR</li>
            </ul>
            {adsData?.length ? (
              <div className={styles.adsDataContainer}>
                {adsData?.map((data, idx) => {
                  return (
                    <div className={styles.dataList} key={idx}>
                      <div className={styles.adsName}>{data?.name}</div>
                      <div
                        className={styles.videoPlayBtn}
                        onClick={() => onClick(data.creative.video_id)}
                        key={data.creative.video_id}
                      >
                        <a
                          href={videoSource?.source}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src={playIcon}
                            alt=""
                            className={styles.videoIcon}
                          />
                        </a>
                      </div>
                      <div className={styles.status}>
                        {status.length !== 0 &&
                          status?.find((i) => i?.name === data?.name)?.status}
                      </div>
                      <div className={styles.dataText}>
                        {data?.insights
                          ? data?.insights?.data[0]?.impressions
                          : "-"}
                      </div>
                      <div className={styles.dataText}>
                        {data?.insights?.data[0]?.actions?.find(
                          (item) => item.action_type === "omni_app_install"
                        )
                          ? data?.insights?.data[0]?.actions?.map((action) => {
                              switch (action.action_type) {
                                case "omni_app_install":
                                  return action.value;
                                default:
                                  return "";
                              }
                            })
                          : "-"}
                      </div>
                      <div className={styles.dataText}>
                        {data?.insights?.data[0]?.cost_per_action_type?.find(
                          (item) => item.action_type === "omni_app_install"
                        )
                          ? data?.insights?.data[0]?.cost_per_action_type?.map(
                              (cost) => {
                                switch (cost.action_type) {
                                  case "omni_app_install":
                                    return `$ ${Number(cost.value).toFixed(2)}`;
                                  default:
                                    return "";
                                }
                              }
                            )
                          : "-"}
                      </div>
                      <div className={styles.dataText}>
                        {data?.insights ? data?.insights?.data[0]?.clicks : "-"}
                      </div>
                      <div className={styles.dataText}>
                        {data?.insights?.data[0]?.cpc
                          ? `$ ${Number(data?.insights?.data[0]?.cpc).toFixed(
                              2
                            )}`
                          : "-"}
                      </div>
                      <div className={styles.dataText}>
                        {data?.insights?.data[0]?.ctr
                          ? `${Number(data?.insights?.data[0]?.ctr).toFixed(
                              2
                            )}%`
                          : "-"}
                      </div>
                    </div>
                  );
                })}

                <>
                  <div className={styles.adsetList}>
                    <div className={styles.adsetAdsName}>{adsetData?.name}</div>
                    <div>
                      <div />
                    </div>
                    <div className={styles.adsetStatus}>
                      <div />
                    </div>
                    {adsetData?.insights?.data?.map((data, idx) => (
                      <>
                        <div className={styles.adsetDataText} key={idx}>
                          {data ? data?.impressions : "-"}
                        </div>
                        <div className={styles.adsetDataText} key={data?.cpc}>
                          {data?.actions
                            ? data?.actions?.map((action) => {
                                switch (action.action_type) {
                                  case "omni_app_install":
                                    return action.value;
                                  default:
                                    return "";
                                }
                              })
                            : "-"}
                        </div>
                        <div
                          className={styles.adsetDataText}
                          key={data?.cost_per_action_type}
                        >
                          {data?.cost_per_action_type &&
                            data?.cost_per_action_type?.map((cost) => {
                              switch (cost.action_type) {
                                case "omni_app_install":
                                  setAverageCpi(Number(cost.value).toFixed(2));
                                  return `$ ${Number(cost.value).toFixed(2)}`;
                                default:
                                  return "";
                              }
                            })}
                        </div>
                        <div
                          className={styles.adsetDataText}
                          key={data?.clicks}
                        >
                          {data ? data?.clicks : "-"}
                        </div>
                        <div
                          className={styles.adsetDataText}
                          key={Number(data?.cpc).toFixed(2)}
                        >
                          {data?.cpc
                            ? `$ ${Number(data?.cpc).toFixed(2)}`
                            : "-"}
                        </div>

                        <div className={styles.adsetDataText} key={data?.ctr}>
                          {data?.ctr ? `${Number(data?.ctr).toFixed(2)}%` : "-"}
                        </div>
                      </>
                    ))}
                  </div>
                </>
              </div>
            ) : (
              <SkelSkeletonCampaignetonUi />
            )}
          </>
        </>
      )}
    </div>
  );
};

export default React.memo(AdsData);
