import {
  createContext,
  createRef,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

const InvoiceContext = createContext({});
const contextRef = createRef();
export function InvoiceProvider({ invoiceService, children }) {
  const [invoiceList, setInvoiceList] = useState([]);

  const studioInvoiceInfo = useCallback(
    (studioId) => {
      return invoiceService.studioInvoiceInfo(studioId).then((res) => res);
    },
    [invoiceService]
  );
  const editStudioInvoiceInfo = useCallback(
    (data) => {
      return invoiceService.editStudioInvoiceInfo(data).then((res) => res);
    },
    [invoiceService]
  );

  const getOverviewData = useCallback(
    (startDate, endDate) => {
      return invoiceService
        .getOverviewData(startDate, endDate)
        .then((res) => res);
    },
    [invoiceService]
  );
  const downloadOverviewData = useCallback(
    (startDate, endDate, gameIds) => {
      return invoiceService
        .downloadOverviewData(startDate, endDate, gameIds)
        .then((res) => res);
    },
    [invoiceService]
  );

  const getRecentInvoiceList = useCallback(
    async (startMonth, endMomth) => {
      return await invoiceService
        .getInvoiceList(startMonth, endMomth)
        .then((res) => res);
    },
    [invoiceService]
  );
  const getAllTimeInvoiceList = useCallback(
    async (startMonth, endMomth) => {
      return await invoiceService
        .getInvoiceList(startMonth, endMomth)
        .then((res) => res);
    },
    [invoiceService]
  );
  const getInvoiceList = useCallback(
    async (startMonth, endMomth) => {
      return await invoiceService
        .getInvoiceList(startMonth, endMomth)
        .then((res) => res);
    },
    [invoiceService]
  );
  const downloadInvoice = useCallback(
    (studioId, date) => {
      return invoiceService.downloadInvoice(studioId, date).then((res) => res);
    },
    [invoiceService]
  );
  const downloadDetail = useCallback(
    (studioId, gameId, date) => {
      return invoiceService
        .downloadDetail(studioId, gameId, date)
        .then((res) => res);
    },
    [invoiceService]
  );
  const downloadRemittance = useCallback(
    (studioId, date) => {
      return invoiceService
        .downloadRemittance(studioId, date)
        .then((res) => res);
    },
    [invoiceService]
  );
  const context = useMemo(
    () => ({
      getInvoiceList,
      downloadRemittance,
      setInvoiceList,
      invoiceList,
      studioInvoiceInfo,
      downloadInvoice,
      downloadDetail,
      editStudioInvoiceInfo,
      getOverviewData,
      downloadOverviewData,
      getRecentInvoiceList,
      getAllTimeInvoiceList,
    }),
    [
      getInvoiceList,
      invoiceList,
      downloadInvoice,
      studioInvoiceInfo,
      downloadDetail,
      downloadRemittance,
      editStudioInvoiceInfo,
      getOverviewData,
      downloadOverviewData,
      getRecentInvoiceList,
      getAllTimeInvoiceList,
    ]
  );
  return (
    <InvoiceContext.Provider value={context}>
      {children}
    </InvoiceContext.Provider>
  );
}

export default InvoiceContext;
export const fetchToken = () => contextRef.current;
export const useInvoice = () => useContext(InvoiceContext);
