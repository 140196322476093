import React from "react";
import styles from "./RetentionData.module.scss";
const RetentionData = ({ d1Retention }) => {
  return (
    <div className={styles.container}>
      <div>D1 Ret</div>
      <div>{d1Retention}</div>
    </div>
  );
};

export default RetentionData;
