import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { useAuth } from "../../Context/AuthContext";
import { useInvoice } from "../../Context/InvoiceContext";
import { deleteCookie, getCookie } from "../../db/cookies";
import { ReactComponent as AlertIcon } from "../../img/Profile/alert.svg";
import InvoiceBankInfo from "../InvoiceBankInfo/InvoiceBankInfo";
import InvoiceStudioInfo from "../InvoiceStudioInfo/InvoiceStudioInfo";
import styles from "./PaymentDetail.module.scss";
export default function PaymentDetail({
  paymentInfo,
  setPaymentInfo,
  setSelectedMenu,
}) {
  const { studioInvoiceInfo, editStudioInvoiceInfo } = useInvoice();
  const { invoiceUser } = useAuth();
  const [isPayment, setIsPayment] = useState(false);
  const editBtnRef = useRef(null);
  useEffect(() => {
    studioInvoiceInfo(invoiceUser.studioId).then((res) => {
      if (res?.status === 401) {
        deleteCookie("Authentication");
        if (!getCookie("Authentication")) {
          setSelectedMenu({ id: 1 });
        }
      } else {
        setIsPayment(res.data.isPayment);
        setPaymentInfo({ studio: res.data.studio, payment: res.data.payment });
      }
    });
  }, [invoiceUser, setPaymentInfo, setSelectedMenu, studioInvoiceInfo]);

  const onInfoChange = (e) => {
    const { id, value } = e.target;
    switch (id) {
      case "paymentTo":
        setPaymentInfo((prev) => ({
          ...prev,
          studio: { ...prev.studio, paymentTo: value },
        }));
        return;
      case "phoneNumber":
        setPaymentInfo((prev) => ({
          ...prev,
          studio: { ...prev.studio, phoneNumber: value },
        }));
        return;
      case "email":
        setPaymentInfo((prev) => ({
          ...prev,
          studio: { ...prev.studio, email: value },
        }));
        return;
      case "businessAddress":
        setPaymentInfo((prev) => ({
          ...prev,
          studio: { ...prev.studio, businessAddress: value },
        }));
        return;
      case "vatOrGst":
        setPaymentInfo((prev) => ({
          ...prev,
          studio: { ...prev.studio, vatOrGst: value },
        }));
        return;
      case "bankAccountName":
        setPaymentInfo((prev) => ({
          ...prev,
          payment: {
            ...prev.payment,
            id: prev.payment.id === "" ? null : prev.payment.id,
            accountName: value,
          },
        }));
        return;
      case "accountNumber":
        setPaymentInfo((prev) => ({
          ...prev,
          payment: { ...prev.payment, accountNumber: value },
        }));
        return;
      case "bankName":
        setPaymentInfo((prev) => ({
          ...prev,
          payment: { ...prev.payment, bankName: value },
        }));
        return;
      case "bankAddress":
        setPaymentInfo((prev) => ({
          ...prev,
          payment: { ...prev.payment, bankAddress: value },
        }));
        return;
      case "swiftCode":
        setPaymentInfo((prev) => ({
          ...prev,
          payment: { ...prev.payment, swiftCode: value },
        }));
        return;
      case "branchCode":
        setPaymentInfo((prev) => ({
          ...prev,
          payment: { ...prev.payment, branchCode: value },
        }));
        return;
      case "ifscCode":
        setPaymentInfo((prev) => ({
          ...prev,
          payment: { ...prev.payment, ifscCode: value },
        }));
        return;
      case "routingNumber":
        setPaymentInfo((prev) => ({
          ...prev,
          payment: { ...prev.payment, routingNumber: value },
        }));
        return;
      default:
        return;
    }
  };

  const editInfoClick = () => {
    let emailRule =
      /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;

    if (!paymentInfo?.studio?.paymentTo?.length) {
      alert("Please check the studio name");
    } else if (
      !paymentInfo?.studio?.email?.length ||
      !emailRule.test(paymentInfo?.studio?.email)
    ) {
      alert("Please check the studio email");
    } else if (!paymentInfo?.studio?.businessAddress?.length) {
      alert("Please check the studio address");
    } else if (!paymentInfo?.studio?.phoneNumber?.length) {
      alert("Please check the studio phone number");
    } else if (!paymentInfo?.payment?.bankName?.length) {
      alert("Please check the studio bank name");
    } else if (!paymentInfo?.payment?.bankAddress?.length) {
      alert("Please check the studio bank address");
    } else if (!paymentInfo?.payment?.bankName?.length) {
      alert("Please check the studio bank name");
    } else if (!paymentInfo?.payment?.accountName?.length) {
      alert("Please check the studio account name");
    } else if (!paymentInfo?.payment?.accountNumber?.length) {
      alert("Please check the studio account number");
    } else if (!paymentInfo?.payment?.swiftCode?.length) {
      alert("Please check the studio swift code");
    } else {
      editStudioInvoiceInfo(paymentInfo).then((res) => {
        if (res.status === 201) {
          window.location.reload();
        } else if (res.status === 401) {
          deleteCookie("Authentication");
          window.location.reload();
        } else {
          alert(res.data.message);
        }
      });
    }
  };
  useEffect(() => {
    if (isPayment) {
      const alertMessage = document.getElementsByClassName(
        styles.alert_message
      );
      const editBtnHoverHandler = (e) => {
        if (editBtnRef?.current?.contains(e.target)) {
          return (alertMessage[0].style.display = "flex");
        } else {
          alertMessage[0].style.display = "none";
        }
      };
      document.addEventListener("mouseover", editBtnHoverHandler);
      return () =>
        document.removeEventListener("mouseover", editBtnHoverHandler);
    }
  }, [isPayment]);

  return (
    <div className={styles.container}>
      <section className={styles.info_input}>
        <span className={styles.studio_info}>
          <h2>Licensor Information</h2>
          <InvoiceStudioInfo
            studioInfo={paymentInfo}
            onInfoChange={onInfoChange}
            invoiceUser={invoiceUser}
            isPayment={isPayment}
          />
        </span>
        <span className={styles.studio_bank_info}>
          <h2>Licensor Bank Information</h2>
          <InvoiceBankInfo
            bankInfo={paymentInfo}
            onInfoChange={onInfoChange}
            invoiceUser={invoiceUser}
            isPayment={isPayment}
          />
        </span>
      </section>
      <section className={styles.button_area} ref={editBtnRef}>
        <button
          className={styles.edit_btn}
          data-content={isPayment ? "invalid" : "active"}
          onClick={isPayment ? undefined : editInfoClick}
        >
          <p>Edit</p>
        </button>
        {isPayment && (
          <div className={styles.alert_message}>
            <AlertIcon />
            <p>
              To make any changes, please request access from our publishing
              managers via Discord or email at{" "}
              <a
                href="mailto:publishing@mondayoff.me"
                target="_blank"
                rel="noreferrer"
              >
                publishing@mondayoff.me.
              </a>
            </p>
          </div>
        )}
      </section>
    </div>
  );
}
