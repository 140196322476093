import { getCookie } from "../db/cookies";

export default class InvoiceService {
  constructor(http) {
    this.http = http;
  }
  async studioInvoiceInfo(studioId) {
    return this.http
      .axios(`/test/studio/info?studioId=${studioId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getCookie("Authentication")}`,
        },
      })
      .then((res) => res)
      .catch((e) => e.response);
  }
  async editStudioInvoiceInfo(data) {
    return this.http
      .axios(`/test/studio/info/update`, {
        method: "POST",
        data,
        headers: {
          Authorization: `Bearer ${getCookie("Authentication")}`,
        },
      })
      .then((res) => res)
      .catch((e) => e.response);
  }
  async getInvoiceList(startMonth, endMonth) {
    return this.http
      .axios(
        `/test/invoice/list?startMonth=${startMonth}&endMonth=${endMonth}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${getCookie("Authentication")}`,
          },
        }
      )
      .then((res) => res)
      .catch((e) => e.response);
  }
  async downloadInvoice(studioId, date) {
    return await this.http
      .axios(`/test/invoice/download?studioId=${studioId}&month=${date}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getCookie("Authentication")}`,
        },
        responseType: "blob",
      })
      .then((res) => res)
      .catch((e) => e.response);
  }
  async downloadDetail(studioId, gameId, date) {
    return await this.http
      .axios(
        `/test/invoice/detail/download?studioId=${studioId}&month=${date}&gameId=${gameId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${getCookie("Authentication")}`,
          },
          responseType: "blob",
        }
      )
      .then((res) => res)
      .catch((e) => e.response);
  }
  async downloadRemittance(studioId, date) {
    return await this.http
      .axios(
        `/test/invoice/payment/statement?studioId=${studioId}&month=${date}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${getCookie("Authentication")}`,
          },
          responseType: "blob",
        }
      )
      .then((res) => res)
      .catch((e) => e.response);
  }
  async getOverviewData(startDate, endDate) {
    return await this.http
      .axios(
        `/test/common/finance/overview?startDate=${startDate}&endDate=${endDate}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${getCookie("Authentication")}`,
          },
        }
      )
      .then((res) => res)
      .catch((e) => e.response);
  }

  async downloadOverviewData(startDate, endDate, gameIds) {
    return await this.http
      .axios(
        `/test/common/finance/overview/csv?startDate=${startDate}&endDate=${endDate}&everyDayGameIds=${gameIds}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${getCookie("Authentication")}`,
          },
        }
      )
      .then((res) => res)
      .catch((e) => e.response);
  }
}
