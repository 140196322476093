import React, { useState, useEffect, memo } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import ImgBox from "../../../components/AppId/ImgBox/ImgBox";
import styles from "./RegistAppId.module.css";
import PrivacyModal from "../../../components/AppId/PrivacyModal/PrivacyModal";
import downArrow from "../../../img/arrow/grayDown.png";
import Modal from "../../../common/Modal/Modal";
const RegistAppId = ({ gameIcon, setGameIcon, user, listCount }) => {
  const navigate = useNavigate();
  const [gameTitle, setGameTitle] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [type, setType] = useState("Normal");

  const [selectGenre, setSelectGenre] = useState({
    id: 1,
    genre: "Hyper Casual",
  });
  const gameGenre = [
    {
      id: 1,
      genre: "Hyper Casual",
    },
    {
      id: 2,
      genre: "Hybrid Casual",
    },
    {
      id: 3,
      genre: "Idle Arcade",
    },
    {
      id: 4,
      genre: "Clicker",
    },
  ];
  const [dropDown, setDropDown] = useState(false);
  const [genreDropDown, setGenreDropDown] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  useEffect(() => {
    if (localStorage.getItem("gameName") && localStorage.getItem("type")) {
      setGameTitle(localStorage.getItem("gameName"));
      setType(localStorage.getItem("type"));
    }
  }, []);
  useEffect(() => {
    if (moment().isBetween("2023-05-29 09:00:00", "2023-07-08 09:00:00")) {
      setType("MondayOFF Party");
    } else {
      setType("Normal");
    }
  }, []);

  const onClick = (e) => {
    e.preventDefault();
    if (user?.block === true) {
      setAlertMessage("please contact our manager");
      setAlertModal(true);
    } else if (listCount >= 2 && user.id !== 290) {
      setAlertMessage(
        "Up to 2 campaigns can be active simultaneously. Please wait until one campaign concludes before resubmitting."
      );
      setAlertModal(true);
    } else if (!gameTitle) {
      setAlertMessage("Check your game title");
      setAlertModal(true);
    } else if (!gameIcon) {
      setAlertMessage("Please upload your game icon");
      setAlertModal(true);
    } else if (localStorage.getItem("app_Id")) {
      navigate("/mytest/newapp/basicsetting");
    } else {
      setShowModal(true);
    }
  };
  const onTypeClick = (e) => {
    setType(e.target.innerText);
    setDropDown(false);
  };

  const isDropDown = () => {
    setDropDown(!dropDown);
  };
  const isGenreDropDown = () => {
    setGenreDropDown(!genreDropDown);
  };
  const onChange = (e) => {
    setGameTitle(e.target.value);
  };
  useEffect(() => {
    localStorage.setItem("gameName", gameTitle);
    localStorage.setItem("type", type);
    localStorage.setItem("genre", selectGenre.genre);
  }, [gameTitle, type, selectGenre.genre]);

  useEffect(() => {
    if (dropDown === true) {
      let selectEventType = document.getElementsByClassName(styles.eventActive);
      let selectNormalType = document.getElementsByClassName(
        styles.normalActive
      );
      if (type === selectEventType[0]?.innerText) {
        selectEventType[0]?.classList.add(styles.selectEvent);
        selectNormalType[0]?.classList.remove(styles.selectNormal);
      } else if (type === selectNormalType[0]?.innerText) {
        selectEventType[0]?.classList.remove(styles.selectEvent);
        selectNormalType[0]?.classList.add(styles.selectNormal);
      }
    } else {
      return;
    }
  });
  const isSelect = (item) => {
    return item?.id === selectGenre.id;
  };
  return (
    <div className={styles.box}>
      <div className={styles.title}>Submit New Game</div>
      {alertModal && (
        <Modal message={alertMessage} setAlertModal={setAlertModal} />
      )}

      <div className={styles.container}>
        <ImgBox gameIcon={gameIcon} setGameIcon={setGameIcon} />

        <div className={styles.gameTitleAndGenre}>
          <div>
            <div className={styles.gameTitleText}>Game Title</div>
            <input
              type="text"
              className={styles.gameTitleInput}
              placeholder="Game Title"
              onChange={onChange}
              value={gameTitle}
            />
          </div>

          <div className={styles.gameGenreContent}>
            <div>Game</div>
            <div onClick={isGenreDropDown} className={styles.genreClick}>
              <div className={styles.genreText}>{selectGenre.genre}</div>
              <img src={downArrow} alt="" className={styles.downArrow} />
            </div>
            {genreDropDown && (
              <div className={styles.genreBox}>
                {gameGenre.map((item) => (
                  <li
                    className={
                      isSelect(item) ? styles.activeGenre : styles.genre
                    }
                    onClick={() => {
                      setSelectGenre(item);
                      setGenreDropDown(false);
                    }}
                  >
                    <div>{item.genre}</div>
                  </li>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className={styles.submission}>
          <div className={styles.submissionText}>Submission Type</div>
          <div onClick={isDropDown} className={styles.typeClick}>
            <div className={styles.typeText}>{type}</div>
            <img src={downArrow} alt="" className={styles.downArrow} />
          </div>
          {dropDown && (
            <ul className={styles.dropDown}>
              <li onClick={onTypeClick} className={styles.normalActive}>
                <span>Normal</span>
              </li>
              <li
                onClick={
                  moment().isBetween(
                    "2023-05-29 09:00:00",
                    "2023-07-08 09:00:00"
                  )
                    ? onTypeClick
                    : ""
                }
                className={
                  moment().isBetween(
                    "2023-05-29 09:00:00",
                    "2023-07-08 09:00:00"
                  )
                    ? styles.eventActive
                    : styles.eventInActive
                }
              >
                <span>MondayOFF Party</span>
              </li>
            </ul>
          )}
        </div>
        {showModal && (
          <PrivacyModal setShowModal={setShowModal} navigate={navigate} />
        )}
      </div>
      <button className={styles.nextBtn} onClick={onClick}>
        Next
      </button>
    </div>
  );
};

export default memo(RegistAppId);
