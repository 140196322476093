import React from "react";
import Skeleton from "@mui/material/Skeleton";
import styles from "./FinanceOverviewSummarySkeleton.module.scss";
export default function FinanceOverviewSummarySkeleton() {
  return (
    <section className={styles.data_section}>
      <span className={styles.skeleton}>
        <Skeleton animation="wave" className={styles.data_name} />
        <Skeleton animation="wave" className={styles.data} />
      </span>
      <span className={styles.skeleton}>
        <Skeleton animation="wave" className={styles.data_name} />
        <Skeleton animation="wave" className={styles.data} />
      </span>
      <span className={styles.skeleton}>
        <Skeleton animation="wave" className={styles.data_name} />
        <Skeleton animation="wave" className={styles.data} />
      </span>
      <span className={styles.skeleton}>
        <Skeleton animation="wave" className={styles.data_name} />
        <Skeleton animation="wave" className={styles.data} />
      </span>
    </section>
  );
}
