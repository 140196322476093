import React from "react";
import { usePopup } from "../../Context/PopupContext";
import styles from "./Popup.module.scss";
import popupIcon from "../../img/popup/popupImg.png";
export default function Popup() {
  const { setIsPopup } = usePopup();
  return (
    <section className={styles.container}>
      <div className={styles.textArea}>
        <img src={popupIcon} alt="icon" />
        <div>
          <h2>Tests temporarily suspended until October 4th.</h2>
          <p>
            Tests are temporarily suspended due to a scheduled server
            inspection. <span>Please submit your test after October 4th.</span>{" "}
            Thank you.
          </p>
        </div>
        <button onClick={() => setIsPopup(false)}>close</button>
      </div>
    </section>
  );
}
