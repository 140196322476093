import React from "react";
import Skeleton from "@mui/material/Skeleton";
import styles from "./SkeletonCampaign.module.scss";
export default function SkelSkeletonCampaignetonUi() {
  return (
    <ul className={styles.container}>
      <li className={styles.skeleton_ui}>
        <Skeleton animation="wave" className={styles.name} />
        <Skeleton animation="wave" className={styles.video} />
        <Skeleton animation="wave" className={styles.status} />
        <Skeleton animation="wave" className={styles.impressions} />
        <Skeleton animation="wave" className={styles.installs} />
        <Skeleton animation="wave" className={styles.cpi} />
        <Skeleton animation="wave" className={styles.clicks} />
        <Skeleton animation="wave" className={styles.cpc} />
        <Skeleton animation="wave" className={styles.ctr} />
      </li>
      <li className={styles.skeleton_ui}>
        <Skeleton animation="wave" className={styles.name} />
        <Skeleton animation="wave" className={styles.video} />
        <Skeleton animation="wave" className={styles.status} />
        <Skeleton animation="wave" className={styles.impressions} />
        <Skeleton animation="wave" className={styles.installs} />
        <Skeleton animation="wave" className={styles.cpi} />
        <Skeleton animation="wave" className={styles.clicks} />
        <Skeleton animation="wave" className={styles.cpc} />
        <Skeleton animation="wave" className={styles.ctr} />
      </li>
      <li className={styles.skeleton_ui}>
        <Skeleton animation="wave" className={styles.name} />
        <Skeleton animation="wave" className={styles.video} />
        <Skeleton animation="wave" className={styles.status} />
        <Skeleton animation="wave" className={styles.impressions} />
        <Skeleton animation="wave" className={styles.installs} />
        <Skeleton animation="wave" className={styles.cpi} />
        <Skeleton animation="wave" className={styles.clicks} />
        <Skeleton animation="wave" className={styles.cpc} />
        <Skeleton animation="wave" className={styles.ctr} />
      </li>
      <li className={styles.skeleton_ui}>
        <Skeleton animation="wave" className={styles.name} />
        <Skeleton animation="wave" className={styles.video} />
        <Skeleton animation="wave" className={styles.status} />
        <Skeleton animation="wave" className={styles.impressions} />
        <Skeleton animation="wave" className={styles.installs} />
        <Skeleton animation="wave" className={styles.cpi} />
        <Skeleton animation="wave" className={styles.clicks} />
        <Skeleton animation="wave" className={styles.cpc} />
        <Skeleton animation="wave" className={styles.ctr} />
      </li>
      <li className={styles.skeleton_ui}>
        <Skeleton animation="wave" className={styles.name} />
        <Skeleton animation="wave" className={styles.video} />
        <Skeleton animation="wave" className={styles.status} />
        <Skeleton animation="wave" className={styles.impressions} />
        <Skeleton animation="wave" className={styles.installs} />
        <Skeleton animation="wave" className={styles.cpi} />
        <Skeleton animation="wave" className={styles.clicks} />
        <Skeleton animation="wave" className={styles.cpc} />
        <Skeleton animation="wave" className={styles.ctr} />
      </li>
    </ul>
  );
}
