import React, { useEffect, useState } from "react";
import moment from "moment";
import { subDays } from "date-fns";
import Status from "../../components/Finance/Status";
import PubCalender from "../../components/PubCalendar/PubCalendar";
import PubSummary from "../../components/PubSummary/PubSummary";
import styles from "./PublishingDetail.module.scss";

const PublishingDetail = ({ user, adsNetworkService }) => {
  const [select, setSelect] = useState({ id: 1, select: "7 days" });
  const [clickCalendar, setClickCalendar] = useState(false);
  const [adsList, setAdsList] = useState([]);
  const [newAdNetworkData, setNewAdNetworkData] = useState([]);
  const [dayList, setDayList] = useState([
    { id: 1, select: "7 days" },
    { id: 2, select: "30 days" },
    { id: 3, select: "Custom" },
  ]);

  useEffect(() => {
    if (!user?.data?.publishing) {
      return;
    } else {
      adsNetworkService.getAdsList(user?.data?.id).then((data) => {
        setAdsList(data);
      });
    }
  }, [user?.data?.id]);

  const [state, setState] = useState({
    startDate: subDays(new Date(moment().format("YYYY-MM-DD")), 10),
    endDate: subDays(new Date(moment().format("YYYY-MM-DD")), 4),
    key: "selection",
  });
  const [thirtyState, setThirtyState] = useState({
    startDate: subDays(new Date(moment().format("YYYY-MM-DD")), 34),
    endDate: subDays(new Date(moment().format("YYYY-MM-DD")), 4),
    key: "selection",
  });

  const [selectRange, setSelectRange] = useState([
    {
      startDate: subDays(new Date(moment().format("YYYY-MM-DD")), 10),
      endDate: subDays(new Date(moment().format("YYYY-MM-DD")), 4),
      key: "selection",
    },
  ]);

  useEffect(() => {
    (async () => {
      setNewAdNetworkData([]);
      if (adsList.length !== 0) {
        if (select.id === 1) {
          await adsNetworkService
            .getNewAdnetworkData(state.startDate, state.endDate, adsList)
            .then((res) => setNewAdNetworkData(res));
        } else if (select.id === 2) {
          await adsNetworkService
            .getNewAdnetworkData(
              thirtyState.startDate,
              thirtyState.endDate,
              adsList
            )
            .then((res) => setNewAdNetworkData(res));
        }
      }
    })();
  }, [adsList, adsNetworkService, select.id, state, thirtyState]);

  const rangeDateUpdate = async () => {
    setNewAdNetworkData([]);
    setClickCalendar(false);
    if (adsList.length !== 0) {
      await adsNetworkService
        .getNewAdnetworkData(
          selectRange[0].startDate,
          selectRange[0].endDate,
          adsList
        )
        .then((res) => setNewAdNetworkData(res))
        .catch(console.error);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.firstSectionBox}>
        <PubSummary newAdNetworkData={newAdNetworkData} />
        <PubCalender
          select={select}
          setSelect={setSelect}
          thirtyState={thirtyState}
          setThirtyState={setThirtyState}
          clickCalendar={clickCalendar}
          setClickCalendar={setClickCalendar}
          dayList={dayList}
          setDayList={setDayList}
          state={state}
          setState={setState}
          selectRange={selectRange}
          setSelectRange={setSelectRange}
          rangeDateUpdate={rangeDateUpdate}
        />
      </div>
      <Status newAdNetworkData={newAdNetworkData} adsList={adsList} />
    </div>
  );
};

export default PublishingDetail;
