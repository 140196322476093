import React from "react";
import styles from "./SecondDocModal.module.css";
import firstImg from "../../../img/setmanager/second.png";

const SecondDocModal = ({ setSecondModal }) => {
  const onClick = () => {
    setSecondModal(false);
  };
  return (
    <div className={styles.container}>
      <div className={styles.modalContainer}>
        <div className={styles.registTitle}>
          <div className={styles.registTitleText}>
            Setup Roles on Facebook for Developers
          </div>
        </div>
        <div className={styles.scroll}>
          <div className={styles.secondDocContainer}>
            <div className={styles.secondDocContext}>
              <div className={styles.one}>
                Setup Roles on Facebook for Developers
              </div>
              <div className={styles.two}>
                <div className={styles.two_one}>
                  1. Setup Roles for your App{" "}
                </div>

                <div className={styles.two_two}>- Go to Roles {"->"} Roles</div>
                <div className={styles.two_three}>
                  Click <span>“Add Administrators”</span> and add everyone of
                  MondayOFF’s Publishing Team
                </div>
                <img src={firstImg} alt="" className={styles.firstImg} />
              </div>

              <button className={styles.closeBtn} onClick={onClick}>
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(SecondDocModal);
