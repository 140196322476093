import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import styles from "./ThirdProcess.module.scss";
import img from "../../img/process/thirdProcess.png";
import progressBar from "../../img/process/thirdProgressBar.png";
import getGA from "../../service/ga";
const ThirdProcess = (props) => {
  const location = useLocation();
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_ID);
    ReactGA.send({
      hitType: "pageview",
      path: location.pathname,
      location: location.pathname,
      title: "ProcessInfo-third",
    });
    getGA();
  }, [location.pathname]);
  const navigate = useNavigate();
  const onClick = () => {
    navigate("/mytest/newapp");
  };
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        Test your game in just three simple steps!
      </div>
      <div className={styles.processText}>3. Upload your creatives</div>
      <img src={img} alt="" className={styles.img} />
      <img src={progressBar} alt="" className={styles.progressBar} />
      <button className={styles.nextBtn} onClick={onClick}>
        Submit your game
      </button>
    </div>
  );
};

export default ThirdProcess;
///mytest/newapp
