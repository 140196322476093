import React from "react";
import styles from "./CheckBox.module.css";
const CheckBox = ({
  firstCheck,
  setFirstCheck,
  secondCheck,
  setSecondCheck,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.firstCheck}>
        <input type="checkbox" id="firstCheck" className={styles.firstCheck} />
        <label
          htmlFor="firstCheck"
          onClick={() => setFirstCheck(!firstCheck)}
        ></label>
        <div className={styles.text}>
          Did you Upload the Game Version with the Integrated SDK to the Google
          Play Store?
        </div>
      </div>
      <div className={styles.secondCheck}>
        <input
          type="checkbox"
          id="secondCheck"
          className={styles.secondCheck}
        />
        <label
          htmlFor="secondCheck"
          onClick={() => setSecondCheck(!secondCheck)}
        ></label>
        <div className={styles.secondText}>
          Did you Finish Setting Up the Platform in the Developers Page?
        </div>
      </div>
    </div>
  );
};

export default CheckBox;
