import { Cookies } from "react-cookie";

const cookies = new Cookies();

export const setCookie = (cookieName, value, option) => {
  return cookies.set(cookieName, value, { ...option });
};
export const getCookie = (cookieName) => {
  return cookies.get(cookieName);
};
export const deleteCookie = (cookieName) => {
  return cookies.remove(cookieName);
};
