import React from "react";
import styles from "./VideoModal.module.css";
import firstVideo from "../../../../videoGuide/SDK_Guide.mp4";

const VideoModal = ({ setVideoGuide }) => {
  const onClick = () => {
    setVideoGuide(false);
  };
  return (
    <div className={styles.container}>
      <div className={styles.modalContainer}>
        <div className={styles.videoTitle}>
          <div className={styles.videoTitleText}>VideoModal</div>
        </div>
        <div className={styles.scroll}>
          <div className={styles.firstVideoContainer}>
            <div className={styles.firstVideoContext}>
              <>
                <div className={styles.one}>How to Integrate MondayOFF SDK</div>
                <video controls className={styles.firstVideo}>
                  <source src={firstVideo} type="video/mp4" />
                </video>
              </>

              <button className={styles.closeBtn} onClick={onClick}>
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(VideoModal);
