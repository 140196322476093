import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CompletionTitle from "../../../components/Completion/CompletionTitle/CompletionTitle";
import GameIcon from "../../../components/Completion/GameIcon/GameIcon";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import styles from "./Completion.module.css";
import getGA from "../../../service/ga";
const Completion = (props) => {
  const location = useLocation();
  const [isClick, setIsClick] = useState(false);
  const onClick = () => {
    setIsClick(true);
  };
  useEffect(() => {
    if (isClick) {
      return localStorage.removeItem("appInfo");
    }
    return () => {
      setIsClick(false);
    };
  }, [isClick]);
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_ID);
    ReactGA.send({
      hitType: "pageview",
      path: location.pathname,
      location: location.pathname,
      title: "Finish",
    });
    getGA();
  }, [location.pathname]);
  return (
    <div className={styles.container}>
      <CompletionTitle />
      <GameIcon />
      <div className={styles.contactEmail}>
        <div>
          Need help? Contact by&nbsp;
          <a
            href="mailto:publishing@mondayoff.me"
            target="_blank"
            rel="noreferrer"
            className={styles.sendEmail}
          >
            publishing@mondayoff.me
          </a>
        </div>
      </div>
      <Link to="/progress" onClick={onClick}>
        <button className={styles.okBtn}>Done</button>
      </Link>
    </div>
  );
};
export default Completion;
