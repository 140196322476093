import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import "./index.css";
import App from "./App";
import { HttpClient, AdsNetworkHttpClient } from "./network/http";
import { AuthErrorEventBus, AuthProvider } from "./Context/AuthContext";
import TokenStorage from "./db/token";
import AuthService from "./service/auth";
import AdsService from "./service/ads";
import MetaService from "./service/metaAPI";
import SdkService from "./service/sdk";
import AdsListService from "./service/adsList";
import MaxService from "./service/max";
import AdsNetworkService from "./service/adNetwork";
import { PopupProvider } from "./Context/PopupContext";
import { RecoilRoot } from "recoil";
import InvoiceService from "./service/Invoice";

const tokenStorage = new TokenStorage();
const baseURL = process.env.REACT_APP_BASE_URL;
const adNetworkURL = process.env.REACT_APP_ADS_NETWORK_URL;
const newAdnetworkURL = process.env.REACT_APP_NEW_ADS_NETWORK_URL;

const authErrorEventBus = new AuthErrorEventBus();
const httpClient = new HttpClient(baseURL, authErrorEventBus);
const adsNetworkHttpClient = new AdsNetworkHttpClient(
  adNetworkURL,
  authErrorEventBus
);
const newAdsNetworkHttpClient = new AdsNetworkHttpClient(
  newAdnetworkURL,
  authErrorEventBus
);

const authService = new AuthService(httpClient, tokenStorage);
const adsService = new AdsService(httpClient, tokenStorage);
const metaService = new MetaService(httpClient, tokenStorage);
const sdkService = new SdkService(httpClient);
const adsListService = new AdsListService(httpClient, tokenStorage);
const maxService = new MaxService(httpClient, tokenStorage);
const adsNetworkService = new AdsNetworkService(
  adsNetworkHttpClient,
  newAdsNetworkHttpClient
);
const invoiceService = new InvoiceService(httpClient);
console.warn = console.error = () => {};

ReactDOM.render(
  // <React.StrictMode>
  <BrowserRouter>
    <RecoilRoot>
      <AuthProvider
        authService={authService}
        authErrorEventBus={authErrorEventBus}
      >
        <PopupProvider>
          <App
            adsService={adsService}
            authService={authService}
            metaService={metaService}
            sdkService={sdkService}
            adsListService={adsListService}
            maxService={maxService}
            adsNetworkService={adsNetworkService}
            invoiceService={invoiceService}
          />
        </PopupProvider>
      </AuthProvider>
    </RecoilRoot>
  </BrowserRouter>,
  // </React.StrictMode>,
  document.getElementById("root")
);
