import React from "react";
import styles from "./FirstDocModal.module.css";
import firstImg from "../../../img/setmanager/first.png";

const FirstDocModal = ({ setFirstModal }) => {
  const onClick = () => {
    setFirstModal(false);
  };
  return (
    <div className={styles.container}>
      <div className={styles.modalContainer}>
        <div className={styles.bunisessTitle}>
          <div className={styles.bunisessTitleText}>
            Advanced Facebook Settings for Developers
          </div>
        </div>
        <div className={styles.scroll}>
          <div className={styles.firstDocContainer}>
            <div className={styles.firstDocContext}>
              <div className={styles.one}>
                Advanced Facebook Settings for Developers
              </div>
              <div className={styles.two}>
                <div className={styles.two_one}>
                  1. Advanced Settings Setup for Developers.
                </div>
                <div className={styles.two_two}>
                  - Go to Settings {"->"} Advanced
                </div>
                <div className={styles.two_three}>
                  Add<span> MondayOFF’s Business Account ID</span> to the
                  Authorized ad account IDs
                </div>
                <div className={styles.two_fourth}>
                  Lastly, click on the <span>“Save changes”</span>button.
                </div>

                <img src={firstImg} alt="" className={styles.img} />
              </div>

              <button className={styles.closeBtn} onClick={onClick}>
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(FirstDocModal);
