import React from "react";
import styles from "./BetaAlert.module.scss";
import { ReactComponent as AlertIcon } from "../../img/FinaceOverview/alert.svg";
import { ReactComponent as DotIcon } from "../../img/FinaceOverview/dot.svg";
export default function BetaAlert() {
  return (
    <div className={styles.container}>
      <AlertIcon />
      <p className={styles.first}>Viewing beta version of this page.</p>
      <DotIcon className={styles.dot} />
      <p className={styles.second}>
        Data may be unreliable. Please report inconsistencies via Discord.
      </p>
      <a
        className={styles.link}
        href="https://forms.gle/1aQKeyckb68wsKz9A"
        target="_blank"
        rel="noreferrer"
      >
        Click to provide feedback
      </a>
    </div>
  );
}
