import React, { useEffect, useState, useRef } from "react";
import fileDownload from "js-file-download";
import axios from "axios";
import styles from "./InvoiceGameList.module.scss";
import { ReactComponent as ExpandIcon } from "../../img/Invoice/expand.svg";
import { ReactComponent as MoreIcon } from "../../img/Invoice/more.svg";
import { ReactComponent as DownIcon } from "../../img/Invoice/download.svg";
import InvoiceGameDetail from "../InvoiceGameDetail/InvoiceGameDetail";
import { deleteCookie } from "../../db/cookies";
import { useInvoice } from "../../Context/InvoiceContext";
export default function InvoiceGameList({ item, date, studioId, month }) {
  const { downloadDetail } = useInvoice();
  const [isExpand, setIsExpand] = useState(false);
  const moreRef = useRef(null);
  const [moreClick, setIsMoreClick] = useState(false);
  const [isGameIcon, setIsGameIcon] = useState(false);
  useEffect(() => {
    let outSideClickHandler = (e) => {
      if (moreRef?.current?.contains(e.target)) {
        setIsMoreClick(true);
      } else {
        setIsMoreClick(false);
      }
    };
    document.addEventListener("mousedown", outSideClickHandler);
    return () => document.removeEventListener("mousedown", outSideClickHandler);
  }, []);
  useEffect(() => {
    axios
      .get(item?.gameIconUrl)
      .then((res) =>
        res.status === 200 ? setIsGameIcon(true) : setIsGameIcon(false)
      )
      .catch((e) => e);
  }, []);
  const onDownDetailClick = () => {
    setIsMoreClick(false);
    return downloadDetail(studioId, item.gameId, month).then((res) => {
      if (res.status === 400) {
        alert(res.data.message);
      } else if (res.status === 401) {
        deleteCookie("Authentication");
        window.location.reload();
      } else {
        fileDownload(res.data, `monthly-statement-${month}.pdf`);
      }
    });
  };
  return (
    <>
      <li className={styles.container}>
        {moreClick && (
          <section className={styles.more_menu} ref={moreRef}>
            <div className={styles.detail_download} onClick={onDownDetailClick}>
              <DownIcon className={styles.down_icon} />
              <p>Download Monthly Statement</p>
            </div>
          </section>
        )}
        <span className={styles.name}>
          <ExpandIcon
            className={styles.expand_icon}
            onClick={() => setIsExpand((prev) => !prev)}
            data-content={isExpand && "active"}
          />
          {isGameIcon ? (
            <img
              className={styles.game_icon}
              src={item?.gameIconUrl}
              alt="icon"
            />
          ) : (
            <div className={styles.game_icon} />
          )}
          <p>{item.name}</p>
        </span>
        <p>
          {item.monthlyGrossRevenue.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </p>
        <p>
          {item.monthlySpend.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </p>
        <p>
          {item.thirdPartCosts.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </p>
        <p>
          {item.monthlyNetRevenue.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </p>
        <p>
          {item.revenueShare.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </p>
        <p>
          {item.bonusSum.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </p>
        <div></div>
        <MoreIcon
          className={styles.more_icon}
          onClick={() => setIsMoreClick((prev) => !prev)}
        />
      </li>
      {isExpand && <InvoiceGameDetail data={item} date={date} />}
    </>
  );
}
