import React from "react";
import styles from "./NotPublishing.module.scss";
import lockedIcon from "../../img/emptyImg/locked.png";

const NotPublishing = () => {
  return (
    <div className={styles.container}>
      <img src={lockedIcon} alt="img" />
      <div className={styles.context}>
        <div>Want to unlock this page?</div>
        <div> Let’s publish an awesome game together!</div>
      </div>
    </div>
  );
};

export default NotPublishing;
