import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import RegistAppId from "./RegistAppId/RegistAppId";
import styles from "./NewApp.module.css";
import ReactGA from "react-ga4";
import getGA from "../../service/ga";
import icon from "../../img/registApp/Vector.png";
import { Fragment } from "react";
import moment from "moment";
const NewApp = ({ user, metaService, adsService }) => {
  const location = useLocation();
  const [gameIcon, setGameIcon] = useState("");
  const [listCount, setListCount] = useState(0);
  useEffect(() => {
    const today = moment().format();
    adsService.allAds(user.email).then((res) => {
      setListCount(
        res.filter((list) => {
          const startDate = moment(list.createdAt).format("YYYY-MM-DD");
          const endDate = moment(list.endTime).format("YYYY-MM-DD");
          return moment(today).isBetween(startDate, endDate);
        }).length
      );
    });
  }, [adsService, user]);

  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_ID);
    ReactGA.send({
      hitType: "pageview",
      path: location.pathname,
      location: location.pathname,
      title: "First",
    });
    getGA();
  }, [location.pathname]);
  return (
    <div className={styles.container}>
      {location.pathname === "/mytest/newapp" && (
        <Fragment>
          <div className={styles.progress}>
            <div className={styles.firstCircle}>1</div>
            <div className={styles.line}></div>
            <div className={styles.secondCircle}>2</div>
            <div className={styles.line}></div>
            <div className={styles.thirdCircle}>3</div>
          </div>
          <RegistAppId
            user={user}
            gameIcon={gameIcon}
            setGameIcon={setGameIcon}
            metaService={metaService}
            listCount={listCount}
          />
          <div className={styles.alertMessage}>
            <img src={icon} alt="" className={styles.icon} />
            <div className={styles.text}>
              “Please note that we are currently only testing on the Google Play
              Store.”
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default React.memo(NewApp);
