import React from "react";
import styles from "./LogoutModal.module.css";
import icon from "../../img/alertModal/markIcon.png";
import { useAuth } from "../../Context/AuthContext";
import { deleteCookie } from "../../db/cookies";
const LogoutModal = ({ message, setAlertModal, logoutText, onLogout }) => {
  const { logout } = useAuth();
  const onClick = () => {
    logout();
  };
  const cancelClick = () => {
    setAlertModal(false);
  };
  return (
    <div className={styles.container}>
      <div className={styles.modalContainer}>
        <img src={icon} alt="" className={styles.icon} />
        <div className={styles.alertMessage}>{message}</div>
        <div className={styles.btnContainer}>
          <button className={styles.cancelBtn} onClick={cancelClick}>
            Cancel
          </button>
          <button className={styles.closeBtn} onClick={onClick}>
            Logout
          </button>
        </div>
      </div>
    </div>
  );
};

export default React.memo(LogoutModal);
