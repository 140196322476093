import React from "react";
import styles from "./FinanceOverviewGameDetail.module.scss";
import { ReactComponent as IosIcon } from "../../img/FinaceOverview/profile_iOS.svg";
import { ReactComponent as AndroidIcon } from "../../img/FinaceOverview/profile_AOS.svg";
export default function FinanceOverviewGameDetail({ android, ios }) {
  return (
    <ul className={styles.container}>
      <li className={styles.ios}>
        <span></span>
        <span className={styles.os}>
          <IosIcon className={styles.icon} />
          <p className={styles.os_name}>iOS</p>
        </span>
        <p className={styles.revenue}>
          {ios.iosRevenue.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </p>
        <p className={styles.iap}>
          {ios.iosAppleIAP.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </p>
        <p className={styles.spend}>
          {ios.iosSpend.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </p>
        <p className={styles.profit}>
          {ios.iosProfit.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </p>
        <p className={styles.install}>
          {ios.iosInstalls.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </p>
      </li>
      <li className={styles.android}>
        <span></span>
        <span className={styles.os}>
          <AndroidIcon className={styles.icon} />
          <p className={styles.os_name}>Android</p>
        </span>
        <p className={styles.revenue}>
          {android.androidRevenue.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </p>
        <p className={styles.iap}>
          {android.androidGoogleIAP.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </p>
        <p className={styles.spend}>
          {android.androidSpend.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </p>
        <p className={styles.profit}>
          {android.androidProfit.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </p>
        <p className={styles.install}>
          {android.androidInstalls
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </p>
      </li>
    </ul>
  );
}

/*
 "IOS": {
      "iosRevenue": 139.12,
      "iosSpend": 2.4,
      "iosProfit": 136.72,
      "iosInstalls": 215,
      "iosGoogleIAP": 0,
      "iosAppleIAP": 0,
      "iosIAP": 0
    },
    "ANDROID": {
      "androidRevenue": 68.48,
      "androidSpend": 0,
      "androidProfit": 68.48,
      "androidInstalls": 292,
      "androidGoogleIAP": 0,
      "androidAppleIAP": 0,
      "androidIAP": 0
    }
*/
