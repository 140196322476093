import React from "react";
import styles from "./SecondDocModal.module.css";
import firstImg from "../../../../img/secondDocModal/first.png";
import secondImg from "../../../../img/secondDocModal/second.png";
import thirdImg from "../../../../img/secondDocModal/third.png";
import fourthImg from "../../../../img/secondDocModal/fourth.png";
import fifthImg from "../../../../img/secondDocModal/fifth.png";
import sixImg from "../../../../img/secondDocModal/sixth.png";
import seventhImg from "../../../../img/secondDocModal/seventh.png";
import eighthImg from "../../../../img/secondDocModal/eighth.png";
import ninthImg from "../../../../img/secondDocModal/ninth.png";

const SecondDocModal = ({ setShowSecondModal }) => {
  const onClick = () => {
    setShowSecondModal(false);
  };
  return (
    <div className={styles.container}>
      <div className={styles.modalContainer}>
        <div className={styles.registTitle}>
          <div className={styles.registTitleText}>
            How to register your App on Facebook for Developers and create an
            App ID
          </div>
        </div>
        <div className={styles.scroll}>
          <div className={styles.secondDocContainer}>
            <div className={styles.secondDocContext}>
              <div className={styles.one}>
                How to register your App on Facebook for Developers and create
                an App ID
                <div className={styles.one_one}>
                  If you have already registered your app on Facebook, please
                  move to part 2
                </div>
              </div>
              <div className={styles.partOne}>
                Part 1. Register your App on Facebook For Developers
              </div>
              <div className={styles.two}>
                <span>1. Go to Facebook for Developers </span>({" "}
                <a
                  className={styles.registLink}
                  href="https://developers.facebook.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://developers.facebook.com/
                </a>
                )
                <div>
                  Log-in to your Facebook for Developers account and click on{" "}
                  <span>"Get Started”.</span>
                </div>
                <img src={firstImg} alt="" className={styles.firstImg} />
              </div>
              <div className={styles.three}>
                <div>
                  2. Set up the E-mail verification and click{" "}
                  <span> “Confirm Email” </span>
                </div>
                <img src={secondImg} alt="" className={styles.secondImg} />
              </div>
              <div className={styles.four}>
                <div>
                  3. Select the <span>“Developer”</span> option and click on
                  <span>“Complete Registration”</span> to continue
                </div>

                <img src={thirdImg} alt="" className={styles.thirdImg} />
              </div>
              <div className={styles.five}>
                <div>4. Your account has been set up.</div>
                <div className={styles.five_one}>
                  Please follow the upcoming instructions on how to register
                  your App and receive an App ID
                </div>
                <img src={fourthImg} alt="" className={styles.fourthImg} />
              </div>
              <div className={styles.partTwo}>Part 2. Create your App ID</div>
              <div className={styles.six}>
                <div>
                  1. Go to Facebook for Developers (
                  <a
                    className={styles.registLink}
                    href="https://developers.facebook.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://developers.facebook.com/
                  </a>
                  )
                  <div className={styles.six_one}>
                    Log-in to your Facebook for Developers account and click on{" "}
                    <span>"My Apps”.</span>
                  </div>
                </div>
                <img src={fifthImg} alt="" className={styles.fifthImg} />
              </div>
              <div className={styles.seven}>
                <div className={styles.sevenTitle}>
                  2. Click on the <span>“Create App”</span> Button on the Upper
                  Right Side
                </div>
                <img src={sixImg} alt="" className={styles.sixImg} />
              </div>
              <div className={styles.eight}>
                <div className={styles.eightTitle}>
                  3. Select the app type <span>“Gaming”</span>
                  <div className={styles.eight_one}>
                    Click on the <span>“Next”</span> button
                  </div>
                </div>
                <img src={seventhImg} alt="" />
              </div>
              <div className={styles.nine}>
                <div className={styles.nineTitle}>
                  4. Fill in the form
                  <div className={styles.nineTitle_one}>
                    Make sure to select a <span>“Business Manager”</span>
                  </div>
                </div>
                <img src={eighthImg} alt="" />
              </div>
              <div className={styles.ten}>
                <div className={styles.tenTitle}>
                  5. Your App ID was created!
                </div>
                <img src={ninthImg} alt="" />
              </div>
              <button className={styles.closeBtn} onClick={onClick}>
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(SecondDocModal);
