export default function playTimeTotalData(maxData, playTime, day) {
  let installs = maxData?.map((data) => {
    let dt = new Date(new Date(data?.day).setHours(0, 0, 0, 0));
    if (
      new Date(dt.setDate(dt.getDate() + day)) <
      new Date(new Date().setHours(0, 0, 0, 0))
    ) {
      return Number(data?.installs);
    }
    return 0;
  });

  let average =
    playTime?.reduce((a, b) => a + b) / installs?.reduce((a, b) => a + b);

  return (average && `${Number(average).toFixed(0)}s`) || "-";
}
