export const faqList = [
  {
    id: 1,
    title: "Q: My App ID is not being recognized (!) on the dashboard.",
    contents:
      "A: Please try deleting, then copy-pasting your App ID into the field.",
  },
  {
    id: 2,
    title:
      "Q: The creative videos are taking very long to be uploaded. (10+ minutes)",
    contents:
      "A: The videos are directly uploaded to Facebook. Depending on the speed of Facebook’s servers, this process may take a while. If you cannot complete the step even after a significant while, please contact us.",
  },
  {
    id: 3,
    title:
      "Q: The videos can’t be uploaded although they’re in the right format/length.",
    contents:
      "A: Have you checked the ratio of your videos? Please make sure they’re in 1080 x 1350. You may have to need a converter to change the ratio and format to mp4.",
  },
  {
    id: 4,
    title: "Q: The submit button does not work after uploading videos.",
    contents:
      "A: Clicking the submit button will generate a campaign on Facebook. Depending on the activity of Facebook’s servers, this process may be delayed or not work sometimes. Please wait a moment and then try again.",
  },
  {
    id: 5,
    title:
      "Q: I cannot click on “In development - Live” on Facebook for Developers.",
    contents:
      "A: In order to let your game go live, you may have to confirm the ownership over your app. This is a process required by Facebook to ensure the app you’re registering really belongs to you. Please follow this guide provided by Facebook or the guide on our dashboard on the step “SDK” to confirm your ownership.",
    contents2: "We linked the guides by Facebook here:",
    developLink: [
      "https://www.facebook.com/business/help/718352112419262?id=211412110064838&helpref=search&sr=2&query=audience%20insights&__cft__[0]=AZVRGu-awyn5cUCeUXECMiVOB7lkEoZHrkcFW3mmi1vSGIuP4AvlwFeewXPCtr8rVYtgT6kD1U4RkqoTCn1iz-E0c9tE2Yfe8Wy63xRfpa3me5iOIw8B2mYNb-TPBVkhMpnqDPoTX43DRz6fuMaN54eu&__tn__=R]-R",
      "https://developers.facebook.com/docs/audience-network/optimization/best-practices/authorized-sellers-app-ads?locale=en_US&__cft__[0]=AZWCRtcrkaugWuc2B0iWKw-3A6n5QjcLa4bLoLqYW83-2LS0Ou-FyNnaiQBAtSaGOOratryCSh3HbfYdDdXqbW8cYFGqzuY3dzRzINQ_csRmAFya9tNqR0xgvhYdbEBncWKK4-AT9P3vSzAkNdMcj0Sd6-6HxxMoHs5hDQhyKI-OsVXt-UaIS1O2vpGvJVhBhuM&__tn__=R]-R",
    ],
  },
  {
    id: 6,
    title:
      "Q: During the settings process on Facebook, field information such as the Privacy Policy Link, Package Name or others won’t save.",
    contents:
      "A: Being unable to save field information usually is a Facebook side transfer error. Please try to refresh the page and reenter the information. If it still doesn’t save, please wait a few minutes, then try again.",
  },
  {
    id: 7,
    title:
      "Q: On the dashboard, it says that my ad got disapproved. What should I do?",
    contents:
      "A: During ad submission, creatives go through an automated security check. During this process, the AI might wrongly flag the ad as inappropriate. In this case, we request a review of the ad in which this problem is usually resolved. This is a routine procedure and might take up to a few hours. Due to the buffer before the campaign starts, your results will not be affected by this. In the unlucky case that several ads get rejected by several reviews, we will contact you on how to proceed.",
  },
  {
    id: 8,
    title: "Q: My game is not showing under “my games” in the dashboard. ",
    contents:
      "A: Please make sure that you register your game under the category “gaming” on Facebook for Developers. If the game is registered under a different app type, your game will not be able to be tested and thus won’t show up under “my games” in the dashboard. ",
  },
  {
    id: 9,
    title: "Q: I cannot see the CPI on the Dashboard.",
    contents:
      "A: The dashboard is refreshed once every 24 hours to show your current progress. If CPI doesn’t show even after 24 hours, there may be a problem with the SDK integration. We will contact you to look into the issue.",
  },
  {
    id: 10,
    title:
      "Q: Facebook asks me to confirm my app ownership. I have created an App-Ads.txt page and submitted it to the Google Play Console, but Facebook still does not accept the ownership. What can I do?",
    contents:
      "A: If you have followed all the steps of our tutorial on how to create and submit the app-ads.txt page, there might be a problem with your Facebook for Business account. Please try to move your ads to the Monetization Manager and confirm your ownership there according to our guide. You may follow this guide provided by Facebook. ",
    monetizationLink: [
      "https://www.facebook.com/business/help/347057685756076?id=211412110064838",
    ],
  },
];
