import React, { useEffect } from "react";
import imageCompression from "browser-image-compression";
import Dropzone from "react-dropzone";
import styles from "./ProfileImgEdit.module.scss";
import pencil from "../../img/pencil/pencil.png";
const ProfileImgEdit = ({ profileImg, setProfileImg, user }) => {
  useEffect(() => {
    actionImgCompress(profileImg);
  }, [profileImg]);
  const actionImgCompress = async (fileSrc) => {
    const options = {
      maxSizeMB: 0.1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(fileSrc[0], options);
      const reader = new FileReader();
      reader.readAsDataURL(compressedFile);
      reader.onloadend = () => {
        const base64Data = reader.result;
        setProfileImg(base64Data);
      };
    } catch (err) {}
  };

  return (
    <div className={styles.container}>
      <Dropzone onDrop={(acceptedFiles) => setProfileImg(acceptedFiles)}>
        {({ getRootProps, getInputProps }) => (
          <section className={styles.dragAndDropArea}>
            <div {...getRootProps()} className={styles.dragAndDropBox}>
              <input {...getInputProps()} />
              <img
                src={profileImg ? profileImg : user?.data?.profileImg}
                alt=""
                className={styles.profileImg}
              />
              <div className={styles.pencil}>
                <img src={pencil} alt="pencil" />
              </div>
            </div>
          </section>
        )}
      </Dropzone>
    </div>
  );
};

export default ProfileImgEdit;
