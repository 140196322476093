import React from "react";
import styles from "./InvoiceBankInfo.module.scss";
export default function InvoiceBankInfo({
  bankInfo,
  onInfoChange,
  invoiceUser,
  isPayment,
}) {
  return (
    <section className={styles.container}>
      <div>
        <span>
          <p>Bank Account Name</p>
          <p>*</p>
        </span>
        <input
          type="text"
          id="bankAccountName"
          placeholder="Bank Account Name"
          value={bankInfo.payment.accountName}
          onChange={onInfoChange}
          disabled={invoiceUser.role === "EMPLOYEE" || isPayment ? true : false}
        />
      </div>
      <div>
        <span>
          <p>Account Number(IBAN Number)</p>
          <p>*</p>
        </span>
        <input
          type="text"
          id="accountNumber"
          placeholder="Account Number(IBAN Number)"
          value={bankInfo.payment.accountNumber}
          onChange={onInfoChange}
          disabled={invoiceUser.role === "EMPLOYEE" || isPayment ? true : false}
        />
      </div>
      <div>
        <span>
          <p>Bank Name</p>
          <p>*</p>
        </span>
        <input
          type="text"
          id="bankName"
          placeholder="Bank Name"
          value={bankInfo.payment.bankName}
          onChange={onInfoChange}
          disabled={invoiceUser.role === "EMPLOYEE" || isPayment ? true : false}
        />
      </div>
      <div>
        <span>
          <p>Bank Address</p>
          <p>*</p>
        </span>
        <input
          type="text"
          id="bankAddress"
          placeholder="Business Address"
          value={bankInfo.payment.bankAddress}
          onChange={onInfoChange}
          disabled={invoiceUser.role === "EMPLOYEE" || isPayment ? true : false}
        />
      </div>
      <div>
        <span>
          <p>Swift Code</p>
          <p>*</p>
        </span>
        <input
          type="text"
          id="swiftCode"
          placeholder="Swift Code"
          value={bankInfo.payment.swiftCode}
          onChange={onInfoChange}
          disabled={invoiceUser.role === "EMPLOYEE" || isPayment ? true : false}
        />
      </div>
      <div>
        <span>
          <p>Branch Code</p>
        </span>
        <input
          type="text"
          id="branchCode"
          placeholder="Branch Code"
          value={bankInfo.payment.branchCode ?? ""}
          onChange={onInfoChange}
          disabled={invoiceUser.role === "EMPLOYEE" || isPayment ? true : false}
        />
      </div>
      <div>
        <span>
          <p>IFSC Code</p>
        </span>
        <input
          type="text"
          id="ifscCode"
          placeholder="IFSC Code"
          value={bankInfo.payment.ifscCode ?? ""}
          onChange={onInfoChange}
          disabled={invoiceUser.role === "EMPLOYEE" || isPayment ? true : false}
        />
      </div>
      <div>
        <span>
          <p>Routing Number</p>
        </span>
        <input
          type="text"
          id="routingNumber"
          placeholder="Routing Number"
          value={bankInfo.payment.routingNumber}
          onChange={onInfoChange}
          disabled={invoiceUser.role === "EMPLOYEE" || isPayment ? true : false}
        />
      </div>
    </section>
  );
}
