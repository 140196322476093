import moment from "moment";
import React from "react";
import styles from "./MonthlyNetRevenue.module.scss";
import { ReactComponent as InfoIcon } from "../../img/Invoice/info.svg";
export default function MonthlyNetRevenue({ data, date }) {
  return (
    <section className={styles.contaienr}>
      <p className={styles.activity_date}>
        {moment(date).subtract(2, "month").format("MMM. YYYY")} Activity*
      </p>
      <div className={styles.gross}>
        <span>
          <p>Monthly Gross Revenue</p>
          <InfoIcon className={styles.info_icon} />
          <section className={styles.popup_message}>
            <p>
              Ad Revenue:&nbsp;
              {(
                data.monthlyGrossRevenue -
                (data.googleIap + data.appleIap)
              ).toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </p>
            <p>
              IAP Revenue:&nbsp;
              {(data.googleIap + data.appleIap).toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </p>
          </section>
        </span>
        <p>
          {data.monthlyGrossRevenue.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </p>
      </div>
      <div className={styles.spend}>
        <p>Monthly Spend</p>
        <p>
          {data.monthlySpend.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </p>
      </div>
      <div className={styles.third_party}>
        <p>Tool Costs</p>
        <p>
          {data.thirdPartCosts.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </p>
      </div>
      {data.detailOthers.length ? (
        data.detailOthers.map((item) => (
          <div className={styles.others}>
            <p>{item.description}</p>
            <p>{item.prize}</p>
          </div>
        ))
      ) : (
        <></>
      )}

      <div className={styles.monthly}>
        <p>Monthly Net Revenue</p>
        <p>
          {data.totalNetRevenue.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </p>
      </div>
    </section>
  );
}
