import React, { useState, useEffect } from "react";
import styles from "./Progress.module.scss";
import AdsList from "../../components/AdsList/AdsList";
import Filter from "../../components/Filter/Filter";
import NotApp from "../../components/NotApp/NotApp";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import REGA from "react-ga";
const Progress = ({ adData, user, progressList, adsService }) => {
  const [searchValue, setSearchValue] = useState("");
  const location = useLocation();
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_ID);
    ReactGA.send({
      hitType: "pageview",
      path: location.pathname,
      location: location.pathname,
      title: "List",
    });
  }, [location.pathname]);
  useEffect(() => {
    const pathName = location.pathname;
    REGA.initialize(process.env.REACT_APP_TRACKING_ID);
    REGA.set({ page: "testList" });
    REGA.pageview(pathName, ["testList"]);
  }, [location.pathname]);

  return (
    <div className={styles.container}>
      <div className={styles.filterAndButton}>
        {progressList?.length ? (
          <>
            <Filter setSearchValue={setSearchValue} />
          </>
        ) : (
          <></>
        )}
      </div>

      {progressList?.length ? (
        <AdsList
          adData={adData}
          adsService={adsService}
          user={user}
          searchValue={searchValue}
          list={progressList}
          pathName={"progress"}
        />
      ) : (
        <NotApp />
      )}
    </div>
  );
};

export default Progress;
