import { memo } from "react";
import {
  createContext,
  createRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import Login from "../pages/Login/Login";
import * as cookie from "../db/cookies";
import { recoilPersist } from "recoil-persist";
import { atom, useRecoilState, RecoilEnv } from "recoil";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
const AuthContext = createContext({});
const contextRef = createRef();
RecoilEnv.RECOIL_DUPLICATE_ATOM_KEY_CHECKING_ENABLED = false;
export const AuthProvider = memo(
  ({ authService, authErrorEventBus, children }) => {
    const [user, setUser] = useState(undefined);
    const [isSignUp, setIsSignUp] = useState(false);
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { persistAtom } = recoilPersist({
      key: "invoiceUserInfo",
      storage: localStorage,
    });
    const userState = atom({
      key: "invoiceUserInfo",
      default: null,
      effects_UNSTABLE: [persistAtom],
    });
    const [invoiceUser, setInvoiceUser] = useRecoilState(userState);

    useImperativeHandle(contextRef, () => (user ? user.token : undefined));

    useEffect(() => {
      authErrorEventBus.listen((err) => {
        console.log(err);
        setUser(undefined);
      });
    }, [authErrorEventBus]);

    useEffect(() => {
      authService.me().then(setUser).catch(console.error);
    }, [authService]);
    const signUp = useCallback(
      async (
        email,
        password,
        studioName,
        teamSize,
        portfolioLink,
        profileImg,
        news
      ) =>
        authService
          .signup(
            email,
            password,
            studioName,
            teamSize,
            portfolioLink,
            profileImg,
            news
          )
          .then((user) => {
            setUser(user);
          })
          .catch((e) => e),
      [authService]
    );

    const logIn = useCallback(
      async (email, password) =>
        authService
          .login(email, password)
          .then((user) => setUser(user))
          .then(
            () =>
              cookie.getCookie("DA_token") && (window.location.pathname = "/")
          ),
      [authService]
    );

    const password = useCallback(
      async (email, password) => authService.resetPassword(email, password),
      [authService]
    );
    const modifingInfo = useCallback(
      async (id, password, profileImg, portfolioLink, teamSize) =>
        authService.modifingInfo(
          id,
          password,
          profileImg,
          portfolioLink,
          teamSize
        ),
      [authService]
    );
    const sendPassword = useCallback(
      async (email) => authService.sendPassword(email),
      [authService]
    );

    const invoiceLogin = useCallback(
      (email, password) => {
        return authService
          .invoiceLogin({ email, password, isAdmin: false })
          .then((res) => {
            if (res?.status === 201) {
              cookie.setCookie("Authentication", res.data.accessToken);
              setInvoiceUser(res.data);
              return;
            } else {
              alert(res.data.message);
              return;
            }
          });
      },
      [authService, setInvoiceUser]
    );

    const logout = useCallback(
      async () =>
        await authService
          .logout()
          .then(() => {
            setUser(undefined);
            cookie.deleteCookie("Authentication");
            cookie.deleteCookie("DA_token");
            localStorage.removeItem("invoiceUserInfo");
            return;
          })
          .then(() => (window.location.pathname = "/login")),
      [authService]
    );

    const context = useMemo(
      () => ({
        user,
        signUp,
        logIn,
        logout,
        password,
        modifingInfo,
        sendPassword,
        invoiceLogin,
        invoiceUser,
      }),
      [
        user,
        signUp,
        logIn,
        logout,
        password,
        modifingInfo,
        sendPassword,
        invoiceLogin,
        invoiceUser,
      ]
    );

    useEffect(() => {
      if (!cookie.getCookie("DA_token")) {
        navigate("/login", { state: pathname });
      } else if (pathname === "/login" && cookie.getCookie("DA_token")) {
        navigate("/", { state: pathname });
      }
    }, [navigate, pathname]);
    return (
      <AuthContext.Provider value={context}>
        {user ? (
          children
        ) : (
          <Routes>
            <Route
              path="/login"
              element={
                <Login
                  onSignUp={signUp}
                  onLogin={logIn}
                  onResetPassword={password}
                  sendPassword={sendPassword}
                  setIsSignUp={setIsSignUp}
                />
              }
            />
          </Routes>
        )}
      </AuthContext.Provider>
    );
  }
);

export class AuthErrorEventBus {
  listen(callback) {
    this.callback = callback;
  }
  notify(error) {
    console.log(error);
    this.callback(error);
  }
}

export default AuthContext;
export const fetchToken = () => contextRef.current;
export const useAuth = () => useContext(AuthContext);
