import React from "react";
import styles from "./VideoModal.module.css";
import firstImg from "../../../../img/videoGuide/1.png";
import secondImg from "../../../../img/videoGuide/2.png";
import thirdImg from "../../../../img/videoGuide/3.png";
import fourthImg from "../../../../img/videoGuide/4.png";
import fifthImg from "../../../../img/videoGuide/5.png";

const VideoModal = ({ setVideoGuide }) => {
  const onClick = () => {
    setVideoGuide(false);
  };
  return (
    <div className={styles.container}>
      <div className={styles.modalContainer}>
        <div className={styles.videoTitle}>
          <div className={styles.videoTitleText}>
            How to Create Ads in Unity
          </div>
        </div>
        <div className={styles.scroll}>
          <div className={styles.firstContainer}>
            <div className={styles.firstContext}>
              <div className={styles.firstTitle}>
                How to Create Ads in Unity
              </div>
              <div className={styles.firstSubTitle}>
                <div>If you use a version of Unity from 2019 or newer, </div>
                <div>
                  you can use the Recorder Function in Unity to record videos.
                </div>
              </div>
              <div className={styles.recommendContainer}>
                <div className={styles.recommendTitle}>
                  If you are using an older version of Unity, you may use any of
                  these programs to record your videos.{" "}
                  <span>(*Recommendations)</span>
                </div>
                <ul className={styles.toolList}>
                  <li>Video Pad</li>
                  <li>Shot cut</li>
                  <li>EZGIF</li>
                  <li>Adobe Premier *</li>
                </ul>
              </div>
              <div className={styles.one}>
                <div className={styles.oneTitle}>
                  1. Click on ‘Window’ and select ‘Package Manager’.
                </div>
                <img src={firstImg} alt="" />
              </div>
              <div className={styles.two}>
                <div className={styles.twoTitle}>
                  2. Click the <span>‘Package’</span> tab located at the top
                  left of the newly opened window, select{" "}
                  <span>‘Unity Registry’</span>, and enter{" "}
                  <span>‘Unity Record’</span> to install.
                </div>
                <img src={secondImg} alt="" />
              </div>
              <div className={styles.three}>
                <div className={styles.threeTitle}>
                  3. Select{" "}
                  <span>‘Window - General - Recorder - Recorder Window’</span>
                </div>
                <img src={thirdImg} alt="" />
              </div>
              <div className={styles.detailContainer}>
                <div className={styles.detailTitle}>Detailed Settings</div>
                <div className={styles.detailSubTitle}>
                  You can also create MondayOFF Game object to the current
                  working scene by{" "}
                  <span>
                    selecting MondayOFF {">"} Create MondayOFF Game Object
                  </span>{" "}
                  on the menu
                </div>
                <ol className={styles.processList}>
                  <li>Click on Add Recorder and select Movie</li>
                  <li>Change the Target FPS to 60</li>
                  <li>
                    Select 'Resolution - Custom' and change the resolution to
                    1080x1350
                  </li>
                  <li>Select the Storage Location</li>
                  <li>Click on 'START RECORDING'</li>
                </ol>
                <img src={fourthImg} alt="" />
              </div>
              <div className={styles.four}>
                <div className={styles.fourTitle}>
                  4. Complete the Ads production.
                </div>
                <ul className={styles.makingList}>
                  <li>
                    - Record 4videos about each 15 - 20 sec Ads for upload.
                  </li>
                  <li>
                    - Click on Play button if you want to finish to record.
                  </li>
                </ul>
                <div className={styles.four_one}>
                  The Creative is saved in the folder you select
                </div>
                <img src={fifthImg} alt="" />
              </div>

              <button className={styles.closeBtn} onClick={onClick}>
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(VideoModal);
