import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import FirstProcess from "../../components/FirstProcess/FirstProcess";
import ReactGA from "react-ga4";
import styles from "./Process.module.scss";
import getGA from "../../service/ga";
const Process = ({ userId, authService }) => {
  const location = useLocation();
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_ID);
    ReactGA.send({
      hitType: "pageview",
      path: location.pathname,
      location: location.pathname,
      title: "ProcessInfo",
    });
    getGA();
  }, [location.pathname]);
  return (
    <>
      <div className={styles.container}>
        <FirstProcess userId={userId} authService={authService} />
      </div>
    </>
  );
};

export default Process;
