import React, { useState } from "react";
import classNames from "classnames";
import Dropzone from "react-dropzone";
import styles from "./VideoUploadBox.module.css";
import plus from "../../../img/plus/gameIconPlus.png";
import xIcon from "../../../img/xIcon/xIcon.png";
import greenCheck from "../../../img/check/greenCheck.png";
import videoIcon from "../../../img/videoIcon/videoIcon.png";
import Loading from "../../../common/Loading";
import Modal from "../../../common/Modal/Modal";
import VideoModal from "./VideoModal/VideoModal";
import CpiVideoGuide from "./CpiVideoGuide/CpiVideoGuide";
import blackCheck from "../../../img/videoIcon/blackCheck.png";
const VideoUploadBox = ({
  metaService,
  firstVideoId,
  setFirstVideoId,
  secondVideoId,
  setSecondVideoId,
  thirdVideoId,
  setThirdVideoId,
  fourthVideoId,
  setFourthVideoId,
  cpiVideoGuide,
  setCpiVideoGuide,
}) => {
  const [firstLoading, setFirstLoading] = useState(false);
  const [secondLoading, setSecondLoading] = useState(false);
  const [thirdLoading, setThirdLoading] = useState(false);
  const [fourthLoading, setFourthLoading] = useState(false);
  const [firstRemoveLoading, setFirstRemoveLoading] = useState(false);
  const [secondRemoveLoading, setSecondRemoveLoading] = useState(false);
  const [thirdRemoveLoading, setThirdRemoveLoading] = useState(false);
  const [fourthRemoveLoading, setFourthRemoveLoading] = useState(false);
  const [firstVideo, setFirstVideo] = useState();
  const [secondVideo, setSecondVideo] = useState();
  const [thirdVideo, setThirdVideo] = useState();
  const [fourthVideo, setFourthVideo] = useState();
  const [firstPercent, setFirstPercent] = useState(0);
  const [alertMessage, setAlertMessage] = useState("");
  const [isAlertModal, setIsAlertMotal] = useState(false);
  const [count, setCount] = useState(0);
  const [videoGuide, setVideoGuide] = useState(false);

  let formData = new FormData();

  const dropHandle = (files) => {
    let video = document.createElement("video");
    let gameName = JSON.parse(localStorage.getItem("app_data"));

    video.onloadedmetadata = () => {
      if (video.videoWidth !== 1080 && video.videoHeight !== 1350) {
        setAlertMessage("Please check your video’s ratio");
        setIsAlertMotal(true);
      } else if (!fourthVideo) {
        formData.append(gameName.name, files[0]);

        if (count === 0) {
          setFirstLoading(true);
          setFirstVideo({
            id: "1",
            name: `${files[0].name.split(".")[0]}-${count}.mp4`,
            size: `${(files[0].size / 1024 / 1024).toFixed(1)}MB`,
          });

          setCount(count + 1);
        } else if (count === 1) {
          setSecondLoading(true);
          setSecondVideo({
            id: "2",
            name: `${files[0].name.split(".")[0]}-${count}.mp4`,
            size: `${(files[0].size / 1024 / 1024).toFixed(1)}MB`,
          });

          setCount(count + 1);
        } else if (count === 2) {
          setThirdLoading(true);
          setThirdVideo({
            id: "3",
            name: `${files[0].name.split(".")[0]}-${count}.mp4`,
            size: `${(files[0].size / 1024 / 1024).toFixed(1)}MB`,
          });

          setCount(count + 1);
        } else if (count === 3) {
          setFourthLoading(true);
          setFourthVideo({
            id: "4",
            name: `${files[0].name.split(".")[0]}-${count}.mp4`,
            size: `${(files[0].size / 1024 / 1024).toFixed(1)}MB`,
          });

          setCount(count + 1);
        }

        metaService
          .uploadVideo(formData, gameName.name, count) //
          .then((res) => {
            if (res.status === 200) {
              if (count === 0) {
                setFirstVideoId({
                  id: "1",
                  name: `${files[0].name.split(".")[0]}-${count}.mp4`,
                  size: `${(files[0].size / 1024 / 1024).toFixed(1)}MB`,
                  videoId: res?.data?.id,
                });
                setFirstPercent(100);
                setFirstLoading(false);
              } else if (count === 1) {
                setSecondVideoId({
                  id: "2",
                  name: `${files[0].name.split(".")[0]}-${count}.mp4`,
                  size: `${(files[0].size / 1024 / 1024).toFixed(1)}MB`,
                  videoId: res?.data?.id,
                });

                setSecondLoading(false);
              } else if (count === 2) {
                setThirdVideoId({
                  id: "3",
                  name: `${files[0].name.split(".")[0]}-${count}.mp4`,
                  size: `${(files[0].size / 1024 / 1024).toFixed(1)}MB`,
                  videoId: res?.data?.id,
                });

                setThirdLoading(false);
              } else if (count === 3) {
                setFourthVideoId({
                  id: "4",
                  name: `${files[0].name.split(".")[0]}-${count}.mp4`,
                  size: `${(files[0].size / 1024 / 1024).toFixed(1)}MB`,
                  videoId: res?.data?.id,
                });

                setFourthLoading(false);
              }
            }
          });
      } else if (fourthVideo.length === 1)
        console.log(
          "width: " + video.videoWidth + "\n" + "height: " + video.videoHeight
        );
    };

    video.src = URL.createObjectURL(files[0]);
  };

  const onRemove = async (id) => {
    let gameName = JSON.parse(localStorage.getItem("app_data"));
    if (id === "1") {
      let videoName = firstVideo.name;
      let metaVideoId = firstVideoId.videoId;
      setFirstRemoveLoading(true);
      return await metaService
        .deleteVideo(gameName.name, videoName, metaVideoId)
        .then(() => {
          setCount(count - 1);
          setFirstRemoveLoading(false);
        })
        .then(() => setFirstVideo())
        .then(() => setFirstVideoId());
    } else if (id === "2") {
      let videoName = secondVideo.name;
      let metaVideoId = secondVideoId.videoId;
      setSecondRemoveLoading(true);
      return await metaService
        .deleteVideo(gameName.name, videoName, metaVideoId)
        .then(() => {
          setCount(count - 1);
          setSecondRemoveLoading(false);
        })
        .then(() => setSecondVideo())
        .then(() => setSecondVideoId());
    } else if (id === "3") {
      let videoName = thirdVideo.name;
      let metaVideoId = thirdVideoId.videoId;
      setThirdRemoveLoading(true);
      return await metaService
        .deleteVideo(gameName.name, videoName, metaVideoId)
        .then(() => {
          setCount(count - 1);
          setThirdRemoveLoading(false);
        })
        .then(() => setThirdVideo())
        .then(() => setThirdVideoId());
    } else if (id === "4") {
      let videoName = fourthVideo.name;
      let metaVideoId = fourthVideoId.videoId;
      setFourthRemoveLoading(true);
      return await metaService
        .deleteVideo(gameName.name, videoName, metaVideoId)
        .then(() => {
          setCount(count - 1);
          setFourthRemoveLoading(false);
        })
        .then(() => setFourthVideo())
        .then(() => setFourthVideoId());
    }
  };

  return (
    <div className={styles.container}>
      {videoGuide && <VideoModal setVideoGuide={setVideoGuide} />}
      {cpiVideoGuide && <CpiVideoGuide setCpiVideoGuide={setCpiVideoGuide} />}
      {isAlertModal && (
        <Modal message={alertMessage} setAlertModal={setIsAlertMotal} />
      )}
      <div className={styles.videoGuideContainer}>
        <div className={styles.settingTitle}>
          Facebook Marketing Video requirements :
        </div>
        <ul className={styles.videoRatio}>
          <li>
            Video Ratio : <span>1080 x 1350</span>
          </li>
          <li>
            <span>4 videos</span> each <span>15 - 20sec.</span>
          </li>
        </ul>
        <div className={styles.videoGuide} onClick={() => setVideoGuide(true)}>
          How to make creatives on Unity
        </div>
      </div>

      <Dropzone onDrop={dropHandle}>
        {({ getRootProps, getInputProps }) => (
          <>
            <section className={styles.dragAndDropArea}>
              <div
                {...getRootProps()}
                className={classNames(
                  !firstVideo && !secondVideo && !thirdVideo && !fourthVideo
                    ? styles.dragAndDropBox
                    : styles.notNullVideo
                )}
              >
                <input
                  {...getInputProps()}
                  multiple={true}
                  type="file"
                  name="files"
                />
                <img
                  src={videoIcon}
                  alt=""
                  className={classNames(
                    !firstVideo && !secondVideo && !thirdVideo && !fourthVideo
                      ? styles.videoIcon
                      : styles.noneIcon
                  )}
                />
                {firstVideo && secondVideo && thirdVideo && fourthVideo ? (
                  <div className={styles.uploadCompletion}>
                    <div className={styles.uploadText}>
                      {firstLoading ||
                      secondLoading ||
                      thirdLoading ||
                      fourthLoading ? (
                        <>
                          <Loading
                            color={"#0EAC4BBF"}
                            width={"20px"}
                            height={"20px"}
                          />
                          <div className={styles.uploadingText}>
                            Uploading...
                          </div>
                        </>
                      ) : (
                        <>
                          <img
                            src={blackCheck}
                            alt=""
                            className={styles.blackCheck}
                          />
                          <div>4 videos have been uploaded.</div>
                        </>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className={styles.videoBoxText}>
                    <img src={plus} alt="plus" className={styles.plus} />
                    <p className={styles.first}>Add file </p>
                    <p className={styles.second}>or</p>
                    <p className={styles.third}>drop file here</p>
                  </div>
                )}
              </div>
              <div
                className={classNames(
                  !firstVideo && !secondVideo && !thirdVideo && !fourthVideo
                    ? styles.notVideo
                    : styles.videoList
                )}
              >
                {firstVideo && (
                  <div className={styles.eachVideo} key={firstVideo?.id}>
                    <div className={styles.videoName}>
                      <div>{firstVideo?.name}</div>
                      {firstLoading || firstRemoveLoading ? (
                        <div className={styles.spinner}>
                          <Loading
                            color={"#0EAC4BBF"}
                            width={"20px"}
                            height={"20px"}
                          />
                        </div>
                      ) : null}

                      {!firstLoading && !firstRemoveLoading ? (
                        <img src={greenCheck} alt="greenCheck" />
                      ) : null}
                    </div>
                    <div className={styles.videoSize}>
                      <div>{firstVideo?.size} </div>

                      {!firstLoading ? (
                        <img
                          src={xIcon}
                          alt="xIcon"
                          onClick={() => onRemove(firstVideo?.id)}
                          className={styles.xIcon}
                        />
                      ) : null}
                    </div>
                  </div>
                )}

                {secondVideo && (
                  <div className={styles.eachVideo} key={secondVideo?.id}>
                    <div className={styles.videoName}>
                      <div>{secondVideo?.name}</div>
                      {secondLoading || secondRemoveLoading ? (
                        <div className={styles.spinner}>
                          <Loading
                            color={"#0EAC4BBF"}
                            width={"20px"}
                            height={"20px"}
                          />
                        </div>
                      ) : null}
                      {!secondLoading && !secondRemoveLoading ? (
                        <img src={greenCheck} alt="greenCheck" />
                      ) : null}
                    </div>
                    <div className={styles.videoSize}>
                      <div>{secondVideo?.size} </div>
                      {!secondLoading ? (
                        <img
                          src={xIcon}
                          alt="xIcon"
                          onClick={() => onRemove(secondVideo?.id)}
                          className={styles.xIcon}
                        />
                      ) : null}
                    </div>
                  </div>
                )}

                {thirdVideo && (
                  <div className={styles.eachVideo} key={thirdVideo?.id}>
                    <div className={styles.videoName}>
                      <div>{thirdVideo?.name}</div>
                      {thirdLoading || thirdRemoveLoading ? (
                        <div className={styles.spinner}>
                          <Loading
                            color={"#0EAC4BBF"}
                            width={"20px"}
                            height={"20px"}
                          />
                        </div>
                      ) : null}
                      {!thirdLoading && !thirdRemoveLoading ? (
                        <img src={greenCheck} alt="greenCheck" />
                      ) : null}
                    </div>
                    <div className={styles.videoSize}>
                      <div>{thirdVideo?.size} </div>
                      {!thirdLoading ? (
                        <img
                          src={xIcon}
                          alt="xIcon"
                          onClick={() =>
                            onRemove(thirdVideo?.id, thirdVideo?.videoId)
                          }
                          className={styles.xIcon}
                        />
                      ) : null}
                    </div>
                  </div>
                )}

                {fourthVideo && (
                  <div className={styles.eachVideo} key={fourthVideo?.id}>
                    <div className={styles.videoName}>
                      <div>{fourthVideo?.name}</div>
                      {fourthLoading || fourthRemoveLoading ? (
                        <div className={styles.spinner}>
                          <Loading
                            color={"#0EAC4BBF"}
                            width={"20px"}
                            height={"20px"}
                          />
                        </div>
                      ) : null}
                      {!fourthLoading && !fourthRemoveLoading ? (
                        <img src={greenCheck} alt="greenCheck" />
                      ) : null}
                    </div>
                    <div className={styles.videoSize}>
                      <div>{fourthVideo?.size} </div>
                      {!fourthLoading ? (
                        <img
                          src={xIcon}
                          alt="xIcon"
                          onClick={() => onRemove(fourthVideo?.id)}
                          className={styles.xIcon}
                        />
                      ) : null}
                    </div>
                  </div>
                )}
              </div>
            </section>
          </>
        )}
      </Dropzone>
    </div>
  );
};

export default VideoUploadBox;
