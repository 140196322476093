import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./FirstProcess.module.scss";
import img from "../../img/process/firstProcess.png";
import progressBar from "../../img/process/firstProgressBar.png";
const FirstProcess = ({ authService }) => {
  const navigate = useNavigate();
  const onClick = (e) => {
    authService
      .checkTurorial()
      .then((data) => data)
      .then(() => navigate("/secondprocess"))
      .catch(console.error);
    //
  };
  return (
    <div>
      <div className={styles.container}>
        <div className={styles.title}>
          Test your game in just three simple steps!
        </div>
        <div className={styles.processText}>
          1. Setup your App on the Facebook for Developers Page.
        </div>
        <img src={img} alt="" className={styles.img} />
        <img src={progressBar} alt="" className={styles.progressBar} />
        <button className={styles.nextBtn} onClick={onClick}>
          Next
        </button>
      </div>
    </div>
  );
};

export default FirstProcess;
