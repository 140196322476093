import React, { useState, useEffect } from "react";
import styles from "./Complete.module.scss";
import AdsList from "../../components/AdsList/AdsList";
import Filter from "../../components/Filter/Filter";

import NotApp from "../../components/NotApp/NotApp";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import REGA from "react-ga";
import CampaignContext from "../../Context/CampaignContext";
const Complete = ({ adData, user, completeList, adsService }) => {
  const [searchValue, setSearchValue] = useState("");
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_ID);
    ReactGA.send({
      hitType: "pageview",
      path: location.pathname,
      location: location.pathname,
      title: "List",
    });
  }, [location.pathname]);
  useEffect(() => {
    const pathName = location.pathname;
    REGA.initialize(process.env.REACT_APP_TRACKING_ID);
    REGA.set({ page: "testList" });
    REGA.pageview(pathName, ["testList"]);
  }, [location.pathname]);
  return (
    <div className={styles.container}>
      <div className={styles.filterAndButton}>
        {completeList?.length ? (
          <Filter setSearchValue={setSearchValue} />
        ) : (
          <></>
        )}
      </div>

      {completeList?.length ? (
        <AdsList
          adData={adData}
          adsService={adsService}
          user={user}
          searchValue={searchValue}
          list={completeList}
          pathName={"complete"}
        />
      ) : (
        <NotApp />
      )}
    </div>
  );
};

export default Complete;
