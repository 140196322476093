import React, { useState } from "react";
import { ReactComponent as CheckIcon } from "../../img/FinaceOverview/check.svg";
import { ReactComponent as ExpandIcon } from "../../img/FinaceOverview/expand.svg";
import FinanceOverviewGameDetail from "../FinanceOverviewGameDetail/FinanceOverviewGameDetail";

import styles from "./FinanceOverviewGameList.module.scss";
export default function FinanceOverviewGameList({
  game,
  checkItem,
  onSingleCheck,
}) {
  const [isExpandClick, setIsExpandClick] = useState(false);
  return (
    <>
      <li className={styles.container}>
        <span className={styles.check}>
          <input
            className={styles.game_click_input}
            type="checkbox"
            id={`${game.gameName}_check`}
            onChange={(e) => onSingleCheck(e, game.everyDayGameId)}
            checked={checkItem.includes(game.everyDayGameId) ? true : false}
          />
          <label
            className={styles.check_box}
            htmlFor={`${game.gameName}_check`}
          >
            <CheckIcon className={styles.icon} />
          </label>
        </span>
        <span className={styles.name}>
          <ExpandIcon
            className={styles.icon}
            onClick={() => setIsExpandClick((prev) => !prev)}
            data-content={isExpandClick && "active"}
          />
          <div className={styles.game_img} />
          <p>{game.gameName}</p>
        </span>
        <p className={styles.game_total_revenue}>
          {game.totalRevenue.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </p>
        <p className={styles.game_total_iap}>
          {game.totalIAP.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </p>
        <p className={styles.game_total_spend}>
          {game.totalSpend.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </p>
        <p className={styles.game_total_profit}>
          {game.totalProfit.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </p>
        <p className={styles.game_total_install}>
          {game.totalInstalls.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </p>
      </li>
      {isExpandClick && (
        <ul className={styles.game_detail}>
          <FinanceOverviewGameDetail android={game.ANDROID} ios={game.IOS} />
        </ul>
      )}
    </>
  );
}

/*
[
  {
    "gameName": "Fill The Region",
    "everyDayGameId": 5,
    "totalRevenue": 207.6,
    "totalSpend": 2.4,
    "totalProfit": 205.2,
    "totalInstalls": 507,
    "totalGoogleIAP": 0,
    "totalAppleIAP": 0,
    "totalIAP": 0,
    "IOS": {
      "iosRevenue": 139.12,
      "iosSpend": 2.4,
      "iosProfit": 136.72,
      "iosInstalls": 215,
      "iosGoogleIAP": 0,
      "iosAppleIAP": 0,
      "iosIAP": 0
    },
    "ANDROID": {
      "androidRevenue": 68.48,
      "androidSpend": 0,
      "androidProfit": 68.48,
      "androidInstalls": 292,
      "androidGoogleIAP": 0,
      "androidAppleIAP": 0,
      "androidIAP": 0
    }
  },
 
]
*/
