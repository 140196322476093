import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import getGA from "../../service/ga";
import styles from "./SetManager.module.css";
import copyIcon from "../../img/addNewApp/copyBtn.png";
import bangIcon from "../../img/setmanager/bangIcon.png";
import FirstDocModal from "./FirstDocModal/FirstDocModal";
import SecondDocModal from "./SecondDocModal/SecondDocModal";
import questionMark from "../../img/addNewApp/questionMark.png";
import VideoModal from "../BasicSetting/VideoModal/VideoModal";

const SetManager = () => {
  let appId = localStorage.getItem("app_Id");
  const navigate = useNavigate();
  const location = useLocation();
  const [copyAccount, setCopyAccount] = useState(false);
  const [copyRichard, setCopyRichard] = useState(false);
  const [copyLeslie, setCopyLeslie] = useState(false);
  const [copyAle, setCopyAle] = useState(false);
  const [copyLena, setCopyLena] = useState(false);
  const [copyDahlia, setCopyDahlia] = useState(false);
  const [copyCemil, setCopyCemil] = useState(false);
  const [firstModal, setFirstModal] = useState(false);
  const [secondModal, setSecondModal] = useState(false);
  const [videoGuide, setVideoGuide] = useState(false);
  const accountRef = useRef();
  const richardRef = useRef();
  const leslieRef = useRef();
  const aleRef = useRef();
  const lenaRef = useRef();
  const dahliaRef = useRef();
  const cemilRef = useRef();
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_ID);
    ReactGA.send({
      hitType: "pageview",
      path: location.pathname,
      location: location.pathname,
      title: "Third",
    });
    getGA();
  }, [location.pathname]);
  const onClickAccount = (e) => {
    const account = accountRef.current;
    account.select();
    document.execCommand("copy");
    setCopyAccount(true);
    setTimeout(() => {
      setCopyAccount(false);
    }, 1000);
  };
  const onClickRichard = (e) => {
    const richard = richardRef.current;
    richard.select();
    document.execCommand("copy");
    setCopyRichard(true);
    setTimeout(() => {
      setCopyRichard(false);
    }, 1000);
  };
  const onClickLeslie = (e) => {
    const leslie = leslieRef.current;
    leslie.select();
    document.execCommand("copy");
    setCopyLeslie(true);
    setTimeout(() => {
      setCopyLeslie(false);
    }, 1000);
  };
  const onClickAle = (e) => {
    const ale = aleRef.current;
    ale.select();
    document.execCommand("copy");
    setCopyAle(true);
    setTimeout(() => {
      setCopyAle(false);
    }, 1000);
  };
  const onClickLena = (e) => {
    const lena = lenaRef.current;
    lena.select();
    document.execCommand("copy");
    setCopyLena(true);
    setTimeout(() => {
      setCopyLena(false);
    }, 1000);
  };

  const onClickDahlia = (e) => {
    const dahlia = dahliaRef.current;
    dahlia.select();
    document.execCommand("copy");
    setCopyDahlia(true);
    setTimeout(() => {
      setCopyDahlia(false);
    }, 1000);
  };
  const onClickCemil = (e) => {
    const cemil = cemilRef.current;
    cemil.select();
    document.execCommand("copy");
    setCopyCemil(true);
    setTimeout(() => {
      setCopyCemil(false);
    }, 1000);
  };
  const nextBtnClick = () => {
    navigate("/mytest/newapp/sdk");
  };
  return (
    <div className={styles.container}>
      <img
        src={questionMark}
        alt=""
        className={styles.markIcon}
        onClick={() => setVideoGuide(true)}
      />
      <div className={styles.progress}>
        <div className={styles.firstCircle}>v</div>
        <div className={styles.line}></div>
        <div className={styles.secondCircle}>2</div>
        <div className={styles.line}></div>
        <div className={styles.thirdCircle}>3</div>
      </div>

      {!videoGuide ? null : <VideoModal setVideoGuide={setVideoGuide} />}
      {firstModal && <FirstDocModal setFirstModal={setFirstModal} />}
      {secondModal && <SecondDocModal setSecondModal={setSecondModal} />}
      <div className={styles.title}>Complete Ad Manager Set Up</div>
      <div className={styles.progressBox}>
        <div className={styles.advencedBox}>
          <div className={styles.docBtn} onClick={() => setFirstModal(true)}>
            ?
          </div>

          <div className={styles.firstTopText}>
            Setup Developer's Page Advanced Settings
          </div>
          <div className={styles.firstMiddleText}>
            Copy MondayOFF's Business Account ID
          </div>

          <div className={styles.accountBox}>
            <div
              className={classNames(
                !copyAccount ? styles.inactivePopupText : styles.popupText
              )}
            >
              Copied
            </div>
            <div className={styles.account}>Copy Administrator's ID</div>
            <div className={styles.accountIdBox} onClick={onClickAccount}>
              <input
                className={styles.accountId}
                value="293037052298556"
                ref={accountRef}
                readOnly
              />
              <img src={copyIcon} alt="" className={styles.copyBtn} />
            </div>
          </div>
          <div className={styles.bottomArea}>
            <div className={styles.firstBottomText}>
              <span>Copy and Paste MondayOFF’s Business </span>
              <span>
                Account ID{" "}
                <a
                  href={`https://developers.facebook.com/apps/${appId}/settings/advanced/`}
                  target="_blank"
                  rel="noreferrer"
                >
                  here.
                </a>
              </span>
            </div>
          </div>
          <div className={styles.firstBottomBox}>
            <img src={bangIcon} alt="" className={styles.blueCheck} />
            <div>
              If you don't input the basic and advanced settings properly, the
              data cannot be tracked.
            </div>
          </div>
        </div>
        <div className={styles.rolesBox}>
          <div className={styles.docBtn} onClick={() => setSecondModal(true)}>
            ?
          </div>

          <div className={styles.secondTopText}>
            Setup Developer's Page Roles
          </div>
          <div className={styles.secondMiddleText}>Copy Administrator's ID</div>
          <div className={styles.pmList}>
            <div className={styles.richardBox}>
              <div
                className={classNames(
                  !copyRichard
                    ? styles.inactivePopupText
                    : styles.richardPopupText
                )}
              >
                Copied
              </div>
              <div className={styles.name}>Richard</div>
              <div className={styles.richardId} onClick={onClickRichard}>
                <input
                  className={styles.email}
                  value="Richard.mondayoff"
                  ref={richardRef}
                  readOnly
                />
                <img src={copyIcon} alt="" className={styles.copyBtn} />
              </div>
            </div>
            <div className={styles.leslieBox}>
              <div
                className={classNames(
                  !copyLeslie
                    ? styles.inactivePopupText
                    : styles.lesliePopupText
                )}
              >
                Copied
              </div>
              <div className={styles.name}>Leslie</div>
              <div className={styles.leslieId} onClick={onClickLeslie}>
                <input
                  className={styles.email}
                  value="leslie.mondayoff"
                  ref={leslieRef}
                  readOnly
                />
                <img src={copyIcon} alt="" className={styles.copyBtn} />
              </div>
            </div>
            <div className={styles.aleBox}>
              <div
                className={classNames(
                  !copyAle ? styles.inactivePopupText : styles.alePopupText
                )}
              >
                Copied
              </div>
              <div className={styles.name}>Alejandra</div>
              <div className={styles.aleId} onClick={onClickAle}>
                <input
                  className={styles.email}
                  value="ale.mondayoff"
                  ref={aleRef}
                  readOnly
                />
                <img src={copyIcon} alt="" className={styles.copyBtn} />
              </div>
            </div>
            <div className={styles.lenaBox}>
              <div
                className={classNames(
                  !copyLena ? styles.inactivePopupText : styles.lenaPopupText
                )}
              >
                Copied
              </div>
              <div className={styles.name}>Lena</div>
              <div className={styles.lenaId} onClick={onClickLena}>
                <input
                  className={styles.email}
                  value="Lena.mondayoff"
                  ref={lenaRef}
                  readOnly
                />
                <img src={copyIcon} alt="" className={styles.copyBtn} />
              </div>
            </div>

            <div className={styles.dahliaBox}>
              <div
                className={classNames(
                  !copyDahlia
                    ? styles.inactivePopupText
                    : styles.dahliaPopupText
                )}
              >
                Copied
              </div>
              <div className={styles.name}>Dahlia</div>
              <div className={styles.dahliaId} onClick={onClickDahlia}>
                <input
                  className={styles.email}
                  value="dahlia.mondayoff"
                  ref={dahliaRef}
                  readOnly
                />
                <img src={copyIcon} alt="" className={styles.copyBtn} />
              </div>
            </div>

            <div className={styles.cemilBox}>
              <div
                className={classNames(
                  !copyCemil ? styles.inactivePopupText : styles.cemilPopupText
                )}
              >
                Copied
              </div>
              <div className={styles.name}>Cemil</div>
              <div className={styles.cemilId} onClick={onClickCemil}>
                <input
                  className={styles.email}
                  value="cemil.mondayoff"
                  ref={cemilRef}
                  readOnly
                />
                <img src={copyIcon} alt="" className={styles.copyBtn} />
              </div>
            </div>
          </div>

          <div className={styles.roleLink}>
            Copy Administrator’s ID and Insert them{" "}
            <a
              href={`https://developers.facebook.com/apps/${appId}/roles/roles/`}
              target="_blank"
              rel="noreferrer"
            >
              Here
            </a>
          </div>
        </div>
      </div>
      <div>
        <button className={styles.nextBtn} onClick={nextBtnClick}>
          Next
        </button>
      </div>
    </div>
  );
};
export default SetManager;
