import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";
import AdsData from "../../components/AdsData/AdsData";
import styles from "./AdsDetail.module.scss";
import MaxData from "../../components/MaxData/MaxData";
import ReactGA from "react-ga4";
import PlayTime from "../../components/PlayTime/PlayTime";
import REGA from "react-ga";
import RetentionData from "../../components/RetentionData/RetentionData";
import CPI from "../../components/CPI/CPI";
import PlayTimeData from "../../components/PlayTimeData/PlayTimeData";
import NextITRModal from "../../common/NextITRModal/NextITRModal";

const AdsDetail = ({ metaService, adsListService, maxService }) => {
  const location = useLocation();
  const [adsetData, setAdsetData] = useState([]);
  const [adsList, setAdsList] = useState([]);
  const [adsData, setAdsData] = useState([]);
  const [status, setStatus] = useState("");
  const [ITR, setITR] = useState([]);
  const [ITR_Tap, setITR_Tap] = useState("");
  const [filterMaxData, setFilterMaxData] = useState([]);
  const [averageCpi, setAverageCpi] = useState("-");
  const [d1Retention, setD1Retention] = useState("-");
  const [d0PlayTime, setD0PlayTime] = useState("-");
  const [showModal, setShowModal] = useState(false);
  const gameName = location?.state?.data?.gameName;
  const appId = location?.state?.data?.appId;
  const [isLimitError, setIsLimitError] = useState(false);
  useEffect(() => {
    adsListService?.allAds(appId).then((data) => {
      setAdsList(data);
    });
  }, [appId]);

  useEffect(() => {
    return setITR(
      adsList
        ?.filter((data) => data?.gameName?.includes(gameName))
        ?.map((data) => {
          let itrSearch = data?.gameName?.split("_");
          return itrSearch[itrSearch.length - 1];
        })
        .reverse()
    );
  }, [adsList, gameName]);
  useEffect(() => {
    if (ITR.length === 1) {
      setITR_Tap("ITR#1");
    } else if (ITR.length > 1) {
      setITR_Tap(ITR[ITR.length - 1]);
    }
  }, [ITR]);

  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_ID);
    ReactGA.send({
      hitType: "pageview",
      path: location.pathname,
      location: location.pathname,
      title: "AdsData",
    });
  }, [location.pathname]);

  useEffect(() => {
    const pathName = location.pathname;
    REGA.initialize(process.env.REACT_APP_TRACKING_ID);
    REGA.set({ page: "testData" });
    REGA.pageview(pathName, ["testData"]);
  }, [location.pathname]);
  useEffect(() => {
    if (ITR_Tap) {
      metaService.getAds(appId, `${gameName}_${ITR_Tap}`).then((data) => {
        if (data.status === 200) {
          setIsLimitError(false);
          setAdsData(() => data?.data?.adsData?.data);
          setAdsetData(() => data?.data?.adsetData);
        } else {
          setIsLimitError(true);
        }
      });
    }
  }, [
    ITR_Tap,
    appId,
    gameName,
    location?.pathname,
    location?.state?.data?.appId,
    location?.state?.data?.gameName,
    location?.state?.data,
    location?.state,
  ]);

  useEffect(() => {
    adsData?.map((data) => {
      switch (data?.effective_status) {
        case "PENDING_REVIEW":
          return setStatus("Review");
        case "ACTIVE":
          return setStatus("Active");
        case "CAMPAIGN_PAUSED":
          return setStatus("Complete");
        default:
          return setStatus("Inactive");
      }
    });
  }, [adsData]);

  useEffect(() => {
    if (ITR_Tap) {
      maxService
        .getMaxData(appId)
        .then((data) =>
          setFilterMaxData(() =>
            data.filter((i) => i.gameName.includes(ITR_Tap))
          )
        );
    }
  }, [ITR_Tap, location?.state?.data?.appId]);

  return (
    <div className={styles.container}>
      {showModal && (
        <NextITRModal
          appData={location.state.data}
          setShowModal={setShowModal}
        />
      )}
      <div className={styles.gameInfoContainer}>
        <img
          src={location?.state?.data?.gameIcon}
          alt=""
          className={styles.gameIcon}
        />
        <div className={styles.gameName}>{gameName}</div>
        {status === "Review" && (
          <div className={styles.reviewContainer}>
            <span className={styles.reviewCircle}></span>
            <span className={styles.reviewText}>{status}</span>
          </div>
        )}
        {status === "Active" && (
          <div className={styles.activeContainer}>
            <span className={styles.activeCircle}></span>
            <span className={styles.activeText}>{status}</span>
          </div>
        )}
        {status === "Inactive" && (
          <div className={styles.inActiveContainer}>
            <span className={styles.inActiveCircle}></span>
            <span className={styles.inActiveText}>{status}</span>
          </div>
        )}
        {status === "Complete" && (
          <div className={styles.completeContainer}>
            <span className={styles.completeCircle}></span>
            <span className={styles.completeText}>{status}</span>
          </div>
        )}
        {moment().isAfter(location?.state?.data?.endTime) && (
          <button
            className={styles.nextITRBtn}
            onClick={() => setShowModal(true)}
          >
            New ITR
          </button>
        )}
      </div>
      <div className={styles.totalDataContainer}>
        <div className={styles.totalDataText}>Summary</div>
        <div className={styles.dataContainer}>
          <CPI averageCpi={averageCpi} adsetData={adsetData} />
          <RetentionData d1Retention={d1Retention} />
          <PlayTimeData d0PlayTime={d0PlayTime} />
        </div>
      </div>
      <AdsData
        adsData={adsData}
        adsetData={adsetData}
        metaService={metaService}
        setITR_Tap={setITR_Tap}
        ITR_Tap={ITR_Tap}
        gameName={gameName}
        maxService={maxService}
        appId={location?.state?.data?.appId}
        setAverageCpi={setAverageCpi}
        adsListService={adsListService}
        ITR={ITR}
        setITR={setITR}
        adsList={adsList}
        isLimitError={isLimitError}
      />
      <MaxData
        retData={filterMaxData[0]?.retention}
        setD1Retention={setD1Retention}
      />
      <PlayTime
        setD0PlayTime={setD0PlayTime}
        playTimeData={filterMaxData[0]?.playTime}
      />
    </div>
  );
};

export default AdsDetail;
