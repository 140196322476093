import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import getGA from "../../../service/ga";
import CheckBox from "../../../components/IntergratingSDK/CheckBox/CheckBox";
import DownSdk from "../../../components/IntergratingSDK/DownSdk/DownSdk";
import styles from "./IntegratingSdk.module.css";
import Modal from "../../../common/Modal/Modal";
import FirstDocModal from "../../../components/IntergratingSDK/FirstDocModal/FirstDocModal";
import markIcon from "../../../img/addNewApp/questionMark.png";
import VideoModal from "./IntegrateVideoModal/VideoModal";
import whiteMark from "../../../img/settingBoxs/whiteMark.png";
const IntegratingSdk = ({ sdkService, setNextManagerSetup, metaService }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [firstCheck, setFirstCheck] = useState(false);
  const [secondCheck, setSecondCheck] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);
  const [isDocModal, setIsDocModal] = useState(false);
  const [videoGuide, setVideoGuide] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [alertMessage, setAlertMessage] = useState();
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_ID);
    ReactGA.send({
      hitType: "pageview",
      path: location.pathname,
      location: location.pathname,
      title: "Fourth",
    });
    getGA();
  }, [location.pathname]);
  useEffect(() => {
    let appId = localStorage.getItem("app_Id");

    const result = async () =>
      await metaService.checkAppId(appId).then((data) => {
        if (!data) {
          localStorage.removeItem("app_data");
        } else if (data) {
          localStorage.setItem("app_data", JSON.stringify(data?.data));
        }
      });
    result();
  }, []);

  const onClick = (e) => {
    if (!firstCheck || !secondCheck) {
      setIsShowModal(true);
      setTimeout(() => {
        setIsShowModal(false);
      }, 2000);
    } else if (
      !JSON.parse(localStorage.getItem("app_data")).object_store_urls
        .google_play
    ) {
      setAlertModal(true);
      setAlertMessage(
        <>
          <div>
            Facebook settings are not completed. Please make sure everything is
            set up.
            <div>1. Authorized Ad ID: Settings {"->"} Advanced Settings</div>
            <div>
              2. Platform Settings: Settings{"->"} Basic Settings{"->"}Add
              Platform
            </div>
          </div>
        </>
      );
    } else {
      e.preventDefault();
      navigate("/mytest/newapp/videos");
    }
  };

  const onClickDoc = () => {
    setIsDocModal(true);
  };

  return (
    <div className={styles.container}>
      {/* {isShowModal && (
        <Modal
          message="Please complete all steps."
          setAlertModal={setIsShowModal}
        />
      )} */}
      {alertModal && (
        <Modal message={alertMessage} setAlertModal={setAlertModal} />
      )}
      {videoGuide && <VideoModal setVideoGuide={setVideoGuide} />}
      <img
        src={markIcon}
        alt=""
        className={styles.markIcon}
        onClick={() => setVideoGuide(true)}
      />

      <div className={styles.progress}>
        <div className={styles.firstCircle}>v</div>
        <div className={styles.line}></div>
        <div className={styles.secondCircle}>2</div>
        <div className={styles.line}></div>
        <div className={styles.thirdCircle}>3</div>
      </div>
      <div className={styles.title}>Integrate the SDK</div>
      <div className={styles.sdkDownContainer}>
        <DownSdk sdkService={sdkService} />
        <CheckBox
          firstCheck={firstCheck}
          setFirstCheck={setFirstCheck}
          secondCheck={secondCheck}
          setSecondCheck={setSecondCheck}
        />
        {isDocModal && <FirstDocModal setIsDocModal={setIsDocModal} />}
        <div className={styles.docBtn} onClick={onClickDoc}>
          ?
        </div>
      </div>

      {isShowModal && (
        <div className={styles.messageContainer}>
          <img src={whiteMark} alt="" className={styles.whiteMark} />
          <div className={styles.message}>"Please complete all steps."</div>
        </div>
      )}

      <button className={styles.nextBtn} onClick={onClick}>
        Next
      </button>
    </div>
  );
};

export default IntegratingSdk;
