import React from "react";
import styles from "./NotApp.module.scss";
import notAppArrow from "../../img/arrow/notAppArrow.png";
import { useNavigate } from "react-router";
const NotApp = (props) => {
  const navigate = useNavigate();
  const onClick = () => {
    navigate("/mytest/newapp");
  };
  return (
    <div className={styles.container}>
      <div className={styles.text}>
        <div className={styles.first}>You haven’t submitted a game yet.</div>
        <div>Curious? Go live within minutes!</div>
      </div>
      <img src={notAppArrow} alt="" className={styles.arrow} />
      <button onClick={onClick} className={styles.btn}>
        Submit your game
      </button>
    </div>
  );
};
export default NotApp;
