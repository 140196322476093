import React from "react";
import styles from "./BonusAndTotalAmount.module.scss";
export default function BonusAndTotalAmount({ data }) {
  return (
    <section className={styles.container}>
      <section className={styles.bonus_list}>
        <p className={styles.bonus_list_title}>Bonus Payments</p>
        {data.bonuses.length ? (
          data.bonuses.map((item) => (
            <div className={styles.bonus_list_item}>
              <p className={styles.description}>{item.description}</p>
              <p className={styles.prize}>
                {item.profit.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </p>
            </div>
          ))
        ) : (
          <div className={styles.bonus_list_empty}>
            <p className={styles.description}>-</p>
            <p className={styles.prize}>$-</p>
          </div>
        )}
      </section>
      <section className={styles.total_amount}>
        <p>Total Amount</p>
        <p>
          {data.amount.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </p>
      </section>
    </section>
  );
}

/*
        {
            "status": "NOT_AVAILABLE",
            "amount": 0,
            "monthlyGrossRevenue": 225.72000000000003,
            "monthlySpend": 0.75,
            "googleIap": 1.71,
            "appleIap": 1.83,
            "detailOthers": [],
            "thirdPartCosts": 11.286,
            "monthlyNetRevenue": 213.68400000000003,
            "carryOverAmount": -21672.3,
            "totalNetRevenue": -21458.615999999998,
            "revenueShare": 0,
            "paidBonus": 2000,
            "carryOverBalances": [
                {
                    "description": "Soft Launch Bonus",
                    "profit": 2000
                }
            ],
            "payableRevenueShare": 0,
            "bonuses": [],
            "bonusSum": 0,
            "isDetail": false,
            "isBonus": false
        },
*/
