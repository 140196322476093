import React, { useRef, useState } from "react";
import { CSVLink } from "react-csv";
import CircularProgress from "@mui/material/CircularProgress";
import { useInvoice } from "../../Context/InvoiceContext";
import { deleteCookie, getCookie } from "../../db/cookies";
import { ReactComponent as CheckIcon } from "../../img/FinaceOverview/check.svg";
import emptyBox from "../../img/FinaceOverview/emptybox.png";
import styles from "./FinanceOverviewDataList.module.scss";
import FinanceOverviewGameList from "../FinanceOverviewGameList/FinanceOverviewGameList";
import FinanceOverviewListSkeleton from "../FinanceOverviewListSkeleton/FinanceOverviewListSkeleton";
import moment from "moment";

export default function FinanceOverviewDataList({
  list,
  isLoading,
  selectDate,
}) {
  const { downloadOverviewData } = useInvoice();
  const [isCsvDownload, setIsCsvDownload] = useState(false);
  const [checkItem, setCheckItem] = useState([]);
  const [dataCsv, setDataCsv] = useState("");

  const csvLinkRef = useRef(null);
  const onDownClick = async () => {
    setIsCsvDownload(true);
    await downloadOverviewData(
      moment(selectDate.startDate).format("YYYY-MM-DD"),
      moment(selectDate.endDate).format("YYYY-MM-DD"),
      checkItem.toString()
    ).then((res) => {
      if (res.status === 401) {
        deleteCookie("Authentication");
        if (!getCookie("Authentication")) {
          window.location.reload();
        }
      } else if (res.status === 400) {
        alert(res.data.message);
      } else {
        return setDataCsv(res.data);
      }
    });
    setIsCsvDownload(false);
    csvLinkRef.current.link.click();
  };
  const onTotalCheck = (e) => {
    const { checked } = e.target;
    if (checked) {
      const idArray = [];
      list.forEach((el) => idArray.push(el.everyDayGameId));
      setCheckItem(idArray);
    } else {
      setCheckItem([]);
    }
  };
  const onSingleCheck = (e, id) => {
    const { checked } = e.target;
    if (checked) {
      setCheckItem((prev) => [...prev, id]);
    } else {
      setCheckItem(checkItem.filter((el) => el !== id));
    }
  };

  return (
    <section className={styles.container}>
      <section className={styles.top_section}>
        <span className={styles.text_area}>
          <h2>Launched Games</h2>
          <p>
            Real-time data is provided for reference purposes only. Accurate
            data will be reflected in monthly statements.
          </p>
        </span>
        <button
          className={styles.csv_down_btn}
          data-content={checkItem.length ? "active" : "inactive"}
          onClick={
            isLoading || isCsvDownload || !checkItem.length
              ? undefined
              : onDownClick
          }
        >
          <p>
            {isCsvDownload ? (
              <CircularProgress size="20px" color="inherit" />
            ) : (
              "Export Data"
            )}
          </p>
        </button>
        <CSVLink
          data={dataCsv}
          filename={`${moment(selectDate[0]).format("YYYY-MM-DD")}~${moment(
            selectDate[1]
          ).format("YYYY-MM-DD")}`}
          ref={csvLinkRef}
          className={styles.hidden}
        ></CSVLink>
      </section>
      <section className={styles.data_container}>
        <ul className={styles.data_header}>
          <li>
            <input
              className={styles.total_click_input}
              type="checkbox"
              id="total_checkbox"
              onChange={onTotalCheck}
              checked={
                checkItem.length === list.length && list.length ? true : false
              }
            />
            <label className={styles.check_box} htmlFor="total_checkbox">
              <CheckIcon className={styles.icon} />
            </label>
          </li>
          <li>
            <p>Game</p>
          </li>
          <li>
            <p>Ad Revenue</p>
          </li>
          <li>
            <p>IAP Revenue</p>
          </li>
          <li>
            <p>Spend</p>
          </li>
          <li>
            <p>Profit</p>
          </li>
          <li>
            <p>Installs</p>
          </li>
        </ul>
        <ul className={styles.game_list}>
          {isLoading ? (
            <FinanceOverviewListSkeleton />
          ) : list.length ? (
            list.map((game) => (
              <FinanceOverviewGameList
                game={game}
                checkItem={checkItem}
                onSingleCheck={onSingleCheck}
                isLoading={isLoading}
              />
            ))
          ) : (
            <div className={styles.not_found}>
              <img src={emptyBox} alt="img" />
              <p>No invoice information has been found.</p>
            </div>
          )}
        </ul>
      </section>
    </section>
  );
}
