import React from "react";
import styles from "./CompletionTitle.module.css";
const CompletionTitle = (props) => {
  const gameName = JSON.parse(localStorage?.getItem("appInfo"))?.gameName;

  return (
    <div className={styles.container}>
      <div className={styles.firstText}>
        <span className={styles.gameName}>{gameName?.split("_")[0]}&nbsp;</span>
        <span className={styles.completeText}>
          {" "}
          was submitted successfully!
        </span>
      </div>
      <div className={styles.secondText}>
        You can now check your game data in real time.
      </div>
    </div>
  );
};
export default CompletionTitle;
