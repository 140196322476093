import React, { useCallback } from "react";
import styles from "./PayableRevenue.module.scss";
import { ReactComponent as InfoIcon } from "../../img/Invoice/info.svg";
export default function PayableRevenue({ data }) {
  const shareCheck = useCallback((prize) => {
    if (prize < 0) {
      return "zeroUnder";
    } else if (prize >= 0 && prize < 500) {
      return "500under";
    } else {
      return "share";
    }
  }, []);

  return (
    <section className={styles.container}>
      <section className={styles.total_net_revenue}>
        <div className={styles.monthly_net_revenue}>
          <p>Monthly Net Revenue</p>
          <p>
            {data.monthlyNetRevenue.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
          </p>
        </div>
        <div className={styles.carryover_amount}>
          <p>Carryover Amount</p>
          <p>
            {data.carryOverAmount.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
          </p>
        </div>
        <div className={styles.total_net}>
          <p>Total Net Revenue</p>
          <p>
            {data.totalNetRevenue.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
          </p>
        </div>
      </section>
      <section className={styles.payable_revenue_share}>
        <div className={styles.revenue_share}>
          <p>Revenue Share</p>
          <p>
            {data.revenueShare.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
          </p>
        </div>
        <div className={styles.payments}>
          <span>
            <p>Recoupable Payments</p>
            <InfoIcon className={styles.info_icon} />
            {data.carryOverBalances.length ? (
              <section className={styles.bonus_popup}>
                <p>Recoupable payments include:</p>
                <ul>
                  {data.carryOverBalances.map((bonus) => (
                    <li>
                      <p>
                        -&nbsp;
                        {bonus.profit.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })}
                      </p>
                      &nbsp;
                      <p>{bonus.description}</p>
                    </li>
                  ))}
                </ul>
              </section>
            ) : (
              <></>
            )}
          </span>
          <p>
            {data.paidBonus.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
          </p>
        </div>
        <div className={styles.total_payable_share}>
          <p>Payable Revenue Share**</p>
          <p data-content={shareCheck(data.payableRevenueShare)}>
            {data.payableRevenueShare <= 0
              ? data.payableRevenueShare.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })
              : "$-"}
          </p>
        </div>
      </section>
    </section>
  );
}
/*
.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })
        {
            "status": "NOT_AVAILABLE",
            "amount": 0,
            "monthlyGrossRevenue": 225.72000000000003,
            "monthlySpend": 0.75,
            "googleIap": 1.71,
            "appleIap": 1.83,
            "detailOthers": [],
            "thirdPartCosts": 11.286,
            "monthlyNetRevenue": 213.68400000000003,
            "carryOverAmount": -21672.3,
            "totalNetRevenue": -21458.615999999998,
            "revenueShare": 0,
            "paidBonus": 2000,
            "carryOverBalances": [
                {
                    "description": "Soft Launch Bonus",
                    "profit": 2000
                }
            ],
            "payableRevenueShare": 0,
            "bonuses": [],
            "bonusSum": 0,
            "isDetail": false,
            "isBonus": false
        },
*/
