import React from "react";
import styles from "./FirstDocModal.module.css";
import first from "../../../img/integrateDoc/first.png";
import second from "../../../img/integrateDoc/second.png";
import third from "../../../img/integrateDoc/third.png";
import fourth from "../../../img/integrateDoc/fourth.png";
import fifth from "../../../img/integrateDoc/fifth.png";
import sixth from "../../../img/integrateDoc/sixth.png";
import seventh from "../../../img/integrateDoc/seventh.png";
import eighth from "../../../img/integrateDoc/eighth.png";
import ninth from "../../../img/integrateDoc/ninth.png";
const FirstDocModal = ({ setIsDocModal }) => {
  const onClick = () => {
    setIsDocModal(false);
  };
  return (
    <div className={styles.container}>
      <div className={styles.modalContainer}>
        <div className={styles.bunisessTitle}>
          <div className={styles.bunisessTitleText}>
            How to Integrate MondayOFF SDK
          </div>
        </div>
        <div className={styles.scroll}>
          <div className={styles.firstDocContainer}>
            <div className={styles.firstDocContext}>
              <div className={styles.one}>How to Integrate MondayOFF SDK</div>
              <div className={styles.two}>
                <div className={styles.two_one}>
                  Please make sure that you upload the updated version with the
                  integrated SDK to the Google play store. Otherwise we cannot
                  track your data and the test may be canceled early.
                </div>
                <div className={styles.two_two}>
                  1. Put the <span>MondayOFFSDK.unitypackage</span> in your
                  project{" "}
                  <div>(You can download SDK file on the dashboard page)</div>
                </div>
                <img src={first} alt="" />
              </div>
              <div className={styles.three}>
                <div className={styles.threeText}>
                  2. Cilck the <span>import</span> button on the lower right
                  Corner
                </div>
                <img src={second} alt="" />
              </div>
              <div className={styles.fourth}>
                <div className={styles.fourthText}>
                  <div className={styles.fouthTextFirst}>
                    3. Select <span>Facebook {">"} Edit settings</span> on the
                    menu
                  </div>
                  <div className={styles.fouthTextSecond}>
                    Add Facebook App name and App ID to the Inspector
                  </div>
                </div>
                <img src={third} alt=""></img>
              </div>
              <div className={styles.fifth}>
                <div className={styles.fifthText}>
                  4. Add <span>Assets/MondayOFF/Prefabs/MondayOFF.prefab </span>
                  to your starting scene
                </div>
                <div className={styles.fifthText_one}>
                  <div>
                    You can also create MondayOFF Game object to the current
                    working scene by{" "}
                    <span>
                      selecting MondayOFF {">"} Create MondayOFF Game Object
                    </span>{" "}
                    on the menu
                  </div>
                  <div className={styles.fifthText_two}>
                    DON’T FORGET TO SAVE THE SCENE
                  </div>
                </div>
                <img src={fourth} alt="" />
              </div>
              <div className={styles.sixth}>
                <div className={styles.sixthTitle}>
                  5. Copy the Package Name and the Class Name to complete the
                  Platform settings.
                </div>
                <div className={styles.sixth_one}>
                  Click on{" "}
                  <a
                    href={`https://developers.facebook.com/apps/${localStorage.getItem(
                      "app_Id"
                    )}/settings/basic`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Basic setting
                  </a>{" "}
                  to move Facebook developer page
                </div>
                <div className={styles.sixth_two}>
                  <div>
                    *How to check the package name and class name in the Unity
                    program
                  </div>
                  <div className={styles.sixth_two_two}>
                    Select <span>Facebook {">"} Edit Settings</span> on the menu
                  </div>
                </div>
                <img src={fifth} alt="" />
              </div>
              <div className={styles.seventh}>
                <div className={styles.seventhTitle}>
                  6. In the basic settings, scroll down and click on{" "}
                  <span>“Add Platform"</span>
                </div>
                <div className={styles.seventh_one}>
                  Click on <span>“Add Platform”</span> and select the platform{" "}
                  <span>“Android”</span>, then select the store{" "}
                  <span>“Google Play”</span>
                </div>
                <img src={sixth} alt="" />
              </div>
              <div className={styles.eighth}>
                <div className={styles.eighthTitle}>
                  7. Enter the Package Name and Class Name Shown in Unity
                </div>
                <div className={styles.eighth_one}>
                  Click on <span>"Save Changes”</span> after filling in the form
                </div>
                <img src={seventh} alt="" />
              </div>
              <div className={styles.ninth}>
                <div className={styles.ninthTitle}>
                  How to confirm app ownership
                </div>
                <img src={eighth} alt="" />
                <div className={styles.ninth_one}>
                  When adding a platform to your app, Facebook will ask you to
                  verify the ownership of your app on the platform.{" "}
                  <span>
                    Your game must be uploaded to the Google Play store to do
                    this.
                  </span>
                </div>
                <div className={styles.ninth_two}>
                  In order to confirm your android app ownership on Google Play,
                  you will need to:
                </div>
                <div className={styles.ninth_three}>
                  1. Have a <span>website</span>, or use one of the following{" "}
                  <span>services to create a website</span>:
                </div>
                <div className={styles.ninth_four}>
                  <a
                    href="https://app-adstxt.dev"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://app-adstxt.dev
                  </a>
                  {" , "}
                  <a
                    href="https://www.app-ads-txt.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    www.app-ads-txt.com
                  </a>
                </div>
                <ul className={styles.ninth_five}>
                  <li>
                    If you are using a service to create your website, follow
                    the steps below.
                  </li>
                </ul>
                <ul className={styles.ninth_six}>
                  <li>
                    - Make an account on the website and confirm your email
                    address.
                  </li>
                  <li>- Enter your app’s store page URL.</li>
                  <li>- Enter the following into the field:</li>
                </ul>
                <div className={styles.ninth_seven}>
                  facebook.com, app ID, RESELLER, c3e20eee3f780d68
                </div>
                <div className={styles.ninth_notice}>
                  Notice: Replace app ID with your game’s app ID
                </div>
                <ul className={styles.ninth_nine}>
                  <li>
                    If you are using your own website, follow the steps below.
                  </li>
                </ul>
                <ul className={styles.ninth_ten}>
                  <li>- Create a .txt file named app-ads</li>
                  <li>- Add the following to your app-ads.txt file:</li>
                </ul>
                <div className={styles.ninth_eleven}>
                  facebook.com, app ID, RESELLER, c3e20eee3f780d68
                </div>
                <div className={styles.ninth_notice}>
                  Notice: Replace app ID with your game’s app ID
                </div>
                <ul className={styles.nine_twelve}>
                  <li>
                    - Upload the app-ads.txt file to the root of your website.
                  </li>
                </ul>
                <img src={ninth} alt="" />
                <div className={styles.nine_thirteenth}>
                  2. <span>Copy</span> either the <span>URL</span> provided by
                  the service or your own website’s URL and{" "}
                  <span>paste it in the “Website” field</span> under{" "}
                  <span>Store listing contact details</span> on{" "}
                  <span>Google Play Console.</span>
                </div>
                <div className={styles.ninth_fourteen}>
                  Store Presence {">"} Store Listing {">"} Store listing contact
                  details {">"} <span>Website</span>
                </div>
                <div className={styles.ninth_fifteen}>
                  <div>
                    3. You’re done! Facebook usually takes{" "}
                    <span>up to 24 hours</span> to detect the file.
                  </div>
                  <div>It may take longer than that, so please be patient.</div>
                </div>
                <div className={styles.ninth_sixteen}>
                  If you need more help, you can check out Facebook’s guide{" "}
                  <a
                    href="https://developers.facebook.com/docs/development/release/mobile-app-verification"
                    target="_blank"
                    rel="noreferrer"
                  >
                    here
                  </a>
                  .
                </div>
              </div>
              <button className={styles.closeBtn} onClick={onClick}>
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(FirstDocModal);
