import axios from "axios";
import merge from "deepmerge";
import moment from "moment";
export default class AdsNetworkService {
  constructor(http, newHttp) {
    this.http = http;
    this.newHttp = newHttp;
  }
  async getAdsList(userId) {
    return await this.http
      .axios(`/list/${userId}`, {
        method: "GET",
      })
      .then((res) => res.data)
      .catch(console.error);
  }
  async getTengineData(startDate, endDate, adsList) {
    return await this.http
      .axios(`/tengine`, {
        method: "POST",
        data: { adsList, startDate, endDate },
      })
      .then((res) => res.data)
      .catch(console.error);
  }
  async getAdsnetworkData(startDate, endDate, adsList) {
    const combineMerge = (target, source, options) => {
      const destination = target.slice();
      source.forEach((item, index) => {
        if (typeof destination[index] === "undefined") {
          destination[index] = options.cloneUnlessOtherwiseSpecified(
            item,
            options
          );
        } else if (options.isMergeableObject(item)) {
          destination[index] = merge(target[index], item, options);
        } else if (target.indexOf(item) === -1) {
          destination.push(item);
        }
      });
      return destination;
    };
    return await axios
      .all([
        axios.post(`https://data.mondayoff.me/dataList/firstData`, {
          data: { adsList, startDate, endDate },
        }),
        axios.post(`https://data.mondayoff.me/dataList/secondData`, {
          data: { adsList, startDate, endDate },
        }),
      ])
      .then(
        axios.spread((res1, res2) => {
          const data1 = res1.data;
          const data2 = res2.data;
          return merge(data1, data2, { arrayMerge: combineMerge });
        })
      );
  }

  async getNewAdnetworkData(startDate, endDate, adsList) {
    let packageNames = [];
    await adsList.map((list) =>
      packageNames.push(list.packageName.toLowerCase())
    );
    packageNames = packageNames.toString();

    return await this.newHttp
      .axios(
        `/appList/dashboard?startDate=${moment(startDate).format(
          "YYYY-MM-DD"
        )}&endDate=${moment(endDate).format(
          "YYYY-MM-DD"
        )}&bundleIds=${packageNames}`,
        {
          method: "GET",
        }
      )
      .then((res) => res.data)
      .catch(console.error);
  }
}
