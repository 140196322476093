export default class sdkService {
  constructor(http, tokenStorage) {
    this.http = http;
  }
  async downSDK() {
    return await this.http
      .axios("/sdk/download", { method: "GET" })
      .then(window.open(`https://server.mondayoff.me/sdk/download`, "_blank"));
  }
}
