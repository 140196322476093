import React from "react";
import { useLocation } from "react-router-dom";
import styles from "./VideoModal.module.css";
import firstVideo from "../../../videoGuide/createVideo.mp4";
import secondVideo from "../../../videoGuide/secondVideo.mp4";
import thirdVideo from "../../../videoGuide/thirdVideo.mp4";
import { Fragment } from "react";
const VideoModal = ({ setVideoGuide, nextManagerSetup }) => {
  const location = useLocation();
  const onClick = () => {
    setVideoGuide(false);
  };
  return (
    <div className={styles.container}>
      <div className={styles.modalContainer}>
        <div className={styles.videoTitle}>
          {/* <div className={styles.videoTitleText}>VideoModal</div> */}
        </div>
        <div className={styles.scroll}>
          <div className={styles.firstVideoContainer}>
            <div className={styles.firstVideoContext}>
              {location.pathname === "/mytest/newapp/basicsetting" && (
                <Fragment>
                  <div className={styles.one}>
                    1. Create FB business Account
                  </div>
                  <video controls className={styles.firstVideo}>
                    <source src={firstVideo} type="video/mp4" />
                  </video>
                  <div className={styles.two}>
                    2. Set-up your developer account
                  </div>
                  <video controls className={styles.secondVideo}>
                    <source src={secondVideo} type="video/mp4" />
                  </video>
                </Fragment>
              )}
              {location.pathname === "/mytest/newapp/advenced" && (
                <Fragment>
                  <div className={styles.one}>
                    1. How to setup Advanced setting and Roles
                  </div>
                  <video controls className={styles.thirdVideo}>
                    <source src={thirdVideo} type="video/mp4" />
                  </video>
                </Fragment>
              )}

              <button className={styles.closeBtn} onClick={onClick}>
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(VideoModal);
