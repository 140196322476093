import React from "react";
import styles from "./CpiVideoGuide.module.css";
import cpiVideo from "../../../../videoGuide/CPI_Guide_red.mp4";
const CpiVideoGuide = ({ setCpiVideoGuide }) => {
  const onClick = () => {
    setCpiVideoGuide(false);
  };
  return (
    <div className={styles.container}>
      <div className={styles.modalContainer}>
        <div className={styles.videoTitle}>
          <div className={styles.videoTitleText}>
            What causes CPI to decrease?
          </div>
        </div>
        <div className={styles.scroll}>
          <div className={styles.firstContainer}>
            <div className={styles.firstContext}>
              <video width="944px" height="472px" controls>
                <source src={cpiVideo} type="video/mp4" />
              </video>
              <button className={styles.closeBtn} onClick={onClick}>
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(CpiVideoGuide);
