import React, { useEffect, useRef } from "react";
import moment from "moment";
import styles from "./InvoiceCalander.module.scss";
import { ReactComponent as ArrowIcon } from "../../img/Invoice/arrow_left.svg";
import { useKeyEscClose } from "../../common/escClose/escClose";
import { monthCheck } from "../../db/monthCheck";

export default function InvoiceCalander({
  setIsCalanderClick,
  monthList,
  setMonth,
  setYear,
  year,
  dateRange,
  setDateRange,
  dateSelects,
  setDateSelects,
  onCustomClick,
}) {
  const calanderRef = useRef();

  useEffect(() => {
    let handleOutsideClick = (e) => {
      if (calanderRef?.current?.contains(e.target)) {
        setIsCalanderClick(true);
      } else {
        setIsCalanderClick(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => document.removeEventListener("mousedown", handleOutsideClick);
  }, [setIsCalanderClick]);

  const onFirstMonthClick = (item, selectYear) => {
    setMonth(item);
    const numberMonth = monthCheck.find((mon) => mon[item]);
    setDateSelects({ startDate: false, endDate: false });
    setDateRange((prev) => ({
      ...prev,
      startDate: moment(`${selectYear}-${numberMonth[item]}`).format("YYYY-MM"),
    }));
    setDateSelects({ startDate: true, endDate: false });
  };

  const onSecontMonthClick = (item, selectYear) => {
    setMonth(item);
    const numberMonth = monthCheck.find((mon) => mon[item]);
    setDateSelects({ startDate: true, endDate: false });
    setDateRange((prev) => ({
      ...prev,
      endDate: moment(`${selectYear}-${numberMonth[item]}`).format("YYYY-MM"),
    }));
    setDateSelects({ startDate: false, endDate: false });
  };

  const isMonthSelect = (selectYear, item) => {
    const numberMonth = monthCheck.find((mon) => mon[item]);
    let startDate = moment(dateRange.startDate).isBefore(dateRange.endDate)
      ? dateRange.startDate
      : dateRange.endDate;
    let endDate = moment(dateRange.endDate).isAfter(dateRange.startDate)
      ? dateRange.endDate
      : dateRange.startDate;

    return (
      moment(`${selectYear}-${numberMonth[item]}`).isSame(startDate) ||
      moment(`${selectYear}-${numberMonth[item]}`).isSame(endDate)
    );
  };
  const isMonthRangeSelect = (selectYear, item) => {
    const numberMonth = monthCheck.find((mon) => mon[item]);
    let startDate = moment(dateRange.startDate).isBefore(dateRange.endDate)
      ? dateRange.startDate
      : dateRange.endDate;
    let endDate = moment(dateRange.endDate).isAfter(dateRange.startDate)
      ? dateRange.endDate
      : dateRange.startDate;
    return moment(`${selectYear}-${numberMonth[item]}`).isBetween(
      startDate,
      endDate,
      undefined,
      "()"
    );
  };
  const onYearCountClick = (type) => {
    if (type === "prev") {
      setYear((prev) => prev - 1);
    } else {
      setYear((prev) => prev + 1);
    }
  };
  useKeyEscClose(() => {
    setIsCalanderClick(false);
  });

  return (
    <div className={styles.container} ref={calanderRef}>
      <div className={styles.year}>
        <button onClick={() => onYearCountClick("prev")} data-content="prev">
          <ArrowIcon className={styles.arrow_left} />
        </button>
        <p>{year}</p>
        <button
          data-content={moment().year() !== year ? "next" : "inactive"}
          onClick={() => moment().year() !== year && onYearCountClick("next")}
        >
          <ArrowIcon className={styles.arrow_right} />
        </button>
      </div>
      <ul className={styles.monthList}>
        {monthList.map((item) => {
          const numberMonth = monthCheck.find((mon) => mon[item]);
          return moment().isSameOrAfter(`${year}-${numberMonth[item]}`) ? (
            <li
              key={Math.random()}
              data-content={isMonthSelect(year, item) ? "active" : "inactive"}
              range-content={
                isMonthRangeSelect(year, item) ? "range" : "inactive"
              }
              onClick={() =>
                moment().isSameOrBefore(`${year}-${numberMonth[item]}`)
                  ? undefined
                  : dateSelects.startDate
                  ? onSecontMonthClick(item, year)
                  : onFirstMonthClick(item, year)
              }
            >
              {item}
            </li>
          ) : (
            <li
              invalid={
                moment().isSameOrBefore(`${year}-${numberMonth[item]}`)
                  ? "invalid"
                  : ""
              }
            >
              {item}
            </li>
          );
        })}
      </ul>
      <div className={styles.date_apply}>
        <div className={styles.date_range}>
          <p>Range:</p>
          <p>
            {moment(dateRange.startDate).isBefore(dateRange.endDate)
              ? `${moment(dateRange.startDate).format("MMM YYYY")} - ${moment(
                  dateRange.endDate
                ).format("MMM YYYY")}`
              : `${moment(dateRange.endDate).format("MMM YYYY")} - ${moment(
                  dateRange.startDate
                ).format("MMM YYYY")}`}
          </p>
        </div>
        <div className={styles.buttons}>
          <button
            className={styles.cancel_btn}
            onClick={() => setIsCalanderClick(false)}
          >
            <p>Cancel</p>
          </button>
          <button className={styles.apply_btn} onClick={onCustomClick}>
            <p>Apply</p>
          </button>
        </div>
      </div>
    </div>
  );
}
