import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import fileDownload from "js-file-download";
import styles from "./InvoiceList.module.scss";
import { ReactComponent as ExpandIcon } from "../../img/Invoice/expand.svg";
import { ReactComponent as MoreIcon } from "../../img/Invoice/more.svg";
import { ReactComponent as DownIcon } from "../../img/Invoice/download.svg";
import InvoiceGameList from "../InvoiceGameList/InvoiceGameList";
import { useInvoice } from "../../Context/InvoiceContext";
import { deleteCookie } from "../../db/cookies";
export default function InvoiceList({ item }) {
  const { downloadInvoice, downloadRemittance } = useInvoice();
  const [invoiceStatus, setInvoiceStatus] = useState("");
  const [isExpand, setIsExpand] = useState(false);
  const [moreClick, setIsMoreClick] = useState(false);
  const moreRef = useRef(null);
  useEffect(() => {
    switch (item.invoiceStatus) {
      case "NOT_AVAILABLE":
        setInvoiceStatus("N/A");
        return;
      case "IN_COMPLETE":
        setInvoiceStatus("Incomplete");
        return;
      case "FINALIZING":
        setInvoiceStatus("Finalizing");
        return;
      case "IN_REVIEW":
        setInvoiceStatus("In Review");
        return;
      case "PENDING":
        setInvoiceStatus("Pending");
        return;
      case "INSUFFICIENT":
        setInvoiceStatus("N/A");
        return;
      case "PAID":
        setInvoiceStatus("Paid");
        return;
      default:
        return;
    }
  }, [item]);

  useEffect(() => {
    let outSideClickHandler = (e) => {
      if (moreRef?.current?.contains(e.target)) {
        setIsMoreClick(true);
      } else {
        setIsMoreClick(false);
      }
    };
    document.addEventListener("mousedown", outSideClickHandler);
    return () => document.removeEventListener("mousedown", outSideClickHandler);
  }, []);

  const onDownInvoiceClick = () => {
    setIsMoreClick(false);
    return downloadInvoice(item.studioId, item.month).then((res) => {
      if (res.status === 400) {
        alert(res.data.message);
      } else if (res.status === 401) {
        deleteCookie("Authentication");
        window.location.reload();
      } else {
        fileDownload(res.data, `invoice-${item.month}.pdf`);
      }
    });
  };
  const onDownRemittanceClick = () => {
    setIsMoreClick(false);
    return downloadRemittance(item.studioId, item.month).then((res) => {
      if (res.status === 400) {
        alert(res.data.message);
      } else if (res.status === 401) {
        deleteCookie("Authentication");
        window.location.reload();
      } else {
        fileDownload(res.data, `remittance-${item.month}.pdf`);
      }
    });
  };
  return (
    <>
      <li className={styles.container}>
        {moreClick &&
          (item.invoiceKey || item.invoicePaymentKey ? (
            <section className={styles.more_menu} ref={moreRef}>
              <div
                className={styles.invoice_download}
                onClick={onDownInvoiceClick}
              >
                <DownIcon className={styles.down_icon} />
                <p>Download Invoice</p>
              </div>
              <div
                className={styles.remittance_download}
                onClick={onDownRemittanceClick}
              >
                <DownIcon className={styles.down_icon} />
                <p>Download Remittance Details</p>
              </div>
            </section>
          ) : (
            <></>
          ))}
        <span className={styles.date}>
          <ExpandIcon
            className={styles.expand_icon}
            onClick={() => setIsExpand((prev) => !prev)}
            data-content={isExpand ? "active" : "inactive"}
          />
          <p>{moment(item.issueDate).format("DD MMMM, YYYY")}</p>
        </span>
        <p>
          {item.totalMonthlyGrossRevenue.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </p>
        <p>
          {item.totlaMonthlySpend.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </p>
        <p>
          {item.totalThirdPartyCosts.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </p>
        <p>
          {item.totalMonthlyNetRevenue.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </p>
        <p>
          {item.totalRevenueShare.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </p>
        <p>
          {item.totalBonus.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </p>
        <p className={styles.status} data-content={invoiceStatus}>
          {invoiceStatus}
        </p>
        <MoreIcon
          className={styles.more_icon}
          onClick={() => setIsMoreClick((prev) => !prev)}
        />
      </li>
      {isExpand &&
        item.games.map((game) => (
          <InvoiceGameList
            item={game}
            date={item.issueDate}
            studioId={item.studioId}
            month={item.month}
          />
        ))}
    </>
  );
}
