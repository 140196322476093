import React, { memo, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import styles from "./SideBar.module.scss";

import { ReactComponent as HomeIcon } from "../../img/sidebar/homeIcon.svg";
import { ReactComponent as GamesIcon } from "../../img/sidebar/games.svg";
import { ReactComponent as PublishingIcon } from "../../img/sidebar/publishing.svg";
import { ReactComponent as FinanceIcon } from "../../img/sidebar/finance.svg";
import { ReactComponent as HelpIcon } from "../../img/sidebar/help.svg";
import { ReactComponent as ExpandIcon } from "../../img/sidebar/expand.svg";
import logo from "../../img/logo/newLogo.png";

import LogoutModal from "../../common/LogoutModal/LogoutModal";
import InvoiceLogin from "../InvoiceLogin/InvoiceLogin";
import { getCookie } from "../../db/cookies";
const SideBar = memo(({ user }) => {
  const location = useLocation();

  const profileModalRef = useRef(null);
  const [isGames, setIsGames] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [isFinance, setIsFinance] = useState(false);
  const [isHelp, setIsHelp] = useState(false);
  const [isProfile, setIsProfile] = useState(false);
  const [isEditPayment, setIsEditPayment] = useState(false);
  const [isLogoutModal, setIsLogoutModal] = useState(false);

  const onMenuClick = (n) => {
    switch (n) {
      case 1:
        setIsGames((prev) => !prev);
        return;
      case 2:
        setIsPublishing((prev) => !prev);
        return;
      case 3:
        setIsFinance((prev) => !prev);
        return;
      case 4:
        setIsHelp((prev) => !prev);
        return;
      case 5:
        setIsProfile((prev) => !prev);
        setIsEditPayment(false);
        return;
      default:
        return;
    }
  };
  useEffect(() => {
    const profileModalHandler = (e) => {
      if (profileModalRef?.current?.contains(e.target)) {
        setIsProfile(true);
      } else {
        setIsProfile(false);
      }
    };
    document.addEventListener("mousedown", profileModalHandler);
    return () => document.removeEventListener("mousedown", profileModalHandler);
  }, []);

  return (
    <>
      <div className={styles.container}>
        <img src={logo} alt="logo" className={styles.logo} />
        {isLogoutModal && (
          <LogoutModal
            message="Do you want to logout?"
            setAlertModal={setIsLogoutModal}
          />
        )}

        <Link to="/mytest/newapp" className={styles.submit_game_btn}>
          <p>Submit Game</p>
        </Link>

        <div className={styles.menu_container}>
          <ul className={styles.menu_list}>
            <Link
              to="/"
              className={styles.home_tab}
              data-content={
                location?.pathname === "/" ||
                location?.pathname === "/secondprocess" ||
                location?.pathname === "/thirdprocess"
                  ? "active"
                  : "inactive"
              }
            >
              <HomeIcon
                className={styles.icon}
                data-content={
                  location?.pathname === "/" ||
                  location?.pathname === "/secondprocess" ||
                  location?.pathname === "/thirdprocess"
                    ? "active"
                    : "inactive"
                }
              />
              <p>Home</p>
            </Link>
            <div className={styles.game_tab}>
              <div
                onClick={() => onMenuClick(1)}
                data-content={
                  location?.pathname.includes("progress") ||
                  location?.pathname.includes("complete")
                    ? "active"
                    : "inactive"
                }
              >
                <span>
                  <GamesIcon className={styles.menu_icon} />
                  <p>My games</p>
                </span>
                <ExpandIcon
                  className={styles.expand_icon}
                  data-content={isGames ? "active" : "inactive"}
                />
              </div>
              {isGames && (
                <>
                  <Link to="/progress">
                    <p
                      data-content={
                        location?.pathname.includes("progress")
                          ? "active"
                          : "inactive"
                      }
                    >
                      In Progress
                    </p>
                  </Link>
                  <Link to="/complete">
                    <p
                      data-content={
                        location?.pathname.includes("complete")
                          ? "active"
                          : "inactive"
                      }
                    >
                      Complete
                    </p>
                  </Link>
                </>
              )}
            </div>
            <div className={styles.publishing_tab}>
              <div
                onClick={() => onMenuClick(2)}
                data-content={
                  location?.pathname.includes("publishing") ||
                  location?.pathname.includes("empty")
                    ? "active"
                    : "inactive"
                }
              >
                <span>
                  <PublishingIcon className={styles.menu_icon} />
                  <p>Publishing</p>
                </span>
                <ExpandIcon
                  className={styles.expand_icon}
                  data-content={isPublishing ? "active" : "inactive"}
                />
              </div>
              {isPublishing && (
                <Link to={user?.data?.publishing ? "/publishing" : "/empty"}>
                  <p
                    data-content={
                      location?.pathname.includes("publishing") ||
                      location?.pathname.includes("empty")
                        ? "active"
                        : "inactive"
                    }
                  >
                    Overview
                  </p>
                </Link>
              )}
            </div>
            <div className={styles.finance_tab}>
              <div
                onClick={() => onMenuClick(3)}
                data-content={
                  location?.pathname.includes("invoice") ||
                  location?.pathname.includes("overview")
                    ? "active"
                    : "inactive"
                }
              >
                <span>
                  <FinanceIcon className={styles.menu_icon} />
                  <p>Finance</p>
                  <p className={styles.beta}>Beta</p>
                </span>
                <ExpandIcon
                  className={styles.expand_icon}
                  data-content={
                    isFinance && user?.data?.id !== 290 ? "active" : "inactive"
                  }
                />
              </div>
              {isFinance && user?.data?.id !== 290 && (
                <>
                  <Link to={user?.data?.id !== 290 && "finance/overview"}>
                    <p
                      data-content={
                        location?.pathname.includes("overview")
                          ? "active"
                          : "inactive"
                      }
                    >
                      Overview
                    </p>
                  </Link>
                  <Link to={user?.data?.id !== 290 && "finance/invoice"}>
                    <p
                      data-content={
                        location?.pathname.includes("invoice")
                          ? "active"
                          : "inactive"
                      }
                    >
                      Invoices
                    </p>
                  </Link>
                </>
              )}
            </div>
            <div className={styles.help_tab}>
              <div
                onClick={() => onMenuClick(4)}
                data-content={
                  location?.pathname.includes("faq") ? "active" : "inactive"
                }
              >
                <span>
                  <HelpIcon className={styles.menu_icon} />
                  <p>Help</p>
                </span>
                <ExpandIcon
                  className={styles.expand_icon}
                  data-content={isHelp ? "active" : "inactive"}
                />
              </div>
              {isHelp && (
                <>
                  <a
                    href="mailto:publishing@mondayoff.me"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <p>Contact</p>
                  </a>
                  <Link to="/faq">
                    <p
                      data-content={
                        location?.pathname.includes("faq")
                          ? "active"
                          : "inactive"
                      }
                    >
                      FAQ
                    </p>
                  </Link>
                </>
              )}
            </div>
          </ul>
        </div>
        <div className={styles.profile}>
          {isProfile && (
            <section className={styles.profile_popup} ref={profileModalRef}>
              <div className={styles.img_studio_name}>
                <img src={user.data.profileImg} alt="img" />
                <p>{user.data.studioName}</p>
              </div>
              <div className={styles.buttons}>
                <span>
                  <Link
                    to="/profile"
                    state={{ id: 1 }}
                    className={styles.edit_profile}
                    onClick={() => setIsProfile(false)}
                  >
                    <p>Edit Profile</p>
                  </Link>
                  <Link
                    to="/profile"
                    state={{ id: 2 }}
                    className={styles.edit_payment}
                    onClick={() => {
                      setIsProfile(false);
                      setIsEditPayment(true);
                    }}
                  >
                    <p>Edit Payment Details</p>
                  </Link>
                  {isEditPayment && !getCookie("Authentication") && (
                    <InvoiceLogin path="profile" setIsProfile={setIsProfile} />
                  )}
                </span>
                <button
                  className={styles.logout}
                  onClick={() => {
                    setIsLogoutModal(true);
                  }}
                >
                  <p>Sign out</p>
                </button>
              </div>
            </section>
          )}
          <div onClick={() => onMenuClick(5)}>
            <img src={user.data.profileImg} alt="" />
            <p>{user.data.studioName}</p>
          </div>
        </div>
      </div>
    </>
  );
});

export default SideBar;
/*
  <button className={styles.logoutBtn} onClick={logoutModal}>
            <img src={logoutIcon} alt="logoutIcon" />
            <p ref={logoutRef}>Logout</p>
          </button> 
*/
