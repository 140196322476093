import React, { useEffect } from "react";

import styles from "./AppIdBox.module.scss";
import foundApp from "../../../img/addNewApp/foundApp.png";
import notFound from "../../../img/addNewApp/notFound.png";

const AppIdBox = ({
  setAppId,
  appId,
  metaService,
  setCheckAppId,
  checkAppId,
  adsService,
  setIsDuplicate,
  setAlertMessage,
}) => {
  const onChange = (e) => {
    e.preventDefault();
    setAppId(e.target.value);
  };
  useEffect(() => {
    if (localStorage.getItem("app_Id")) {
      setAppId(localStorage.getItem("app_Id"));
    } else {
      setAppId("");
    }
  }, [setAppId]);
  useEffect(() => {
    const result = async () =>
      await metaService.checkAppId(appId).then((data) => {
        if (!data) {
          setCheckAppId(false);
          localStorage.removeItem("app_Id");
          // sessionStorage.removeItem("app_data");
        } else if (data) {
          //작업중

          adsService.checkPeriodAppId(appId).then((data) => {
            if (data?.data?.duplicate === true) {
              setIsDuplicate(true);
              setCheckAppId(true);
              setAlertMessage(
                `Creation is restricted until ${data?.data?.endTime}`
              );
            } else {
              setIsDuplicate(false);
              setCheckAppId(true);
              localStorage.setItem("app_Id", appId);
            }
          });

          // sessionStorage.setItem("app_data", JSON.stringify(data?.data));
        }
      });
    result();
  }, [adsService, appId, metaService, setCheckAppId]);

  return (
    <div className={styles.container}>
      <div className={styles.title}>Enter your App ID</div>
      <div className={styles.appIdContainer}>
        <div className={styles.appId}>App ID</div>
        <div className={styles.inputContainer}>
          <input
            type="text"
            onChange={onChange}
            className={styles.appInput}
            placeholder="Facebook App ID"
            value={appId}
          />
          {appId && checkAppId && (
            <img src={foundApp} alt="" className={styles.checkIcon} />
          )}
          {appId && !checkAppId && (
            <img src={notFound} alt="" className={styles.notFoundIcon} />
          )}
        </div>
        <div className={styles.hoverMessage}>
          <img src={notFound} alt="" className={styles.popMesaageIcon} />
          <div>Please retry to copy and paste your APP ID</div>
        </div>
      </div>
    </div>
  );
};
export default React.memo(AppIdBox);
