import axios from "axios";
import * as cookie from "../db/cookies";

const token = cookie.getCookie("DA_token");
export default class MetaService {
  constructor(http, tokenStorage) {
    this.http = http;
    this.tokenStorage = tokenStorage;
  }
  async getAllAds(userId) {
    return await this.http
      .axios(`/meta/allads?userid=${userId}`, { method: "GET" })
      .then((res) => res)
      .catch((e) => e.response);
  }

  async getAds(appId, gameName) {
    return await this.http
      .axios(
        `/meta/ads?appId=${appId}&gameName=${gameName
          .replace(/#/g, "%23")
          .replace(/&/g, "26")}`,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => res)
      .catch((e) => e.response);
  }

  async checkAppId(appId) {
    return await axios(
      `https://graph.facebook.com/v18.0/${appId}?fields=name,object_store_urls&access_token=${process.env.REACT_APP_META_TOKEN}`,
      { method: "GET" }
    )
      .then((data) => data)
      .catch(console.error);
  }

  async uploadVideo(videos, gameName, index) {
    return await this.http
      .axios(`/meta/uploads/${gameName.replace(/&/g, "26")}&${index}`, {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: videos,
      })
      .then((res) => res)
      .catch(console.error);
  }
  async deleteVideo(gameName, videoName, videoId) {
    return await this.http
      .axios(`/meta/uploads/${gameName.replace(/&/g, "%26")}`, {
        method: "DELETE",
        data: { gameName, videoName, videoId },
      })
      .then((res) => res)
      .catch(console.error);
  }
  async creativeAds(videoId, gameLink, studioName, appId, gameName, userId) {
    return await this.http
      .axios("/meta/creativeads", {
        method: "POST",
        data: { videoId, gameLink, studioName, appId, gameName, userId },
      })
      .then((res) => {
        if (res.status === 201) {
          return res.data;
        } else {
          console.log("wrong");
        }
      })
      .catch((e) => e.response);
  }
  async getAdVideo(adVideoId) {
    return await this.http
      .axios(`/meta?video_id=${adVideoId}`, { method: "GET" })
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        } else {
          return null;
        }
      });
  }
}
