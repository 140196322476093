import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import styles from "./AdsList.module.scss";
import AdsListAnimation from "../../common/AdsListAnimation/AdsListAmination";

const AdsList = ({ adData, searchValue, list, pathName }) => {
  return (
    <div className={styles.container}>
      {list && (
        <>
          <ul className={styles.items}>
            <li>Game</li>
            <li>Status</li>
            <li>Start Date</li>
            <li>End date</li>
            <li>Upload</li>
          </ul>
          {list.length === 0 ? (
            <AdsListAnimation />
          ) : (
            <div className={styles.adsListContainer}>
              {list
                ?.filter((data) => {
                  if (searchValue === "") {
                    return data;
                  } else if (
                    data?.gameName
                      .toLowerCase()
                      .replace(/(\s*)/g, "")
                      .includes(
                        searchValue.replace(/(\s*)/g, "").toLowerCase()
                      ) ||
                    data?.gameName
                      .toUpperCase()
                      .replace(/(\s*)/g, "")
                      .includes(searchValue.replace(/(\s*)/g, "").toUpperCase())
                  ) {
                    return data;
                  }
                })
                .map((data) => {
                  let ads = adData?.filter((ad) =>
                    ad?.name.split("_").includes(data?.studioName)
                  );
                  let status = ads?.filter((ad) =>
                    ad?.name?.includes(data?.gameName)
                  );
                  let gameName = data?.gameName;
                  return (
                    <Link
                      key={data?.id}
                      to={`/${pathName}/${data?.id}`}
                      state={{
                        data: data,
                        adData: adData,
                      }}
                    >
                      <div className={styles.adsList}>
                        <div className={styles.game}>
                          <img
                            src={data?.gameIcon}
                            alt=""
                            className={styles.gameIcon}
                            id="img"
                          />
                          <div className={styles.gameName}>{gameName}</div>
                        </div>
                        <div>
                          {status
                            ?.map((ad, idx) => {
                              if (ad.effective_status) {
                                switch (ad.effective_status) {
                                  case "ACTIVE":
                                    return (
                                      <div
                                        className={styles.activeStatus}
                                        key={idx}
                                      >
                                        <div>Active</div>
                                      </div>
                                    );
                                  case "PAUSED":
                                    return (
                                      <div
                                        className={styles.completeStatus}
                                        key={idx}
                                      >
                                        <div>Complete</div>
                                      </div>
                                    );
                                  case "CAMPAIGN_PAUSED":
                                    return (
                                      <div className={styles.completeStatus}>
                                        <div>Complete</div>
                                      </div>
                                    );
                                  default:
                                    return (
                                      <div className={styles.status} key={idx}>
                                        <div>Inactive</div>
                                      </div>
                                    );
                                }
                              }
                            })
                            .splice(0, 1)}
                        </div>
                        <div className={styles.date}>
                          {moment(data?.createdAt).format("YYYY. MM. DD")}
                        </div>
                        <div className={styles.date}>
                          {moment(data.endTime).format("YYYY. MM. DD")}
                        </div>
                        <div className={styles.date}>
                          {moment(data?.createdAt).format("YYYY. MM. DD")}
                        </div>
                      </div>
                    </Link>
                  );
                })}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default React.memo(AdsList);
