import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import styles from "./SecondProcess.module.scss";
import second from "../../img/process/secondProcess.png";
import getGA from "../../service/ga";
import progressBar from "../../img/process/secondProgressBar.png";
const SecondProcess = (props) => {
  const navigate = useNavigate();
  const onClick = () => {
    navigate("/thirdprocess");
  };
  const location = useLocation();
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_ID);
    ReactGA.send({
      hitType: "pageview",
      path: location.pathname,
      location: location.pathname,
      title: "ProcessInfo-second",
    });
    getGA();
  }, [location.pathname]);
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        Test your game in just three simple steps!
      </div>
      <div className={styles.processText}>
        2. Integrate MondayOFF SDK to get real time updates.
      </div>
      <img src={second} alt="" className={styles.img} />

      <img src={progressBar} alt="progressBar" className={styles.progressBar} />
      <button className={styles.nextBtn} onClick={onClick}>
        Next
      </button>
    </div>
  );
};

export default SecondProcess;
