import React from "react";
import Skeleton from "@mui/material/Skeleton";
import styles from "./SkeletonUi.module.scss";
export default function SkeletonUi() {
  return (
    <ul className={styles.container}>
      <li className={styles.skeleton_ui}>
        <Skeleton animation="wave" className={styles.date} />
        <Skeleton animation="wave" className={styles.monthly_gross} />
        <Skeleton animation="wave" className={styles.monthly_spend} />
        <Skeleton animation="wave" className={styles.monthly_third} />
        <Skeleton animation="wave" className={styles.monthly_net_revenue} />
        <Skeleton animation="wave" className={styles.revenue_share} />
        <Skeleton animation="wave" className={styles.bonus} />
        <Skeleton animation="wave" className={styles.payment_status} />
      </li>
      <li className={styles.skeleton_ui}>
        <Skeleton animation="wave" className={styles.date} />
        <Skeleton animation="wave" className={styles.monthly_gross} />
        <Skeleton animation="wave" className={styles.monthly_spend} />
        <Skeleton animation="wave" className={styles.monthly_third} />
        <Skeleton animation="wave" className={styles.monthly_net_revenue} />
        <Skeleton animation="wave" className={styles.revenue_share} />
        <Skeleton animation="wave" className={styles.bonus} />
        <Skeleton animation="wave" className={styles.payment_status} />
      </li>
      <li className={styles.skeleton_ui}>
        <Skeleton animation="wave" className={styles.date} />
        <Skeleton animation="wave" className={styles.monthly_gross} />
        <Skeleton animation="wave" className={styles.monthly_spend} />
        <Skeleton animation="wave" className={styles.monthly_third} />
        <Skeleton animation="wave" className={styles.monthly_net_revenue} />
        <Skeleton animation="wave" className={styles.revenue_share} />
        <Skeleton animation="wave" className={styles.bonus} />
        <Skeleton animation="wave" className={styles.payment_status} />
      </li>
      <li className={styles.skeleton_ui}>
        <Skeleton animation="wave" className={styles.date} />
        <Skeleton animation="wave" className={styles.monthly_gross} />
        <Skeleton animation="wave" className={styles.monthly_spend} />
        <Skeleton animation="wave" className={styles.monthly_third} />
        <Skeleton animation="wave" className={styles.monthly_net_revenue} />
        <Skeleton animation="wave" className={styles.revenue_share} />
        <Skeleton animation="wave" className={styles.bonus} />
        <Skeleton animation="wave" className={styles.payment_status} />
      </li>
      <li className={styles.skeleton_ui}>
        <Skeleton animation="wave" className={styles.date} />
        <Skeleton animation="wave" className={styles.monthly_gross} />
        <Skeleton animation="wave" className={styles.monthly_spend} />
        <Skeleton animation="wave" className={styles.monthly_third} />
        <Skeleton animation="wave" className={styles.monthly_net_revenue} />
        <Skeleton animation="wave" className={styles.revenue_share} />
        <Skeleton animation="wave" className={styles.bonus} />
        <Skeleton animation="wave" className={styles.payment_status} />
      </li>
      <li className={styles.skeleton_ui}>
        <Skeleton animation="wave" className={styles.date} />
        <Skeleton animation="wave" className={styles.monthly_gross} />
        <Skeleton animation="wave" className={styles.monthly_spend} />
        <Skeleton animation="wave" className={styles.monthly_third} />
        <Skeleton animation="wave" className={styles.monthly_net_revenue} />
        <Skeleton animation="wave" className={styles.revenue_share} />
        <Skeleton animation="wave" className={styles.bonus} />
        <Skeleton animation="wave" className={styles.payment_status} />
      </li>
      <li className={styles.skeleton_ui}>
        <Skeleton animation="wave" className={styles.date} />
        <Skeleton animation="wave" className={styles.monthly_gross} />
        <Skeleton animation="wave" className={styles.monthly_spend} />
        <Skeleton animation="wave" className={styles.monthly_third} />
        <Skeleton animation="wave" className={styles.monthly_net_revenue} />
        <Skeleton animation="wave" className={styles.revenue_share} />
        <Skeleton animation="wave" className={styles.bonus} />
        <Skeleton animation="wave" className={styles.payment_status} />
      </li>
      <li className={styles.skeleton_ui}>
        <Skeleton animation="wave" className={styles.date} />
        <Skeleton animation="wave" className={styles.monthly_gross} />
        <Skeleton animation="wave" className={styles.monthly_spend} />
        <Skeleton animation="wave" className={styles.monthly_third} />
        <Skeleton animation="wave" className={styles.monthly_net_revenue} />
        <Skeleton animation="wave" className={styles.revenue_share} />
        <Skeleton animation="wave" className={styles.bonus} />
        <Skeleton animation="wave" className={styles.payment_status} />
      </li>
      <li className={styles.skeleton_ui}>
        <Skeleton animation="wave" className={styles.date} />
        <Skeleton animation="wave" className={styles.monthly_gross} />
        <Skeleton animation="wave" className={styles.monthly_spend} />
        <Skeleton animation="wave" className={styles.monthly_third} />
        <Skeleton animation="wave" className={styles.monthly_net_revenue} />
        <Skeleton animation="wave" className={styles.revenue_share} />
        <Skeleton animation="wave" className={styles.bonus} />
        <Skeleton animation="wave" className={styles.payment_status} />
      </li>
    </ul>
  );
}
