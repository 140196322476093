import React from "react";
import styles from "./Modal.module.css";
import modalIcon from "../../img/alertModal/modalIcon.png";

const Modal = ({ message, setAlertModal, logoutText, onLogout }) => {
  const onClick = () => {
    if (logoutText) {
      onLogout();
      setAlertModal(false);
    } else {
      setAlertModal(false);
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.modalContainer}>
        <img src={modalIcon} alt="" className={styles.icon} />
        <div className={styles.alertMessage}>{message}</div>
        <button className={styles.closeBtn} onClick={onClick}>
          Ok
        </button>
      </div>
    </div>
  );
};

export default React.memo(Modal);
