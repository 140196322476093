import React from "react";
import { faqList } from "../../common/faqList";
import styles from "./FaqList.module.scss";
import arrow from "../../img/faq/arrow.png";

const FaqList = () => {
  const onClick = (id) => {
    const activeFaq = document.getElementsByClassName(
      styles[`contentsBox_${id}`]
    );
    const activeInfo = document.getElementsByClassName(
      styles[`eachInfo_${id}`]
    );
    activeFaq[0].classList.toggle(styles.activeContents);
    activeInfo[0].classList.toggle(styles.activeFaq);
  };
  return (
    <div className={styles.container}>
      <div className={styles.containerTitle}>FAQ</div>
      <div className={styles.listBox}>
        {faqList.map((d) => (
          <div
            key={d.id}
            className={styles[`eachInfo_${d.id}`]}
            onClick={() => onClick(d.id)}
          >
            <div className={styles.title}>{d.title}</div>
            <div className={styles[`contentsBox_${d.id}`]} key={d.id}>
              <div className={styles.contents}>{d.contents}</div>
              {d.contents2 && (
                <div className={styles.contents2}>{d.contents2}</div>
              )}
              {d.developLink && (
                <>
                  <a
                    className={styles.link}
                    href={d.developLink[0]}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Verify Ownership of your Audience Network App
                  </a>
                  <a
                    className={styles.link}
                    href={d.developLink[1]}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Identifying Authorized Sellers with app-ads.txt
                  </a>
                </>
              )}
              {d?.monetizationLink && (
                <a
                  className={styles.link}
                  href={d.monetizationLink[0]}
                  target="_blank"
                  rel="noreferrer"
                >
                  Upgrade to Monetization Manager from Facebook for Developers
                </a>
              )}
            </div>
            <img src={arrow} alt="" className={styles.arrow} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default FaqList;
