import React, { useState } from "react";
import styles from "./ProfileInfoEdit.module.scss";

import arrow from "../../img/login/arrow.png";
import inactiveEye from "../../img/login/inactiveEye.png";
import activeEye from "../../img/login/activeEye.png";
const ProfileInfoEdit = ({
  user,
  updatePassword,
  confirmPassword,
  setUpdadtePassword,
  setConfirmPassword,
  setTeamSize,
  setPortfolioLink,
  onClick,
  teamSize,
  portfolioLink,
}) => {
  const [viewPassword, setViewPassword] = useState(false);
  const [viewConfirmPasssword, setViewConfirmPassword] = useState(false);
  const [viewDropDown, setViewDropDown] = useState(false);
  const [select, setSelect] = useState({ id: 1, size: "1" });
  const sizeList = [
    { id: 1, size: "1" },
    { id: 2, size: "2 - 5" },
    { id: 3, size: "10 +" },
  ];
  const isSelect = (item) => {
    return select?.id === item.id;
  };
  const onChange = (e) => {
    const {
      target: { name, value },
    } = e;
    switch (name) {
      case "password":
        setUpdadtePassword(value);
        return;
      case "confirmPassword":
        setConfirmPassword(value);
        return;
      case "teamSize":
        setTeamSize(value);
        return;
      case "portfolioLink":
        setPortfolioLink(value);
        return;
      default:
        return;
    }
  };
  const onPasswordViewClick = () => {
    return setViewPassword(!viewPassword);
  };
  const onConfirmPasswordViewClick = () => {
    return setViewConfirmPassword(!viewConfirmPasssword);
  };

  return (
    <div className={styles.container}>
      <div className={styles.studioName}>{user?.data?.studioName}</div>
      <div className={styles.passwordInputArea}>
        <div className={styles.passwordContainer}>
          <div
            className={
              updatePassword.length > 5
                ? styles.validPasswordTitle
                : styles.invalidPasswordTitle
            }
          >
            New Password
          </div>
          <input
            placeholder="Password"
            type={viewPassword ? "text" : "password"}
            id="password"
            name="password"
            onChange={onChange}
            className={
              updatePassword.length > 5
                ? styles.validPassword
                : styles.invalidPassword
            }
          />
          <img
            src={viewPassword ? activeEye : inactiveEye}
            alt="viewPassword"
            className={styles.eyes}
            onClick={() => onPasswordViewClick()}
          />
        </div>

        <div className={styles.passwordContainer}>
          <div
            className={
              updatePassword === confirmPassword && updatePassword.length !== 0
                ? styles.validConfirmPasswordTitle
                : styles.invalidConfirmPasswordTitle
            }
          >
            New Password Confirmation
          </div>
          <input
            placeholder="Password Confirmation"
            type={viewConfirmPasssword ? "text" : "password"}
            id="confirmPassword"
            name="confirmPassword"
            onChange={onChange}
            className={
              updatePassword === confirmPassword && updatePassword.length !== 0
                ? styles.validConfirmPassword
                : styles.invalidConfirmPassword
            }
          />
          <img
            src={viewConfirmPasssword ? activeEye : inactiveEye}
            alt="viewPassword"
            className={styles.eyes}
            onClick={() => onConfirmPasswordViewClick()}
          />
        </div>
      </div>
      <div className={styles.infoInputArea}>
        <div>
          <div className={styles.teamSizeTitle}>Team Size</div>
          <div
            className={styles.signupTeamSize}
            onClick={() => setViewDropDown(!viewDropDown)}
          >
            <div className={styles.selectSizeText}>{select.size}</div>
            <img
              src={arrow}
              alt=""
              className={!viewDropDown ? styles.arrow : styles.rotateArrow}
            />
            {viewDropDown && (
              <ul className={styles.dropDown}>
                {sizeList.map((i) => (
                  <>
                    <li
                      key={i.id}
                      onClick={() => {
                        setSelect(i);
                        setTeamSize(i.size);
                      }}
                      className={isSelect(i) ? styles.clickList : styles.list}
                    >
                      {i.size}
                    </li>
                  </>
                ))}
              </ul>
            )}
          </div>
        </div>
        <div>
          <div className={styles.portfolioLinkTitle}>Portfolio Link</div>
          <input
            type="text"
            name="portfolioLink"
            onChange={onChange}
            placeholder={
              user?.data?.portfolioLink ? user?.data?.portfolioLink : "-"
            }
            value={portfolioLink}
            className={styles.portfolioUrl}
          />
        </div>
      </div>

      <button className={styles.editBtn} onClick={onClick}>
        Edit
      </button>
    </div>
  );
};

export default React.memo(ProfileInfoEdit);
