import React, { memo } from "react";
import styles from "./Notfound.module.scss";
const Notfound = () => {
  return (
    <div className={styles.container}>
      <div>
        <h1>404</h1>
        <p>
          <span>Opps!</span> Page not found.
        </p>
        <p>The page you’re looking for doesn’t exist.</p>
        <a href="https://dashboard.mondayoff.me">Go Home</a>
      </div>
    </div>
  );
};
export default memo(Notfound);
