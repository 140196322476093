import React from "react";
import styles from "./GameIcon.module.css";
const GameIcon = (props) => {
  const gameIcon = JSON.parse(localStorage?.getItem("appInfo"))?.gameIcon;

  return (
    <div className={styles.container}>
      <img src={gameIcon} alt="" className={styles.gameIcon} />
    </div>
  );
};

export default GameIcon;
