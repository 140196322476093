import React from "react";
import styles from "./ThirdDocModal.module.css";
import firstImg from "../../../../img/thirdDocModal/first.png";
import secondImg from "../../../../img/thirdDocModal/second.png";
const ThirdDocModal = ({ setShowThirdModal }) => {
  const onClick = () => {
    setShowThirdModal(false);
  };
  return (
    <div className={styles.container}>
      <div className={styles.modalContainer}>
        <div className={styles.basicSetupTitle}>
          <div className={styles.basicSetupTitleText}>
            Basic setting setup for developer
          </div>
        </div>
        <div className={styles.scroll}>
          <div className={styles.thirdDocContainer}>
            <div className={styles.thirdDocContext}>
              <div className={styles.one}>
                Basic Settings setup for developers.
              </div>
              <div className={styles.two}>
                <div className={styles.two_one}>
                  {" "}
                  1. Basic Settings setup for developers.{" "}
                </div>
                <div className={styles.two_two}>
                  - Click on Settings {"->"} Basic
                </div>
                <div className={styles.two_three}>
                  Fill in the areas that are marked in
                  <span className={styles.redText}> RED</span> following the
                  guide, and click on the{" "}
                  <span className={styles.italic}>“Save changes”</span> button.
                </div>
                <div className={styles.two_four}>
                  * Privacy Policy URL, User Data deletion, Terms of Service
                </div>
                <div className={styles.two_five}>
                  You can use MondayOFF's URL, if you don't have one.
                </div>
                <div className={styles.two_six}>
                  <span>https://mondayoff.me/privacyPolicy.html</span> {"<-"}{" "}
                  Copy the link.
                </div>
                <img src={firstImg} alt="" className={styles.firstImg} />
              </div>
              <div className={styles.three}>
                <div className={styles.threeTitle}>
                  2. Click on <span>‘In development’</span> button to switch to
                  live mode
                </div>
                <div className={styles.three_one}>
                  Live may not be activated immediately due to internal server
                  problems on Facebook.
                </div>
                <div className={styles.three_two}>
                  (Refresh the page or try again after a while)
                </div>
                <img src={secondImg} alt="" className={styles.secondImg} />
              </div>
              <button className={styles.closeBtn} onClick={onClick}>
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ThirdDocModal);
